import { Helmet } from 'react-helmet-async';

interface SeoMetaDataProps {
    title: string;
    description: string;
    type: string;
}

// https://blog.sachinchaurasiya.dev/how-to-integrate-reactjs-and-react-helmet-async-manage-seo-and-meta-data

// https://ahrefs.com/blog/seo-meta-tags/

// https://dillionmegida.com/p/how-to-configure-meta-tags-in-react-app-dynamically/#a-better-solution

const SeoMetaData = ({ title, description, type }: SeoMetaDataProps) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="twitter:creator" content={'Grégory Jacob'} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {/* Open graph */}
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="31st.fr" />
        </Helmet>
    );
};

export default SeoMetaData;
