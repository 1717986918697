import { RootState } from '../../app/store';

export const selectBlogCategoryStatus = (state: RootState) =>
    state.blogCategory.status;

export const selectAllBlogCategory = (state: RootState) =>
    state.blogCategory.categories;

export const selectBlogCategoryCount = (state: RootState) =>
    state.blogCategory.categories.length;
