interface LoadingProps {
    size?: 'xs' | 'base' | 'xl';
    expanded?: boolean;
}

const sizes = {
    xs: 'h-2 w-2',
    base: 'h-3 w-3',
    xl: 'h4 w-4',
};

const Loading = ({ size = 'xs', expanded = true }: LoadingProps) => {
    const elementsSize = sizes[size];
    const expandedCss = expanded ? ` h-full w-full` : ``;

    return (
        <div className={`flex-wrap-col-center ${expandedCss}`}>
            <div className="flex-wrap-col-center bg-white h-12 w-12 rounded-full">
                <div className="grid w-fit animate-spin grid-cols-2 gap-1">
                    <div
                        className={`${elementsSize} animate-bg-gray-to-rose-slow rounded-full`}
                    ></div>
                    <div
                        className={`${elementsSize} animate-bg-gray-to-rose-slow rounded-full`}
                    ></div>
                    <div
                        className={`${elementsSize} animate-bg-gray-to-rose-normal rounded-full`}
                    ></div>
                    <div
                        className={`${elementsSize} animate-bg-gray-to-rose-normal rounded-full`}
                    ></div>
                </div>
                {/* Tailwind need explicit usage to include classes */}
                <div className="hidden h-2 w-2"></div>
                <div className="hidden h-3 w-3"></div>
                <div className="hidden h-4 w-4"></div>
            </div>
        </div>
    );
};

export default Loading;
