import { useEffect, useState } from 'react';
import { getOptionTypeFamily } from '../../../features/31stflex';
import { implodeOrUndefined } from '../../../lib/functionsArray';
import { regInteger } from '../../../lib/regExp';
import {
    FlexOptionPropertyValueUnit,
    FlexOptionSetting,
    FlexOptionTypeFamily,
} from '../../../models/31stflex';

type FlexOptionNumberProps = {
    setting: FlexOptionSetting;
    containerOptionSelectionHandler: (settingUpdated: FlexOptionSetting) => void;
    itemOptionSelectionHandler: (settingUpdated: FlexOptionSetting) => void;
};

const checkInputValue = (value: string, regexp?: RegExp) => {
    return (regexp ?? regInteger).test(value);
};

const FlexOptionNumber = ({
    setting,
    containerOptionSelectionHandler,
    itemOptionSelectionHandler,
}: FlexOptionNumberProps) => {
    const [inputValue, setInputValue] = useState(setting.value);
    const [inputIsValid, setInputIsValid] = useState(true);

    // Changement de la valeur
    const onChangeHandler = (event: any) => {
        const value = event.target.value;
        const test = checkInputValue(value, setting.regex);

        setInputIsValid(test);
        setInputValue(value);
        if (!test) return;

        switch (getOptionTypeFamily(setting.property)) {
            // Pour les valeurs conteneur
            case FlexOptionTypeFamily.CONTAINER:
                containerOptionSelectionHandler({
                    ...setting,
                    value: value,
                });
                break;

            // Pour les valeurs item
            case FlexOptionTypeFamily.ITEM:
                itemOptionSelectionHandler({ ...setting, value: value });
                break;
        }
    };

    const onClickUnitHandler = (unit: FlexOptionPropertyValueUnit) => {
        switch (getOptionTypeFamily(setting.property)) {
            // Pour les valeurs conteneur
            case FlexOptionTypeFamily.CONTAINER:
                containerOptionSelectionHandler({
                    ...setting,
                    unit: unit,
                });
                break;

            // Pour les valeurs item
            case FlexOptionTypeFamily.ITEM:
                itemOptionSelectionHandler({ ...setting, unit: unit });
                break;
        }
    };

    useEffect(() => {
        const test = checkInputValue(String(setting.value), setting.regex);
        setInputIsValid(test);
        setInputValue(setting.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setting]);

    const cssClasses = ['flex-item-input'];
    if (!inputIsValid) {
        cssClasses.push('invalid-input');
    }

    if (setting.units?.length) {
        cssClasses.push('max-w-[50%]');
    }

    return (
        <div className="flex-item-value-wrapper">
            <input
                className={implodeOrUndefined(cssClasses)}
                value={inputValue}
                onChange={onChangeHandler}
                maxLength={2}
            />
            {setting.units?.map((unit) => {
                const cssClasses = ['flex-item-value-unit'];
                if (unit === setting.unit) {
                    cssClasses.push('selected');
                }

                return (
                    <div
                        key={unit}
                        className={implodeOrUndefined(cssClasses)}
                        onClick={() => onClickUnitHandler(unit)}
                    >
                        {unit}
                    </div>
                );
            })}
        </div>
    );
};

export default FlexOptionNumber;
