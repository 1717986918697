import dayjs from 'dayjs';
import { BlogArticle } from '../../models/backShop/blogArticle';
import CodeArticleCategories from './CodeArticleCategories';

interface CodeArticleCompactProps {
    className?: string;
    article: BlogArticle;
    filterCategory: Set<string>;
    onClickHandler: (article: BlogArticle) => void;
}

const CodeArticleCompact = ({
    article,
    filterCategory,
    onClickHandler,
    className = undefined,
}: CodeArticleCompactProps) => {
    const date = dayjs(article.createdAt.toDate());

    return (
        <div className={`flex-wrap-col ${className ? ` ${className}` : ``}`}>
            <h5
                onClick={() => onClickHandler(article)}
                className="px-3 py-2 text-right transition-300 w-full self-end cursor-pointer bg-white/25 hover:bg-white/40 border-white border border-b-0 border-dashed rounded-t-md"
            >
                {article.title}
            </h5>
            <div className="flex flex-row flex-wrap text-sm bg-white/10 px-3 py-2 border border-dashed border-t-solid rounded-b-md">
                <CodeArticleCategories
                    articleCategories={article.categories}
                    articleCategoriesFilter={filterCategory}
                />
                <time className="ml-auto">{date.format('ll')}</time>
            </div>
        </div>
    );
};

export default CodeArticleCompact;
