import dayjs from 'dayjs';
import { TWITTER_ACCOUNT } from '../../constants/constants';
import { BlogArticle } from '../../models/backShop/blogArticle';
import ButtonSocialShare from '../31st/Common/Button/ButtonSocialShare';
import { RichTextEditorDisplay } from '../31st/Common/RichTextEditor/RichTextEditor';
import CodeArticleCategories from './CodeArticleCategories';

interface CodeArticleProps {
    article: BlogArticle;
}

const CodeArticle = ({ article }: CodeArticleProps) => {
    const href = window.location.href;

    const date = dayjs(article.updatedAt.toDate());

    return (
        <article className="flex-1">
            <h1 className="drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)] flex-wrap-row gap-3">
                <i className="fa-solid fa-code-branch text-4xl"></i>
                {article.title}
            </h1>
            <div className="flex-wrap-row gap-3">
                <CodeArticleCategories articleCategories={article.categories} theme="notePad" />
                <div className="ml-auto text-xs text-slate-300 flex-wrap-row gap-3">
                    <time>Last updated {date.format('ll')}</time> | <span>Share</span>
                </div>
                <ButtonSocialShare
                    type={'twitter'}
                    data={{
                        url: `${href}/${article.id}`,
                        title: article.title,
                        handle: TWITTER_ACCOUNT,
                    }}
                />
                <ButtonSocialShare
                    type={'facebook'}
                    data={{
                        url: `${href}/${article.id}`,
                    }}
                />
                <ButtonSocialShare
                    type={'linkedin'}
                    data={{
                        url: `${href}/${article.id}`,
                        title: article.title,
                    }}
                />
            </div>
            <RichTextEditorDisplay className="text-slate-300" content={article.content} />
        </article>
    );
};

export default CodeArticle;
