import { CSSProperties, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import colors from 'tailwindcss/colors';
import { CssMap } from '../models/31stflex';
import ShinyEditor from './31stshiny/Editor/ShinyEditor';
import { ShinyTheme } from './31stshiny/Editor/ShinyEditorThemeSelector';
import ShinyTitle, { ShinySectionType } from './31stshiny/ShinyTitle';
import SeoMetaData from './Common/Seo/SeoMetaData';
import { getCssMapForDisplay } from './Flexbox';

const shinyList = Object.values(ShinyTheme);

const Shiny = () => {
    const [shinyDummyStyleForCode, setShinyDummyStyleForCode] =
        useState<CSSProperties>({});

    const [shinyCurrentThemeForCode, setShinyCurrentThemeForCode] =
        useState<ShinyTheme>(ShinyTheme.SLATE);

    // Custom user style to CSS code
    const styleToCssMap = (style: CSSProperties): CssMap => {
        const cssMap = new Map();
        const { borderRadius, ...restStyle } = style;

        const commonProps = [
            ['display', 'flex'],
            ['flex-direction', 'column'],
            ['flex-wrap', 'wrap'],
            ['align-content', 'center'],
            ['align-items', 'center'],
            ['justify-content', 'center'],
            ['borderRadius', borderRadius],
            ['transition', 'all ease-in-out 0.3s'],
        ];

        cssMap.set(
            `/* Html: <div class="shiny"><div class="content"></div></div> */`,
            []
        );
        cssMap.set('shiny, .shiny .content', commonProps);

        const taiwindColorName = shinyCurrentThemeForCode.replace('shiny-', '');

        const shinyProps = [...Array.from(Object.entries(restStyle))];
        // Recherche des couleurs du dégradé & ombrage dans la conf. couleurs Tailwind
        const colorsObj = Object.entries(colors).find(
            ([prop, __]) => prop === taiwindColorName
        );
        if (colorsObj) {
            const deg01 = colorsObj[1][400];
            const deg02 = colorsObj[1][600];
            const sha01 = colorsObj[1][500];
            const sha02 = colorsObj[1][700];

            shinyProps.push(
                [
                    `/* Tailwind colors ${taiwindColorName}[400] & ${taiwindColorName}[600] */`,
                    ``,
                ],
                [
                    'background',
                    `linear-gradient(45deg, ${deg01}% 0%, ${deg02} 100%)`,
                ],
                [
                    `/* Tailwind colors ${taiwindColorName}[500] & ${taiwindColorName}[700] */`,
                    ``,
                ],
                [
                    'box-shadow',
                    `0 10px 15px -4px theme('${sha01}'), 0 4px 6px -4px theme('${sha02}')`,
                ]
            );
        }
        cssMap.set('shiny', shinyProps);

        const shinyContentProps = [
            ['width', '100%'],
            ['flex', '1'],
            ['background', 'linear-gradient(0deg, #fff 0%, #e5e5e5 100%)'],
            ['box-shadow', '0 1px 5px 0px rgba(0, 0, 0, 0.2)'],
        ];
        cssMap.set('shiny .content', shinyContentProps);

        return cssMap;
    };

    useEffect(() => {
        // React router dom side effect :/
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SeoMetaData
                title={'31stshiny, elegant glowy CSS element generator'}
                description={
                    'How to easily create glowy designed element with a few lines of CSS. Edit all properties and get your own custom "Shiny" tags'
                }
                type={'website'}
            />
            <Link to="/">
                <div className="title-badge fixed">31st</div>
            </Link>
            <div className="page-31stshiny min-h-screen bg-white p-4 text-neutral-700">
                <div className="wrapper-home" id="shiny-gallery">
                    <ShinyTitle type={ShinySectionType.GALLERY} />
                    <div className="grid min-h-fit grow grid-cols-9 justify-items-center gap-4 p-4">
                        {shinyList.map((shinyClass) => {
                            return (
                                <div
                                    key={uuid()}
                                    className={`shiny ${shinyClass}`}
                                >
                                    <div className="content">Shiny</div>
                                </div>
                            );
                        })}

                        {shinyList.map((shinyClass) => {
                            return (
                                <div
                                    key={uuid()}
                                    className={`shiny shiny-sm ${shinyClass} shiny-tl shiny-br`}
                                >
                                    <div className="content shiny-tl shiny-br">
                                        Shiny
                                    </div>
                                </div>
                            );
                        })}

                        {Object.keys(ShinyTheme).map((theme) => {
                            return (
                                <div key={uuid()} className="text-slate-600">
                                    {theme}
                                </div>
                            );
                        })}

                        {shinyList.map((shinyClass) => {
                            return (
                                <div
                                    key={uuid()}
                                    className={`shiny shiny-xs ${shinyClass} shiny-bl shiny-br`}
                                >
                                    <div className="content shiny-bl shiny-br">
                                        Shiny
                                    </div>
                                </div>
                            );
                        })}

                        {shinyList.map((shinyClass) => {
                            return (
                                <div
                                    key={uuid()}
                                    className={`shiny shiny-2xs ${shinyClass} shiny-tl`}
                                >
                                    <div className="content shiny-tl">
                                        Shiny
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="wrapper-home" id="shiny-editor">
                    <ShinyTitle type={ShinySectionType.EDITOR} />
                    <ShinyEditor
                        setShinyDummyStyleForCode={setShinyDummyStyleForCode}
                        setShinyCurrentThemeForCode={
                            setShinyCurrentThemeForCode
                        }
                    />
                </div>
                <div className="wrapper-home" id="shiny-css">
                    <ShinyTitle type={ShinySectionType.CSS} />
                    <div className="flex-wrap-col-center grow">
                        <div className="bg-slate-700 p-6 text-white">
                            {getCssMapForDisplay(
                                styleToCssMap(shinyDummyStyleForCode)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Shiny;
