import { Alert, Button, Dialog, DialogBody, DialogFooter } from '@material-tailwind/react';
import { Dispatch } from 'react';

interface DialogCustomProps<T> {
    dialogOpen: boolean;
    dialogMessage: string;
    setDialogOpen: Dispatch<React.SetStateAction<boolean>>;
    // Callback and it's param
    callbackHandlerParam?: T;
    callbackHandler?: (param: T) => void;
}

const DialogCustom = <T,>({
    dialogOpen,
    setDialogOpen,
    callbackHandler,
    callbackHandlerParam,
    dialogMessage,
}: DialogCustomProps<T>) => {
    return (
        <Dialog open={dialogOpen} handler={setDialogOpen} size="xs">
            <DialogBody>
                <Alert
                    children={dialogMessage}
                    color="red"
                    icon={<i className="fa-solid fa-bomb"></i>}
                />
            </DialogBody>
            <DialogFooter className="flex gap-1">
                <Button color="gray" onClick={() => setDialogOpen(false)}>
                    <span>Cancel</span>
                </Button>
                {callbackHandler && callbackHandlerParam && (
                    <Button color="red" onClick={() => callbackHandler(callbackHandlerParam)}>
                        <span>Confirm</span>
                    </Button>
                )}
            </DialogFooter>
        </Dialog>
    );
};

export default DialogCustom;
