const CodeArticleMissing = () => {
    return (
        <div className="flex-wrap-col-center flex-1">
            <i className="fa-solid fa-skull text-5xl -rotate-12 text-slate-500"></i>
            <h1 className="p-6 text-rose-300">Ooooops...</h1>
            <p>This article appears to be unavailable</p>
            <blockquote className="m-6 p-6">
                <div className="text-2xl p-3">
                    <i className="fa-solid fa-quote-left -rotate-12 mr-2 text-slate-500"></i>
                    what the hell going wrong around here ?
                    <i className="fa-solid fa-quote-right -rotate-12 ml-2 text-slate-500"></i>
                </div>
                <div className="text-right text-sm">
                    Suicidal tendencies / You Can't Bring Me Down
                </div>
            </blockquote>
        </div>
    );
};

export default CodeArticleMissing;
