import { createSlice } from '@reduxjs/toolkit';
import { AuthState, AuthStateSliceCurrentUserAction } from '../../models/auth/auth';

const initialState: AuthState = {
    displayName: null,
    email: null,
    isLoggedIn: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser: (state, action: AuthStateSliceCurrentUserAction) => {
            state.displayName = action.payload.displayName;
            state.email = action.payload.email;
            state.isLoggedIn = action.payload.isLoggedIn;
        },
    },
});

export const { setCurrentUser } = authSlice.actions;

export default authSlice.reducer;
