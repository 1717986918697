import {
	Button,
	Card,
	CardBody,
	Input,
	Textarea,
	Typography,
} from '@material-tailwind/react';
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	serverTimestamp,
	updateDoc,
} from 'firebase/firestore';
import {
	deleteObject,
	getDownloadURL,
	getStorage,
	ref,
	uploadBytes,
} from 'firebase/storage';
import { CSSProperties, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUiMessage } from '../../app/hooks/useUiMessage';
import { getFirebaseCollectionCount } from '../../features/firebase/firebase';
import { getFirebaseNews } from '../../features/firebase/firebaseNews';
import { firestoreDb } from '../../lib/firebase';
import { NewsData, NewsFirebase } from '../../models/backShop/news';
import { NewsInput } from '../../models/form';
import { PaginationData, UI_MESSAGE_LOG } from '../../models/generic';
import { DragAndDropFileData } from '../../models/utilsFiles';
import AlertCustom from '../Common/Alert/AlertCustom';
import DragAndDropFile from '../Common/DragAndDrop/DragAndDropFile';
import FieldErrorMessage from '../Common/Form/FieldErrorMessage';
import Loading from '../Common/Loading/Loading';
import Pagination, {
	paginationDataDefault,
} from '../Common/Pagination/Pagination';
import { BackShopWhatsUpNews } from './BackShopWhatsUp/BackShopWhatsUpNews';

// A corréler avec les data de la BDD
interface NewsPreview {
    title: string;
    content: string;
    imagePreviewStyle: CSSProperties;
}

const formFieldsDefaultValues = {
    title: '',
    content: '',
};

const formPreviewDefaultValues = {
    ...formFieldsDefaultValues,
    imagePreviewStyle: {},
};

enum NewsFetchTriggerMode {
    NEWS = 'news',
    NEWS_AND_COUNT = 'news+count',
}

interface NewsFetchTrigger {
    mode: NewsFetchTriggerMode;
    date: Date;
}

const BackShopWhatsUp = () => {
    const storage = getStorage();

    const [dragAndDropFileData, setDragAndDropFileData] = useState<
        DragAndDropFileData | undefined
    >(undefined);
    const [newsPreview, setNewsPreview] = useState<NewsPreview>({
        ...formPreviewDefaultValues,
    });

    // Statut & data news
    const [newsData, setNewsData] = useState<NewsData>({
        news: [],
        loading: false,
        newsCount: 0,
    });
    // News en cours d'édition ( uniquement pour le réaffichage des data )
    const [newsCurrentEdition, setNewsCurrentEdition] = useState<
        NewsFirebase | undefined
    >(undefined);
    // Trigger refresh de la liste des news
    const [newsFetchTrigger, setNewsFetchTrigger] = useState<NewsFetchTrigger>({
        mode: NewsFetchTriggerMode.NEWS_AND_COUNT,
        date: new Date(),
    });

    // Pagination
    const [currentPagination, setCurrentPagination] = useState<PaginationData>({
        ...paginationDataDefault,
        itemsPerPage: 2,
    });

    const setPaginationDataFromPaginationComponent = (
        paginationPartial: Partial<PaginationData>
    ): void => {
        setCurrentPagination({
            ...currentPagination,
            ...paginationPartial,
        });
    };

    const {
        uiMessageData,
        setUiMessageSuccess,
        setUiMessageFailure,
        resetUiMessage,
    } = useUiMessage();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
        watch,
        clearErrors,
    } = useForm<NewsInput>({
        defaultValues: {
            ...formFieldsDefaultValues,
        },
    });

    const watchAllFields = watch();

    const onSubmitForm = async (data: NewsInput) => {
        let downloadURL = undefined;
        const timeStamp = serverTimestamp();

        resetUiMessage();

        // Image à uploader
        if (dragAndDropFileData?.image64 && dragAndDropFileData?.file) {
            try {
                // Définition du storage et du nom de fichier
                const storageRef = ref(
                    storage,
                    `images/news/${dragAndDropFileData.properties.name}`
                );
                // Upload de l'image
                const snapshot = await uploadBytes(
                    storageRef,
                    dragAndDropFileData.file
                );
                // Récupération de l'url de download
                downloadURL = await getDownloadURL(snapshot.ref);
            } catch (error) {
                setUiMessageFailure({
                    message: `An error occurred while uploading image file`,
                    log: {
                        type: UI_MESSAGE_LOG.ERROR_FIREBASE_STORAGE,
                        data: error,
                    },
                });
            }
        }

        if (newsCurrentEdition && newsCurrentEdition.id) {
            try {
                const docRef = doc(firestoreDb, 'news', newsCurrentEdition.id);

                await updateDoc(docRef, {
                    title: data.title,
                    content: data.content,
                    imageUrl: downloadURL ?? newsCurrentEdition.imageUrl,
                    updatedAt: timeStamp,
                });

                setUiMessageSuccess({
                    message: 'News updated successfully',
                });
            } catch (error) {
                setUiMessageFailure({
                    message: `An error occurred while updating news`,
                    log: {
                        type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                        data: error,
                    },
                });
            }
        } else {
            try {
                await addDoc(collection(firestoreDb, 'news'), {
                    title: data.title,
                    content: data.content,
                    imageUrl: downloadURL,
                    createdAt: timeStamp,
                    updatedAt: timeStamp,
                });

                setUiMessageSuccess({
                    message: 'News created successfully',
                });
            } catch (error) {
                setUiMessageFailure({
                    message: `An error occurred while creating news`,
                    log: {
                        type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                        data: error,
                    },
                });
            }
        }

        setNewsFetchTrigger({
            mode: NewsFetchTriggerMode.NEWS_AND_COUNT,
            date: new Date(),
        });
        onClickReset();
    };

    const onClickNewsEditionHandler = (newsToEdit: NewsFirebase) => {
        onClickReset();
        setNewsCurrentEdition(newsToEdit);
        resetUiMessage();

        // Hydratation du formulaire
        reset({
            title: newsToEdit.title,
            content: newsToEdit.content,
        });
        // Preview image
        setNewsPreview({
            ...newsPreview,
            imagePreviewStyle: {
                backgroundImage: `url("${newsToEdit.imageUrl}")`,
            },
        });
    };

    const onClickNewsDeleteHandler = async (newsToDelete: NewsFirebase) => {
        resetUiMessage();

        if (!newsToDelete.id) {
            setUiMessageFailure({
                message: 'Invalid news id, operation cancelled',
            });
            return;
        }

        // 1. Suppression de l'image
        if (newsToDelete.imageUrl) {
            try {
                const imagePath = new URL(
                    decodeURIComponent(newsToDelete.imageUrl)
                ).pathname;

                const imagePathShort = imagePath.match(/\/images.*/)?.[0] ?? '';
                const storage = getStorage();
                const imageRef = ref(storage, imagePathShort);
                await deleteObject(imageRef);
            } catch (error) {
                setUiMessageFailure({
                    message: `An error occurred while deleting image`,
                    log: {
                        type: UI_MESSAGE_LOG.ERROR_FIREBASE_STORAGE,
                        data: error,
                    },
                });
            }
        }

        // 2. Suppression de la news
        try {
            const docRef = doc(firestoreDb, 'news', newsToDelete.id);
            await deleteDoc(docRef);

            setNewsFetchTrigger({
                mode: NewsFetchTriggerMode.NEWS_AND_COUNT,
                date: new Date(),
            });
            onClickReset();

            setUiMessageSuccess({
                message: 'News deleted successfully',
            });
        } catch (error) {
            setUiMessageFailure({
                message: `An error occurred while deleting news`,
                log: {
                    type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                    data: error,
                },
            });
        }
    };

    // Reset form
    const onClickReset = () => {
        setNewsCurrentEdition(undefined);
        setNewsPreview({ ...formPreviewDefaultValues });
        clearErrors();
        reset({ ...formFieldsDefaultValues });
    };

    useEffect(() => {
        setNewsPreview({
            ...newsPreview,
            ...watchAllFields,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchAllFields.title, watchAllFields.content]);

    // DnD image preview
    useEffect(() => {
        setNewsPreview({
            ...newsPreview,
            imagePreviewStyle: dragAndDropFileData
                ? {
                      backgroundImage: `url("${dragAndDropFileData.imageUrl}")`,
                  }
                : {},
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragAndDropFileData]);

    // Fetch news list
    useEffect(() => {
        setNewsData({
            ...newsData,
            news: [],
            loading: true,
        });

        const fetchNews = async () => {
            try {
                // Nbre de news au total ( pour la pagination )
                let newsCount = currentPagination.itemsCount;
                if (
                    NewsFetchTriggerMode.NEWS_AND_COUNT ===
                    newsFetchTrigger.mode
                ) {
                    newsCount = await getFirebaseCollectionCount('news');
                }

                // News
                const { newsList, querySnapshot } = await getFirebaseNews(
                    currentPagination
                );

                setNewsData({
                    news: newsList,
                    loading: false,
                    newsCount,
                });

                setCurrentPagination({
                    ...currentPagination,
                    itemsCount: newsCount,
                    items: querySnapshot.docs,
                });
            } catch (error) {
                setNewsData({
                    news: [],
                    loading: false,
                    newsCount: 0,
                });
                setUiMessageFailure({
                    message: 'Fetch news list failure',
                    log: {
                        type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                        data: error,
                    },
                });
            }
        };
        fetchNews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsFetchTrigger.date, currentPagination.currentPage]);

    return (
        <div className="flex-1">
            <div className="grid grid-flow-row-dense grid-cols-1 gap-6 md:grid-cols-3">
                <div className="bs-title md:col-span-3">
                    <div className="icon-wrapper">
                        <i className="fa-solid fa-star"></i>
                    </div>
                    What's up
                </div>
                <Card className="h-fit w-full">
                    <CardBody>
                        <Typography
                            variant="h5"
                            className="font-normal text-neutral-500"
                        >
                            News form
                        </Typography>
                        {uiMessageData && (
                            <AlertCustom
                                className="mb-3"
                                uiMessage={uiMessageData}
                                visible={Boolean(uiMessageData)}
                            />
                        )}
                        <form
                            id="form-news"
                            onSubmit={handleSubmit(onSubmitForm)}
                            className="flex flex-col flex-wrap gap-6"
                        >
                            <div>
                                <input type="hidden" {...register('image')} />
                                <Input
                                    crossOrigin={'anonymous'}
                                    size="lg"
                                    variant="outlined"
                                    label="Title"
                                    error={!!errors.title}
                                    {...register('title', {
                                        required: 'Fill the "title" please',
                                    })}
                                />
                                {errors.title && (
                                    <FieldErrorMessage
                                        message={
                                            errors?.title?.message?.toString() ??
                                            ''
                                        }
                                    />
                                )}
                            </div>
                            <DragAndDropFile
                                dragAndDropFileData={dragAndDropFileData}
                                setDragAndDropFileData={setDragAndDropFileData}
                                label="Image"
                            />
                            <div>
                                <Textarea
                                    size="lg"
                                    variant="outlined"
                                    label="Content"
                                    rows={12}
                                    error={!!errors.content}
                                    {...register('content', {
                                        required: 'Fill the "content" please',
                                    })}
                                />
                                {errors.content && (
                                    <FieldErrorMessage
                                        message={
                                            errors?.content?.message?.toString() ??
                                            ''
                                        }
                                    />
                                )}
                            </div>
                            <div className="flex-wrap-row-between gap-1">
                                <Button
                                    className="flex-1"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Button>
                                <Button
                                    className="flex-1"
                                    type="reset"
                                    disabled={isSubmitting}
                                    color="gray"
                                    onClick={onClickReset}
                                >
                                    Reset
                                </Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
                <Card className="h-fit w-full">
                    <CardBody>
                        <Typography
                            variant="h5"
                            className="font-normal text-neutral-500"
                        >
                            News preview
                        </Typography>
                        {
                            <div
                                className="mx-auto mb-6 h-64 w-96 rounded-md border border-gray-50 bg-gray-50 bg-cover bg-center bg-no-repeat shadow-md"
                                style={newsPreview.imagePreviewStyle}
                            ></div>
                        }
                        <Typography
                            variant="h6"
                            className="font-normal text-neutral-500"
                        >
                            {newsPreview.title || "News' title..."}
                        </Typography>
                        <Typography>
                            {newsPreview.content || "News' content..."}
                        </Typography>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="flex-1 divide-y divide-dashed divide-gray-300">
                        <Pagination
                            setCurrentPagination={
                                setPaginationDataFromPaginationComponent
                            }
                            currentPagination={currentPagination}
                            showInfos={true}
                        />
                        {newsData.loading ? (
                            <Loading />
                        ) : (
                            newsData.news.map((newsEntry) => {
                                return (
                                    <BackShopWhatsUpNews
                                        key={newsEntry.id}
                                        news={newsEntry}
                                        onClickNewsEditionHandler={
                                            onClickNewsEditionHandler
                                        }
                                        onClickNewsDeleteHandler={
                                            onClickNewsDeleteHandler
                                        }
                                    />
                                );
                            })
                        )}
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default BackShopWhatsUp;
