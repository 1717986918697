import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useBlogArticle } from '../app/hooks/useBlogArticle';
import { useFetchAndDispatchBlogCategories } from '../app/hooks/useFetchAndDispatchBlogCategories';
import { BlogArticle } from '../models/backShop/blogArticle';
import { BlogCategory } from '../models/backShop/blogCategory';
import SelectableTags from './31st/Common/Tag/SelectableTags';
import CodeArticle from './31stcode/CodeArticle';
import CodeArticleCompact from './31stcode/CodeArticleCompact';
import CodeArticleMissing from './31stcode/CodeArticleMissing';
import Loading from './Common/Loading/Loading';
import Pagination from './Common/Pagination/Pagination';
import SeoMetaData from './Common/Seo/SeoMetaData';

const Code = () => {
    // Blog categories
    const { blogCategories } = useFetchAndDispatchBlogCategories();

    const {
        articles,
        articlesCount,
        loading,
        pagination,
        setCurrentPagination,
        filterCategory,
        filterCategoryChange,
        setFilterCategory,
    } = useBlogArticle(5);

    const [currentArticle, setCurrentArticle] = useState<
        BlogArticle | undefined
    >(undefined);

    // Paramètre "article": Lien direct vers un article
    const { article: paramArticleId } = useParams();

    const onClickArticle = (article: BlogArticle) => {
        setCurrentArticle(article);
    };

    useEffect(() => {
        if (currentArticle || !articles.length) return;

        if (paramArticleId) {
            // En priorité: Recherche l'id passé en paramètre dans la liste des articles
            const selectedArticle = articles.find(
                (currentArticle) => currentArticle.id === paramArticleId
            );

            setCurrentArticle(selectedArticle);
            return;
        }

        // Affichage par défaut de l'article le plus récent
        setCurrentArticle({ ...articles[0] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articlesCount, paramArticleId]);

    useEffect(() => {
        if (articles.length) {
            setCurrentArticle({ ...articles[0] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterCategoryChange]);

    return (
        <>
            {currentArticle ? (
                <SeoMetaData
                    title={currentArticle.title}
                    description={currentArticle.seoDescription}
                    type={'article'}
                />
            ) : (
                <SeoMetaData
                    title={'31stcode, front development code blocks'}
                    description={'A collection of snippets, best practices, tips or usefull portions of code. React, TypeScript, Javascript, CSS, HTML...and more'}
                    type={'article'}
                />
            )}
            <div className="page-31st page-31stcode min-h-screen flex z-0">
                <div className="md:grid md:grid-cols-3 min-h-full flex-1">
                    <div className="flex-wrap-col p-3 pl-6 pt-0 pb-6 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-rose-400 to-indigo-400">
                        <div className="flex-1 flex-wrap-col p-3 pl-6 pt-0 pb-6 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-rose-400 to-indigo-400 to-40% drop-shadow-[2px_0_8px_#1e1b4b] rounded-b-xl">
                            <aside className="flex-1 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-rose-400 to-indigo-400 p-6 flex-wrap-col drop-shadow-[2px_0_8px_#1e1b4b] rounded-b-lg">
                                <div className="from-white to-60% to-transparent rounded-tr-2xl self-end">
                                    <h1 className="drop-shadow-[0_1px_1px_rgba(0,0,0,0.4)] flex-wrap-row justify-end gap-3">
                                        Code Notepad{' '}
                                        <i className="fa-solid fa-code text-4xl"></i>
                                    </h1>
                                    <p className="text-justify pb-6">
                                        This "
                                        <span className="font-bold">
                                            code notepad
                                        </span>
                                        " is a collection of{' '}
                                        <span className="italic">snippets</span>
                                        , <span className="italic">tips</span>{' '}
                                        or simply usefull{' '}
                                        <span className="italic">portions</span>{' '}
                                        of code that i find interesting and good
                                        to keep somewhere. So, pick what you
                                        need and be critical, maybe there's a
                                        better way?
                                    </p>

                                    <div className="my-6 p-3 bg-white/10 rounded-md border border-dashed relative">
                                        <h6 className="drop-shadow-[0_1px_1px_rgba(0,0,0,0.4)] absolute top-0 -translate-y-full p-0 px-3 right-0 rounded-md text-md flex-wrap-row gap-1">
                                            Filtering by category
                                            <i className="fa-solid fa-filter"></i>
                                        </h6>
                                        {blogCategories.length > 0 && (
                                            <div className="flex-wrap-row gap-1">
                                                <SelectableTags<
                                                    BlogCategory,
                                                    string
                                                >
                                                    entities={blogCategories}
                                                    setSelectedKeys={
                                                        setFilterCategory
                                                    }
                                                    pathes={{
                                                        key: 'id',
                                                        label: 'label',
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <Pagination
                                        setCurrentPagination={
                                            setCurrentPagination
                                        }
                                        currentPagination={pagination}
                                        showInfos={true}
                                        showPageLabel={true}
                                        buttonsColor="white"
                                    />
                                    <div>
                                        {loading ? (
                                            <Loading />
                                        ) : articles.length > 0 ? (
                                            articles.map((article) => {
                                                return (
                                                    <CodeArticleCompact
                                                        key={`article-${article.id}`}
                                                        filterCategory={
                                                            filterCategory
                                                        }
                                                        onClickHandler={
                                                            onClickArticle
                                                        }
                                                        className={'pb-6'}
                                                        article={article}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <div className="italic text-center text-white pt-8">
                                                No article available
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>

                    <main className="md:col-span-2 p-6 flex">
                        {loading && <Loading />}
                        {!loading &&
                            (currentArticle ? (
                                <CodeArticle article={currentArticle} />
                            ) : (
                                <CodeArticleMissing />
                            ))}
                    </main>
                </div>
            </div>
            <Link to="/" className="z-50">
                <div className="title-badge code fixed">31st</div>
            </Link>
        </>
    );
};

export default Code;
