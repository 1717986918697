import {
    DocumentData,
    Query,
    QueryConstraint,
    collection,
    getDocs,
    orderBy,
    query,
} from 'firebase/firestore';
import { firestoreDb } from '../../lib/firebase';
import { BlogCategory } from '../../models/backShop/blogCategory';

export const getFirebaseBlogCategories = async () => {
    const theCollection = collection(firestoreDb, 'blog-category');

    // Query parameters
    const queryConstraints: QueryConstraint[] = [
        orderBy('label', 'asc'),
        orderBy('createdAt', 'desc'),
        orderBy('updatedAt', 'desc'),
    ];

    // The query
    const queryCategories: Query<DocumentData> = query(theCollection, ...queryConstraints);

    // The query snapshot
    const querySnapshot = await getDocs(queryCategories);

    // The data
    const blogCategoryList: BlogCategory[] = [];
    querySnapshot.forEach((doc) => {
        const news = {
            ...doc.data(),
            id: doc.id,
            // Pour fixer pb de sérialisation lorsque les data sont injectées dans le store
            createdAt: doc.data().createdAt?.toDate().getTime(),
            updatedAt: doc.data().updatedAt?.toDate().getTime(),
        } as BlogCategory;

        blogCategoryList.push(news);
    });

    return {
        blogCategoryList,
        querySnapshot,
    };
};
