import { Alert } from '@material-tailwind/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { STATUS, UiMessage } from '../../../models/generic';

interface AlertCustomProps {
    className?: string;
    uiMessage: UiMessage;
    visible: boolean;
    dissmisable?: boolean;
}

const getMessage = (uiMessage: UiMessage) => {
    const date = dayjs(uiMessage.date);

    return (
        <>
            {uiMessage.message}
            <span className="ml-1 text-xs">({date.format('LL LTS')})</span>
        </>
    );
};

const AlertCustom = ({
    className = undefined,
    uiMessage,
    visible,
    dissmisable = true,
}: AlertCustomProps) => {
    const [open, setOpen] = useState(true);

    if (!visible || !open) return <></>;

    const color = uiMessage.status === STATUS.SUCCESS ? 'light-blue' : 'pink';
    const icon = uiMessage.status === STATUS.SUCCESS ? 'fa-heart' : 'fa-skull';

    return (
        <Alert
            className={className}
            children={getMessage(uiMessage)}
            color={color}
            icon={<i className={`fa-solid ${icon}`}></i>}
            onClose={dissmisable ? () => setOpen(false) : undefined}
            open={visible}
        />
    );
};

export default AlertCustom;
