import {
    DocumentData,
    Query,
    QueryConstraint,
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
} from 'firebase/firestore';
import { firestoreDb } from '../../lib/firebase';
import { NewsFirebase } from '../../models/backShop/news';
import { PaginationData } from '../../models/generic';

export const getFirebaseNews = async (paginationData: PaginationData) => {
    const theCollection = collection(firestoreDb, 'news');

    // Query parameters
    const queryConstraints: QueryConstraint[] = [
        orderBy('createdAt', 'desc'),
        orderBy('updatedAt', 'desc'),
        limit(paginationData.itemsPerPage),
    ];

    if (paginationData.itemsCursors.get(paginationData.currentPage)) {
        queryConstraints.push(
            startAfter(paginationData.itemsCursors.get(paginationData.currentPage))
        );
    }

    // The query
    const queryNews: Query<DocumentData> = query(theCollection, ...queryConstraints);

    // The query snapshot
    const querySnapshot = await getDocs(queryNews);

    // The data
    const newsList: NewsFirebase[] = [];
    querySnapshot.forEach((doc) => {
        const news = {
            ...doc.data(),
            id: doc.id,
        } as NewsFirebase;
        newsList.push(news);
    });

    return {
        newsList,
        querySnapshot,
    };
};
