import { useState } from 'react';
import { useFetchAndDispatchBlogCategories } from '../../../app/hooks/useFetchAndDispatchBlogCategories';
import { getDateDataFromTimestamp } from '../../../lib/functionsDate';
import { BlogArticle } from '../../../models/backShop/blogArticle';
import { BlogCategory } from '../../../models/backShop/blogCategory';
import Tag from '../../31st/Common/Tag/Tag';
import ButtonIcon from '../../Common/Button/ButtonIcon';
import DialogCustom from '../../Common/Dialog/DialogCustom';

interface BackshopBlogArticleProps {
    className?: string;
    article: BlogArticle;
    onClickArticleEditionHandler: (article: BlogArticle) => void;
    onClickArticleDeleteHandler: (article: BlogArticle) => void;
}

const getCategory = (categoryList: BlogCategory[], categoryId: string) => {
    const theCat = categoryList.find((category) => category.id === categoryId);
    if (!theCat) return <></>;

    return <Tag key={`category-${theCat.id}`} content={theCat.label} />;
};

const BackshopBlogArticle = ({
    article,
    onClickArticleEditionHandler,
    onClickArticleDeleteHandler,
    className = undefined,
}: BackshopBlogArticleProps) => {
    const dateData = getDateDataFromTimestamp(article.createdAt, article.updatedAt);

    // Blog categories
    const { blogCategories } = useFetchAndDispatchBlogCategories();

    const [dialogOpen, setDialogOpen] = useState(false);

    const onClickDeleteNewsConfirmHandler = async () => {
        onClickArticleDeleteHandler(article);
    };

    return (
        <div className={`relative${className ? ` ${className}` : ``}`}>
            <div className="absolute left-1/2 -translate-x-1/2 top-0 text-xs px-2 py-1 rounded-b-md bg-pink-300 text-white">
                {article.type}
            </div>
            <DialogCustom<string>
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                callbackHandlerParam={article.id}
                callbackHandler={onClickDeleteNewsConfirmHandler}
                dialogMessage={'Confirm article deletion ?'}
            />
            <h6 className={'p-0 flex-wrap-row gap-2'}>
                {article.title}
                <ButtonIcon
                    className="ms-auto"
                    iconClass={'fa-pen'}
                    onClickHandler={() => onClickArticleEditionHandler(article)}
                />
                <ButtonIcon
                    iconClass={'fa-trash'}
                    type="danger"
                    onClickHandler={() => setDialogOpen(true)}
                />
            </h6>
            <p className="text-sm py-2">
                <span className="text-gray-500">{dateData.label}:</span>{' '}
                {dateData.date.format('llll')}
            </p>
            {blogCategories.length > 0 && (
                <div className="flex-wrap-row gap-1">
                    {article.categories.map((categoryId) => {
                        return getCategory(blogCategories, categoryId);
                    })}
                </div>
            )}
        </div>
    );
};

export default BackshopBlogArticle;
