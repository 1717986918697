import { IconButton } from '@material-tailwind/react';
import { useEffect } from 'react';
import { getPaginationPages } from '../../../features/pagination/pagination';
import { PaginationData, PaginationDirection } from '../../../models/generic';

export interface PaginationTriggerAction {
    next: number;
    prev: number;
    page: number;
}

interface PaginationProps {
    className?: string;
    currentPagination: PaginationData;
    // Affichage des n° de page ( inexploitable pour Firebase/Firestore la pagination dépend des curseurs )
    showPages?: boolean;
    showPageLabel?: boolean;
    showInfos?: boolean;
    setCurrentPagination: (currentPagination: Partial<PaginationData>) => void;
    triggerAction?: PaginationTriggerAction;
    buttonsColor?: 'purple' | 'white';
}

export const paginationDataDefault: PaginationData = {
    currentPageAccess: PaginationDirection.PAGE,
    currentPage: 1,
    currentPageIsTheFirst: true,
    currentPageIsTheLast: false,
    itemsCount: 0,
    itemsCursors: new Map(),
    itemsPerPage: 10,
    items: [],
    pages: [],
};

const getLastPage = (pages: number[]) => {
    return pages.slice(-1)[0] ?? 1;
};

const isFirstPage = (pageNumber: number) => {
    return pageNumber === 1;
};

const isLastPage = (pages: number[], pageNumber: number) => {
    return pageNumber === getLastPage(pages);
};

// Pagination
// Pour la pagination Firebase/Firestore cf: https://firebase.google.com/docs/firestore/query-data/query-cursors?hl=fr
const Pagination = ({
    className = undefined,
    currentPagination,
    setCurrentPagination,
    showPages = false,
    showInfos = true,
    showPageLabel = false,
    triggerAction = undefined,
    buttonsColor = 'purple',
}: PaginationProps) => {
    const onClickPage = (pageNumber: number) => {
        if (!currentPagination.pages.includes(pageNumber)) return;

        setCurrentPaginationUpdated({
            currentPage: pageNumber,
            currentPageAccess: PaginationDirection.PAGE,
        });
    };

    const onClickPrevPage = () => {
        if (!isFirstPage(currentPagination.currentPage)) {
            setCurrentPaginationUpdated({
                currentPage: currentPagination.currentPage - 1,
                currentPageAccess: PaginationDirection.PREV,
            });
        }
    };

    const onClickNextPage = () => {
        if (!isLastPage(currentPagination.pages, currentPagination.currentPage)) {
            setCurrentPaginationUpdated({
                currentPage: currentPagination.currentPage + 1,
                currentPageAccess: PaginationDirection.NEXT,
            });
        }
    };

    const setCurrentPaginationUpdated = (paginationPartial?: Partial<PaginationData>) => {
        const currentPageUpdated = paginationPartial?.currentPage ?? currentPagination.currentPage;

        const pages = getPaginationPages(
            currentPagination.itemsCount,
            currentPagination.itemsPerPage
        );

        const currentPageIsTheFirst = isFirstPage(currentPageUpdated);
        const currentPageIsTheLast = isLastPage(pages, currentPageUpdated);

        // Reprise de la liste des curseurs
        let itemsCursorsUpdated = currentPagination.itemsCursors;
        if (!currentPagination.currentPageIsTheLast) {
            const lastItem = currentPagination.items.slice(-1)?.[0];
            if (lastItem) {
                itemsCursorsUpdated = new Map(currentPagination.itemsCursors);
                itemsCursorsUpdated.set(currentPagination.currentPage + 1, lastItem);
            }
        }

        setCurrentPagination({
            ...paginationPartial,
            pages,
            itemsCursors: itemsCursorsUpdated,
            currentPageIsTheFirst,
            currentPageIsTheLast,
        });
    };

    useEffect(() => {
        setCurrentPaginationUpdated();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination.itemsCount, currentPagination.items]);

    // Trigger: Prev
    useEffect(() => {
        if (!triggerAction) return;

        onClickPrevPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerAction?.prev]);

    // Trigger: Next
    useEffect(() => {
        if (!triggerAction) return;

        onClickNextPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerAction?.next]);

    // Trigger: Page
    useEffect(() => {
        if (!triggerAction || !triggerAction?.page) return;

        onClickPage(triggerAction?.page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerAction?.page]);

    return (
        <nav className={`flex-wrap-row-center gap-1 pb-4${className ? ` ${className}` : ``}`}>
            <IconButton
                variant="text"
                color={buttonsColor}
                size="sm"
                className="flex-wrap-row-center gap-1"
                onClick={() => onClickPrevPage()}
                disabled={1 === currentPagination.currentPage}
            >
                <i className="fa-solid fa-angle-left"></i>
            </IconButton>
            {showPages &&
                currentPagination.pages.map((pageNumber) => {
                    const variant =
                        pageNumber === currentPagination.currentPage ? `filled` : `text`;

                    return (
                        <IconButton
                            key={`pagination-page-${pageNumber}`}
                            variant={variant}
                            size="sm"
                            onClick={() => onClickPage(pageNumber)}
                        >
                            {pageNumber}
                        </IconButton>
                    );
                })}
            {showInfos && (
                <div className="flex-wrap-row-between gap-2 text-sm">
                    {showPageLabel ? 'Page ' : undefined}
                    {currentPagination.currentPage} / {currentPagination.pages.length}
                </div>
            )}
            <IconButton
                variant="text"
                color={buttonsColor}
                size="sm"
                className="flex-wrap-row-center gap-1"
                onClick={() => onClickNextPage()}
                disabled={isLastPage(currentPagination.pages, currentPagination.currentPage)}
            >
                <i className="fa-solid fa-angle-right"></i>
            </IconButton>
        </nav>
    );
};

export default Pagination;
