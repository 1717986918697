import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirebaseCollectionCount } from '../../../features/firebase/firebase';
import { getFirebaseNews } from '../../../features/firebase/firebaseNews';
import { NewsType } from '../../../models/31st';
import { NewsData } from '../../../models/backShop/news';
import { PaginationData } from '../../../models/generic';
import Pagination, {
    PaginationTriggerAction,
    paginationDataDefault,
} from '../../Common/Pagination/Pagination';
import ConditionalWrapper from '../../ConditionalWrapper';
import TitleColored from '../Common/TitleColored/TitleColored';
import NewsDisplay from './NewsDisplay';

const getNewsNavigation = (
    prevHandler: () => void,
    nextHandler: () => void,
    pagination: PaginationData
) => {
    return (
        <div className="flex-wrap-row gap-3 text-col01-dark">
            <ConditionalWrapper
                condition={!pagination.currentPageIsTheFirst}
                wrapper={(children: any) => {
                    return (
                        <Link
                            to={'#'}
                            onClick={pagination.currentPageIsTheFirst ? undefined : prevHandler}
                        >
                            {children}
                        </Link>
                    );
                }}
            >
                <div>
                    <i className="fa-solid fa-left-long text-base"></i>
                </div>
            </ConditionalWrapper>
            <ConditionalWrapper
                condition={!pagination.currentPageIsTheLast}
                wrapper={(children: any) => {
                    return (
                        <Link
                            to={'#'}
                            onClick={pagination.currentPageIsTheLast ? undefined : nextHandler}
                        >
                            {children}
                        </Link>
                    );
                }}
            >
                <div>
                    <i className="fa-solid fa-right-long text-base"></i>
                </div>
            </ConditionalWrapper>
        </div>
    );
};

const News = () => {
    // Pagination
    const [currentPagination, setCurrentPagination] = useState<PaginationData>({
        ...paginationDataDefault,
        itemsPerPage: 3,
    });
    const [paginationTriggerAction, setPaginationTriggerAction] = useState<PaginationTriggerAction>(
        {
            prev: 0,
            next: 0,
            page: 0,
        }
    );

    // Statut & data news
    const [newsData, setNewsData] = useState<NewsData>({
        news: [],
        loading: false,
        newsCount: 0,
    });

    const onClickPrevHandler = () => {
        setPaginationTriggerAction({
            ...paginationTriggerAction,
            prev: paginationTriggerAction.prev - 1,
        });
    };

    const onClickNextHandler = () => {
        setPaginationTriggerAction({
            ...paginationTriggerAction,
            next: paginationTriggerAction.next + 1,
        });
    };

    const setPaginationDataFromPaginationComponent = (
        paginationPartial: Partial<PaginationData>
    ): void => {
        setCurrentPagination({
            ...currentPagination,
            ...paginationPartial,
        });
    };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                // Nbre de news au total ( pour la pagination )
                let newsCount = currentPagination.itemsCount;
                if (0 === newsCount) {
                    newsCount = await getFirebaseCollectionCount('news');
                }

                // News
                const { newsList, querySnapshot } = await getFirebaseNews(currentPagination);

                setNewsData({
                    news: newsList,
                    loading: false,
                    newsCount,
                });

                setCurrentPagination({
                    ...currentPagination,
                    itemsCount: newsCount,
                    items: querySnapshot.docs,
                });
            } catch (error) {
                setNewsData({
                    news: [],
                    loading: false,
                    newsCount: 0,
                });
            }
        };
        fetchNews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination.currentPage]);

    const newsEmpty = new Array(Math.max(0, currentPagination.itemsPerPage - newsData.news.length))
        .fill(undefined)
        .map((_, index) => (
            <NewsDisplay
                key={`news-empty-${index}`}
                type={NewsType.NEWS_EMPTY}
                className="pb-6 sm:pb-0"
            />
        ));

    return (
        <>
            {/* News pagination externalisée, ce composant sert uniquement à la gestion */}
            <Pagination
                className="hidden"
                setCurrentPagination={setPaginationDataFromPaginationComponent}
                currentPagination={currentPagination}
                triggerAction={paginationTriggerAction}
                showInfos={false}
            />
            <TitleColored
                sentence={"What's up ?"}
                className={'sm:col-span-3'}
                suffixContent={getNewsNavigation(
                    onClickPrevHandler,
                    onClickNextHandler,
                    currentPagination
                )}
            />
            {newsData.news.map((news) => {
                return (
                    <NewsDisplay
                        key={`news-${news.id}`}
                        type={NewsType.NEWS}
                        className="pb-6 sm:pb-0"
                        data={news}
                    />
                );
            })}
            {newsEmpty}
        </>
    );
};

export default News;
