import { HashLink } from 'react-router-hash-link';
import { FLEX_ITEMS_MAX_QUANTITY } from '../../../constants/constants';

type FlexSectionTitleRenderActionsProps = {
    itemAddHandler?: () => void;
    fullResetHandler?: () => void;
};

// Actions disponible dans la barre de titre "render"
const FlexSectionTitleRenderActions = ({
    itemAddHandler,
    fullResetHandler,
}: FlexSectionTitleRenderActionsProps) => {
    return (
        <div className="row flex gap-px">
            <button className="button action-item-add rounded-l-md" onClick={itemAddHandler}>
                <i className="fa-solid fa-plus"></i>Add item (
                <span className="text-sm">
                    &nbsp;
                    <i className="fa-solid fa-plus-minus"></i>
                    {FLEX_ITEMS_MAX_QUANTITY}&nbsp;
                </span>
                )
            </button>
            <HashLink smooth to={'#csscode'} className="button action-goto-css">
                <i className="fa-brands fa-css3"></i>Get CSS code
            </HashLink>
            <button className="button action-total-reset rounded-r-md" onClick={fullResetHandler}>
                <i className="fa-solid fa-shower"></i>Full reset
            </button>
        </div>
    );
};

export default FlexSectionTitleRenderActions;
