import { IconButton } from '@material-tailwind/react';

interface BackShopHeaderProps {
    collapsed: boolean;
    onClickToggleCollapsed: () => void;
}

const BackShopHeader = ({ onClickToggleCollapsed }: BackShopHeaderProps) => {
    return (
        <IconButton className="backshop-header" size="sm" onClick={onClickToggleCollapsed}>
            <i className="fas fa-bars" />
        </IconButton>
    );
};

export default BackShopHeader;
