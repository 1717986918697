import { createSlice } from '@reduxjs/toolkit';
import {
    BlogCategorySliceCategoriesAction,
    BlogCategoryState,
} from '../../models/backShop/blogCategory';
import { FETCH_STATUS } from '../../models/generic';

const initialState: BlogCategoryState = {
    status: FETCH_STATUS.IDLE,
    categories: [],
};

const blogCategorySlice = createSlice({
    name: 'blogCategory',
    initialState,
    reducers: {
        setBlogCategoriesStatus: (
            state,
            action: BlogCategorySliceCategoriesAction
        ) => {
            state.status = action.payload.status;
        },
        setBlogCategories: (
            state,
            action: BlogCategorySliceCategoriesAction
        ) => {
            state.status = action.payload.status;
            state.categories = action.payload.categories;
        },
    },
});

export const { setBlogCategoriesStatus, setBlogCategories } =
    blogCategorySlice.actions;

export default blogCategorySlice.reducer;
