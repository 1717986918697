export type TupleStringString = [string, string];

export type CssMap = Map<string, TupleStringString[]>;

export enum FlexOptionTypeFamily {
    CONTAINER = 'container',
    ITEM = 'item',
    SELECTOR = 'selector',
}

export enum FlexOptionType {
    // Container family
    FLEX_CONTAINER_PROP = 'flex-container-prop',
    FLEX_CONTAINER_VALUE = 'flex-container-value',
    // Item family
    FLEX_ITEM_PROP = 'flex-item-prop',
    FLEX_ITEM_VALUE = 'flex-item-value',
    FLEX_ITEM_PROP_AND_VALUE = 'flex-item-prop-and-value',
    // Selector family
    FLEX_ITEM_SELECTOR = 'flex-item-selector',
}

export enum FlexOptionProperty {
    ALIGN_CONTENT = 'align-content',
    ALIGN_ITEMS = 'align-items',
    ALIGN_SELF = 'align-self',
    DISPLAY = 'display',
    FLEX_BASIS = 'flex-basis',
    FLEX_DIRECTION = 'flex-direction',
    FLEX_GROW = 'flex-grow',
    FLEX_SHRINK = 'flex-shrink',
    FLEX_WRAP = 'flex-wrap',
    GAP = 'gap',
    ITEMS_SELECTOR = 'items-selector',
    JUSTIFY_CONTENT = 'justify-content',
    ORDER = 'order',
}

export enum FlexOptionPropertyValue {
    CONTENT = 'content',
    AUTO = 'auto',
    BASELINE = 'baseline',
    CENTER = 'center',
    COLUMN = 'column',
    COLUMN_REVERSE = 'column-reverse',
    FLEX = 'flex',
    FLEX_END = 'flex-end',
    FLEX_START = 'flex-start',
    INLINE_FLEX = 'inline-flex',
    NOWRAP = 'nowrap',
    ROW = 'row',
    ROW_REVERSE = 'row-reverse',
    SPACE_AROUND = 'space-around',
    SPACE_BETWEEN = 'space-between',
    STRETCH = 'stretch',
    WRAP = 'wrap',
    WRAP_REVERSE = 'wrap-reverse',
}

export enum FlexOptionPropertyValueUnit {
    PIXEL = 'px',
    EM = 'em',
    REM = 'rem',
    PERCENT = '%',
}

export type FlexOptionPropertyValueTypes =
    | FlexOptionPropertyValue
    | number
    | string;

export type FlexOptionSetting = {
    type: FlexOptionType;
    property: FlexOptionProperty;
    options: FlexOptionPropertyValue[] | number[];
    default: FlexOptionPropertyValueTypes;
    value: FlexOptionPropertyValueTypes;
    regex?: RegExp;
    units?: FlexOptionPropertyValueUnit[];
    unit?: FlexOptionPropertyValueUnit;
    unitDefault?: FlexOptionPropertyValueUnit;
};

export type Setting = {
    title: string;
    setting: FlexOptionSetting;
    order: number;
};

export type SettingsDefaultMap = Map<FlexOptionProperty, Setting>;

export type SettingsMap = Map<number, SettingsDefaultMap>;

export type InlineStyle = {
    [key: string]: string;
};
