import { useState } from 'react';
import { getOptionTypeFamily, itemSelectorTextFormat } from '../../../features/31stflex';
import { implodeOrUndefined } from '../../../lib/functionsArray';
import {
    FlexOptionPropertyValueTypes,
    FlexOptionSetting,
    FlexOptionType,
    FlexOptionTypeFamily,
} from '../../../models/31stflex';
import FlexOptionNumber from './FlexOptionNumber';

type FlexOptionProps = {
    title: string;
    setting: FlexOptionSetting;
    selectorOptionResetHandler: () => void;
    selectorOptionSelectionHandler: (itemNumber: FlexOptionPropertyValueTypes) => void;
    containerOptionSelectionHandler: (settingUpdated: FlexOptionSetting) => void;
    itemOptionSelectionHandler: (settingUpdated: FlexOptionSetting) => void;
};

const FlexOption = ({
    title,
    setting,
    selectorOptionResetHandler,
    selectorOptionSelectionHandler,
    containerOptionSelectionHandler,
    itemOptionSelectionHandler,
}: FlexOptionProps) => {
    const hasPropertySelector = [
        FlexOptionType.FLEX_CONTAINER_PROP,
        FlexOptionType.FLEX_ITEM_PROP,
        FlexOptionType.FLEX_ITEM_SELECTOR,
        FlexOptionType.FLEX_ITEM_PROP_AND_VALUE,
    ].includes(setting.type);

    const hasInput = [
        FlexOptionType.FLEX_CONTAINER_VALUE,
        FlexOptionType.FLEX_ITEM_VALUE,
        FlexOptionType.FLEX_ITEM_PROP_AND_VALUE,
    ].includes(setting.type);

    const [overReset, setOverReset] = useState(false);

    const onMouseOverResetHandler = () => {
        setOverReset(true);
    };

    const onMouseOutResetHandler = () => {
        setOverReset(false);
    };

    // Sélection d'une valeur
    const onClickHandler = (selectedOption: FlexOptionPropertyValueTypes) => {
        switch (getOptionTypeFamily(setting.property)) {
            case FlexOptionTypeFamily.SELECTOR:
                selectorOptionSelectionHandler(selectedOption);
                break;

            // Pour les valeurs conteneur
            case FlexOptionTypeFamily.CONTAINER:
                containerOptionSelectionHandler({
                    ...setting,
                    value: selectedOption,
                });
                break;

            // Pour les valeurs item
            case FlexOptionTypeFamily.ITEM:
                itemOptionSelectionHandler({
                    ...setting,
                    value: selectedOption,
                });
                break;
        }
    };

    // Reset
    const onClickResetHandler = () => {
        setOverReset(false);

        switch (getOptionTypeFamily(setting.property)) {
            // Pour le sélecteur d'items
            case FlexOptionTypeFamily.SELECTOR:
                selectorOptionResetHandler();
                break;

            // Pour les valeurs conteneur
            case FlexOptionTypeFamily.CONTAINER:
                const containerSettingUpdated = {
                    ...setting,
                    value: setting.default,
                };
                if (setting.unitDefault) containerSettingUpdated.unit = setting.unitDefault;
                containerOptionSelectionHandler(containerSettingUpdated);
                break;

            // Pour les valeurs item
            case FlexOptionTypeFamily.ITEM:
                const itemSettingUpdated = {
                    ...setting,
                    value: setting.default,
                };
                if (setting.unitDefault) itemSettingUpdated.unit = setting.unitDefault;
                itemOptionSelectionHandler(itemSettingUpdated);
                break;
        }
    };

    const wrapperOptionCssClasses = ['wrapper-option mb-3', ...(overReset ? ['cold'] : [])];

    return (
        <div className={wrapperOptionCssClasses.join(' ')}>
            <div className="flex-wrap-row-between">
                <div>
                    <span className="title-wrappers">&#123; </span>
                    {title}
                    <span className="title-wrappers"> &#125;</span>
                </div>
                <i
                    className="option-reset fa-solid fa-retweet"
                    onClick={onClickResetHandler}
                    onMouseOver={onMouseOverResetHandler}
                    onMouseOut={onMouseOutResetHandler}
                ></i>
            </div>
            <div className="relative h-max w-full">
                {overReset && (
                    <div className="hover-card">
                        <div className="icon-wrapper">
                            <i className="fa-solid fa-recycle"></i>
                        </div>
                        {'flex-item-selector' === setting.type
                            ? 'Clear all items settings ?'
                            : 'Reset to default ?'}
                    </div>
                )}
                <div className={setting.type}>
                    {hasPropertySelector &&
                        setting.options.map((option, index) => {
                            const cssClasses = [
                                'flex-prop',
                                ...(setting.value === option ? ['selected'] : []),
                                // Spécificité pour les boutons "items selector"
                                ...(Number.isInteger(option)
                                    ? 0 === option
                                        ? // "All": full size
                                          ['basis-full']
                                        : // "Others": grow
                                          ['grow']
                                    : []),
                            ];

                            return (
                                <div
                                    key={String(index)}
                                    className={implodeOrUndefined(cssClasses)}
                                    onClick={() => onClickHandler(option)}
                                >
                                    {FlexOptionType.FLEX_ITEM_SELECTOR === setting.type
                                        ? itemSelectorTextFormat(option as number)
                                        : option}
                                </div>
                            );
                        })}

                    {hasInput && (
                        <FlexOptionNumber
                            setting={setting}
                            containerOptionSelectionHandler={containerOptionSelectionHandler}
                            itemOptionSelectionHandler={itemOptionSelectionHandler}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FlexOption;
