// --- UI ---
export enum Titles {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}

// --- AXIOS ---
export enum REQUEST_TYPE {
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT',
    REQUEST = 'REQUEST',
}

export enum NewsType {
    NEWS = 'news',
    NEWS_EMPTY = 'news-empty',
}
