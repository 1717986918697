import { HashLink } from 'react-router-hash-link';
import uuid from 'react-uuid';

export enum ShinySectionType {
    EDITOR = 'editor',
    GALLERY = 'gallery',
    CSS = 'css',
}

interface ShinyTitleProps {
    type: ShinySectionType;
}

const shinySections = {
    [ShinySectionType.EDITOR]: {
        gotoIcon: 'fa-crop-simple',
    },
    [ShinySectionType.GALLERY]: {
        gotoIcon: 'fa-camera',
    },
    [ShinySectionType.CSS]: {
        gotoIcon: 'fa-code',
    },
};

const ShinyTitle = ({ type }: ShinyTitleProps) => {
    return (
        <div className="flex-wrap-row">
            <span className="text-8xl text-slate-500">
                SH<span className="text-slate-400">I</span>NY
            </span>
            <div className="flex-wrap-col items-end">
                <span className="-rotate-[10deg] text-xl uppercase tracking-[2rem] text-slate-400 pt-3">
                    {type}
                </span>
                <div className="grid w-fit -rotate-[10deg] grid-cols-2 grid-rows-2 gap-[1px]">
                    <div className="square-corner sm rounded-tl-md"></div>
                    <div className="square-corner sm rounded-tr-full"></div>
                    <div className="square-corner sm rounded-bl-3xl"></div>
                    <div className="square-corner sm rounded-br-xl bg-slate-300"></div>
                </div>
            </div>
            <div className="ml-auto">
                {Array.from(Object.entries(shinySections))
                    .filter(([name]) => name !== type)
                    .map(([name, settings]) => {
                        return (
                            <HashLink
                                key={uuid()}
                                smooth
                                to={`/31stshiny/#shiny-${name}`}
                                className="button ml-1 rounded-md uppercase text-sm"
                            >
                                <i
                                    className={`fa-solid ${settings.gotoIcon}`}
                                ></i>
                                SHINY {name}
                            </HashLink>
                        );
                    })}
            </div>
        </div>
    );
};

export default ShinyTitle;
