import { useEffect, useState } from 'react';
import {
    getFirebaseCollectionCount,
    getFirebaseCollectionCountWhereArrayContainsAny,
} from '../../features/firebase/firebase';
import { firebaseBlogArticle } from '../../features/firebase/firebaseBlogArticle';
import { BlogArticleData, UseBlogArticleReturn } from '../../models/backShop/blogArticle';
import { PaginationData } from '../../models/generic';
import { paginationDataDefault } from '../../modules/Common/Pagination/Pagination';

interface FilterCategoryInfos {
    size: number;
    triggerChange: number;
}

const articlesDataDefault: BlogArticleData = {
    data: [],
    articles: [],
    loading: false,
    articlesCount: 0,
    triggerFetchDate: undefined,
};

export const useBlogArticle = (itemsPerPage: number) => {
    // Statut & data articles
    const [articlesData, setArticlesData] = useState<BlogArticleData>({
        ...articlesDataDefault,
    });

    // Pagination
    const [currentPagination, setCurrentPagination] = useState<PaginationData>({
        ...paginationDataDefault,
        itemsPerPage,
    });

    const [filterCategory, setFilterCategory] = useState<Set<string>>(new Set());
    // Permet de détecter les modifications de filtrage afin de réinitialiser la page courante
    const [filterCategoryInfos, setFilterCategoryInfos] = useState<FilterCategoryInfos>({
        size: 0,
        triggerChange: 0,
    });

    const setPaginationDataFromPaginationComponent = (
        paginationPartial: Partial<PaginationData>
    ): void => {
        setCurrentPagination({
            ...currentPagination,
            ...paginationPartial,
        });
    };

    const triggerArticlesFetch = () => {
        setArticlesData({
            ...articlesData,
            triggerFetchDate: new Date(),
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            setArticlesData({
                ...articlesDataDefault,
                loading: true,
            });

            try {
                // Nbre d'articles au total ( pour la pagination )
                let articlesCount = currentPagination.itemsCount;

                const categoryFilterChanged = filterCategoryInfos.size !== filterCategory.size;

                // Actualisation systématique du comptage ( nécessaire pour les filtres )
                if (filterCategory.size) {
                    articlesCount = await getFirebaseCollectionCountWhereArrayContainsAny(
                        'blog-article',
                        'categories',
                        Array.from(filterCategory)
                    );
                } else {
                    articlesCount = await getFirebaseCollectionCount('blog-article');
                }

                // Articles
                const { blogArticleList, querySnapshot } = await firebaseBlogArticle(
                    currentPagination,
                    Array.from(filterCategory)
                );

                setArticlesData({
                    data: blogArticleList,
                    articles: blogArticleList,
                    loading: false,
                    articlesCount,
                });

                setCurrentPagination({
                    ...currentPagination,
                    itemsCount: articlesCount,
                    items: querySnapshot.docs,
                    currentPage: categoryFilterChanged ? 1 : currentPagination.currentPage,
                });

                setFilterCategoryInfos({
                    size: filterCategory.size,
                    triggerChange:
                        filterCategoryInfos.triggerChange + (categoryFilterChanged ? 1 : 0),
                });
            } catch (error) {
                setArticlesData({
                    ...articlesDataDefault,
                });
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articlesData.triggerFetchDate, currentPagination.currentPage, filterCategory]);

    return {
        articles: articlesData.articles,
        articlesCount: articlesData.articlesCount,
        filterCategory,
        loading: articlesData.loading,
        pagination: currentPagination,
        setCurrentPagination: setPaginationDataFromPaginationComponent,
        setFilterCategory,
        triggerArticlesFetch,
        filterCategoryChange: filterCategoryInfos.triggerChange,
    } as UseBlogArticleReturn;
};
