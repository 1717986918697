import { ChangeEvent, Dispatch, DragEvent, useRef, useState } from 'react';
import uuid from 'react-uuid';
import { DragAndDropFileData } from '../../../models/utilsFiles';
import ButtonIcon from '../Button/ButtonIcon';

interface DragAndDropFileProps {
    fieldName?: string;
    label?: string;
    // Types de fichiers acceptés ( defaut: accept="image/*" )
    accept?: string;
    dragAndDropFileData: DragAndDropFileData | undefined;
    setDragAndDropFileData: Dispatch<
        React.SetStateAction<DragAndDropFileData | undefined>
    >;
}

const getFileData = async (file: File): Promise<DragAndDropFileData> => {
    return new Promise((resolve, reject) => {
        // Génération d'une URL pour preview
        const imageUrl = URL.createObjectURL(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            // Use a regex to remove data url part
            if (reader.result) {
                // const base64String = reader.result
                // .replace('data:', '')
                // .replace(/^.+,/, '');

                const image64 = reader.result as string;

                // Infos image
                const type = image64.split(';')[0].replace('data:', '');
                const extension = type.split('/')[1];
                const name = `${uuid()}.${extension}`;

                resolve({
                    imageUrl,
                    image64,
                    file,
                    properties: {
                        extension,
                        name,
                        type,
                    },
                });
            }
        };
        reader.readAsDataURL(file);
    });
};

const DragAndDropFile = ({
    fieldName = undefined,
    label = undefined,
    accept = 'image/*',
    dragAndDropFileData,
    setDragAndDropFileData,
}: DragAndDropFileProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState(false);

    // Handle drag events
    const onDragHandler = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === 'dragenter' || e.type === 'dragover') {
            setIsDragging(true);
        } else if (e.type === 'dragleave') {
            setIsDragging(false);
        }
    };

    // Triggers when file is dropped
    const onDropHandler = async (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];

            const fileData = await getFileData(file);
            setDragAndDropFileData(fileData);
        }
    };

    // Triggers when file is selected with click
    const onChangeInputFileHandler = async (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        e.preventDefault();

        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            const fileData = await getFileData(file);
            setDragAndDropFileData(fileData);
        }
    };

    // Triggers the input when the button is clicked
    const onClickHandler = () => {
        if (!inputRef.current) return;

        inputRef.current.click();
    };

    const onClickRemoveImage = () => {
        setDragAndDropFileData(undefined);
    };

    const fileFieldName = fieldName ?? 'input-file-dnd';

    return (
        <div className="dnd-file-wrapper relative">
            {label && (
                <label className="mb-1 text-sm text-gray-600">{label}</label>
            )}
            <div
                className={`dnd-file${isDragging ? ` dragging` : ``}`}
                onClick={onClickHandler}
                onDragEnter={onDragHandler}
                onDragLeave={onDragHandler}
                onDragOver={onDragHandler}
                onDrop={onDropHandler}
            >
                <input
                    ref={inputRef}
                    id={fileFieldName}
                    name={fileFieldName}
                    className="hidden"
                    type="file"
                    onChange={onChangeInputFileHandler}
                    accept={accept}
                />
                <div className="icons flex-wrap-row-center gap-3">
                    <i className="fa-solid fa-circle-down text-3xl text-gray-300"></i>
                    <i className="fa-solid fa-circle-down text-6xl text-gray-400"></i>
                    <i className="fa-solid fa-circle-down text-3xl text-gray-300"></i>
                </div>
                <div className="text-sm">
                    Drop file here or click to select a file
                </div>
            </div>
            {dragAndDropFileData?.imageUrl && (
                <ButtonIcon
                    type="danger"
                    iconClass={'fa-xmark'}
                    className="absolute right-[-1rem] top-[0.5rem] z-50"
                    onClickHandler={onClickRemoveImage}
                />
            )}
        </div>
    );
};

export default DragAndDropFile;
