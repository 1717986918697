import {
    DocumentData,
    DocumentSnapshot,
    QueryDocumentSnapshot,
} from 'firebase/firestore';

export enum STATUS {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export enum FETCH_STATUS {
    FAILED = 'failed',
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
}

export enum UI_MESSAGE_LOG {
    ERROR_FIREBASE_FIRESTORE = '[api/firebase/firestore/error]',
    ERROR_FIREBASE_STORAGE = '[api/firebase/storage/error]',
}

export interface UiMessage {
    status: STATUS;
    message: string | JSX.Element;
    date: Date;
    log?: {
        type: UI_MESSAGE_LOG;
        data: any;
    };
}

// Pour la gestion du composant "Pagination"
export interface PaginationData {
    // Méthode d'accès pour la page actuelle
    currentPageAccess?: PaginationDirection;
    // Page courante
    currentPage: number;
    // La page courante est la première
    currentPageIsTheFirst: boolean;
    // La page courante est la dernière
    currentPageIsTheLast: boolean;
    // Nbre de pages total
    itemsCount: number;
    // Liste des curseurs par page ( pour la pagination Firebase>Firestore )
    itemsCursors: Map<number, DocumentSnapshot | undefined>;
    // Nbre d'items par page
    itemsPerPage: number;
    // Lot d'items pour la page actuelle
    items: QueryDocumentSnapshot<DocumentData>[];
    // Liste des n° de page
    pages: number[];
}

export enum PaginationDirection {
    // Affichage d'une page par sélection directe
    PAGE = 'page',
    // Affichage de la page précédente
    PREV = 'prev',
    // Affichage de la page suivante
    NEXT = 'next',
}

// Type Enum
export type Enum = { [s: number]: string };

// Object composé d'une entrée de Enum
export interface ObjectFromEnum {
    key: string;
    value: string;
}
