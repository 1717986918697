import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchAndDispatchBlogCategories } from '../app/hooks/useFetchAndDispatchBlogCategories';
import { ReactComponent as Logo31st } from '../assets/images/logo-31st.svg';
import About from './31st/About/About';
import TitleColored from './31st/Common/TitleColored/TitleColored';
import {
	DataIntroducing31stcode,
	DataIntroducing31stflex,
	DataIntroducing31stshiny,
} from './31st/Data/Data';
import HomeNavigation from './31st/Navigation/HomeNavigation';
import News from './31st/News/News';
import CodeArticleCategories from './31stcode/CodeArticleCategories';
import SeoMetaData from './Common/Seo/SeoMetaData';
import './Home.css';

const Home = () => {
    const navigate = useNavigate();
    // Blog categories
    const { blogCategories } = useFetchAndDispatchBlogCategories();

    const [topLineCounter, setTopLineCounter] = useState(0);

    const onClickTopLine = () => {
        setTopLineCounter(topLineCounter + 1);
    };

    const blogCategoriesIds = blogCategories.map((category) => category.id);

    useEffect(() => {
        if (20 === topLineCounter) {
            setTopLineCounter(0);

            navigate('/backshop');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topLineCounter]);

    useEffect(() => {
        // React router dom side effect :/
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SeoMetaData
                title={
                    'A personnal website about front web development, basically'
                }
                description={
                    "Grégory Jacob's personnal website about web development: React, Javascript, TypeScript and more"
                }
                type={'website'}
            />
            <div className="page-31st">
                <div className="top-line" onClick={onClickTopLine}></div>
                <HomeNavigation />
                <div className="wrapper-home" id="home">
                    <div className="splash">
                        <div className="flex-wrap-col-center gap-9">
                            <div className="flex-wrap-row-center gap-6">
                                <div className="logo-wrapper">
                                    <div className="logo-in">
                                        <Logo31st className="h-20 w-20" />
                                    </div>
                                </div>
                                <div className="text-xl text-col02-yellow">
                                    About web front dev, basically
                                </div>
                            </div>
                            <div className="text-center text-lg text-col01-lighter">
                                Hi, my name is{' '}
                                <span className="text-col01-lightest">
                                    Greg
                                </span>
                                , i'm currently working as{' '}
                                <span className="text-col01-lightest">
                                    front developper
                                </span>
                                , and i
                                <i className="fa-solid fa-heart mx-2 text-col02-yellow"></i>
                                it.
                                <br />
                                This is my personnal website about{' '}
                                <span className="text-col01-lightest">
                                    web development
                                </span>{' '}
                                and other stuff, welcome.
                            </div>
                        </div>

                        <div className="clippy"></div>
                    </div>
                </div>
                <div className="wrapper-home" id="whatsup">
                    <div className="splash justify-start py-6 sm:py-20">
                        <div className="grid w-96 grid-cols-1 gap-x-6 sm:w-[60%] sm:grid-cols-3">
                            <News />
                        </div>
                        <div className="clippy clippy-03"></div>
                    </div>
                </div>
                <div className="wrapper-home" id="31stcode">
                    <div className="splash">
                        <div className="flex-wrap-col-center md:flex-wrap-row-between gap-6">
                            <div
                                className="drop-shadow-[-36px_36px_3px_rgba(155,198,255,0.2)]"
                                style={{
                                    transform:
                                        'rotateX(60deg) rotateY(0deg) rotateZ(-45deg)',
                                }}
                            >
                                <div className="drop-shadow-[-36px_36px_3px_rgba(155,198,255,0.1)]">
                                    <div className="flex-wrap-col p-6 bg-gradient-to-bl from-rose-400 to-indigo-400 drop-shadow-[-36px_36px_3px_rgba(155,198,255,0.1)] rounded-full">
                                        <div className="flex-wrap-col-center gap-6 flex-1 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-rose-400 to-75% to-indigo-400 p-6 py-12 flex-wrap-col drop-shadow-[2px_0_24px_#312e81]">
                                            <h2 className="drop-shadow-[0_1px_1px_rgba(0,0,0,0.4)] flex-wrap-row justify-end gap-3 text-6xl">
                                                Code Notepad{' '}
                                                <i className="fa-solid fa-code text-6xl"></i>
                                            </h2>
                                            <CodeArticleCategories
                                                articleCategories={[
                                                    ...blogCategoriesIds.slice(
                                                        0,
                                                        6
                                                    ),
                                                ]}
                                                theme="notePad"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-96">
                                <TitleColored sentence={'31st code notePad'} />
                                <DataIntroducing31stcode />
                            </div>
                        </div>
                        <div className="clippy clippy-05"></div>
                    </div>
                </div>
                <div className="wrapper-home" id="31stflex">
                    <div className="splash">
                        <div className="flex-wrap-col-center md:flex-wrap-row-between gap-6">
                            <div className="preview-31stflex">
                                <div className="ex-item wide">01</div>
                                <div className="ex-item selected self-center">
                                    02
                                </div>
                                <div className="ex-item self-end">03</div>
                                <div className="ex-item wide">04</div>
                                <div className="ex-item self-center">05</div>
                                <div className="ex-item selected self-end">
                                    06
                                </div>
                            </div>
                            <div className="w-96">
                                <TitleColored
                                    sentence={'31st flex'}
                                    suffixContent={
                                        <div className="preview-31stflex-tiny ml-auto">
                                            <div className="ex-item">
                                                &nbsp;
                                            </div>
                                            <div className="ex-item selected">
                                                &nbsp;
                                            </div>
                                            <div className="ex-item">
                                                &nbsp;
                                            </div>
                                            <div className="ex-item selected">
                                                &nbsp;
                                            </div>
                                            <div className="ex-item">
                                                &nbsp;
                                            </div>
                                        </div>
                                    }
                                />
                                <DataIntroducing31stflex showLinkCheck={true} />
                            </div>
                        </div>
                        <div className="clippy clippy-02"></div>
                    </div>
                </div>
                <div className="wrapper-home" id="31stshiny">
                    <div className="splash">
                        <div className="flex-wrap-col-center md:flex-wrap-row-between gap-6">
                            <div className="preview-31stshiny rounded-full rounded-tl-3xl rounded-br-3xl">
                                <div className="shiny shiny-sm shiny-lime shiny-tl">
                                    <div className="content">Shiny</div>
                                </div>
                                <div className="shiny shiny-sm shiny-slate">
                                    <div className="content">Shiny</div>
                                </div>
                                <div className="shiny shiny-sm shiny-orange shiny-tr">
                                    <div className="content">Shiny</div>
                                </div>
                            </div>
                            <div className="w-96">
                                <TitleColored
                                    sentence={'31st shiny'}
                                    className={undefined}
                                    suffixContent={undefined}
                                />
                                <DataIntroducing31stshiny />
                            </div>
                        </div>
                        <div className="clippy clippy-06"></div>
                    </div>
                </div>
                <div className="wrapper-home" id="about">
                    <div className="splash">
                        <About />
                        <div className="clippy clippy-05"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
