interface TagProps<ENTITY_KEY> {
    className?: string;
    content: JSX.Element | string;
    selectionSettings?: {
        selectable: boolean;
        selected: boolean;
        entityKey: ENTITY_KEY;
    };
    onClickHandler?: (key?: ENTITY_KEY) => void;
}

const Tag = <ENTITY_KEY,>({
    content,
    selectionSettings = undefined,
    className = undefined,
    onClickHandler = undefined,
}: TagProps<ENTITY_KEY>) => {
    const cssClasses = [
        'badge-base',
        'flex-0',
        'flex-wrap-row',
        'gap-1',
        'px-3',
        'py-1',
        'rounded',
        'text-sm',
        'transition-300',
        'w-fit',
    ];

    if (className) cssClasses.push(className);

    if (selectionSettings?.selectable) {
        cssClasses.push('cursor-pointer');
    }

    cssClasses.push(
        selectionSettings?.selected
            ? 'bg-white text-indigo-500 hover:text-rose-500'
            : 'bg-slate-600 text-white hover:bg-slate-500'
    );

    return (
        <div
            className={cssClasses.join(' ')}
            onClick={
                onClickHandler ? () => onClickHandler(selectionSettings?.entityKey) : undefined
            }
        >
            {content}
        </div>
    );
};

export default Tag;
