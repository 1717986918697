import { Button, Card, CardBody, Typography } from '@material-tailwind/react';
import { browserSessionPersistence, getAuth, setPersistence, signInWithPopup } from 'firebase/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as Logo31st } from '../../assets/images/logo-31st.svg';
import { googleProvider } from '../../lib/firebase';
import { STATUS, UiMessage } from '../../models/generic';
import AlertCustom from '../Common/Alert/AlertCustom';

// To do 
// https://www.youtube.com/watch?v=MNm1XhDjX1s

const BackShopSignIn = () => {
    const navigate = useNavigate();

    const [signinResult, setSigninResult] = useState<UiMessage | undefined>(undefined);

    // Auth. Google
    const onClickSignInWithGoogle = async () => {
        const auth = getAuth();
        setPersistence(auth, browserSessionPersistence);

        signInWithPopup(auth, googleProvider)
            .then((_userCredential) => {
                // dispatch(
                //     setCurrentUser({
                //         displayName: userCredential?.user?.displayName ?? null,
                //         email: userCredential?.user?.email ?? null,
                //         isLoggedIn: Boolean(userCredential?.user?.uid),
                //     })
                // );
                navigate('/backshop/home');
            })
            .catch((error) => {
                setSigninResult({
                    status: STATUS.FAILURE,
                    message: `${error.message} (${error.code})`,
                    date: new Date(),
                });
            });
    };

    return (
        <div className="flex-wrap-col-center flex-1">
            <Card className="relative">
                <HashLink to={'/'}>
                    <div className="flex-wrap-col-center absolute left-1/2 top-[-32px] h-24 w-24 -translate-x-1/2 transform rounded-full bg-white cursor-pointer hover:h-28 hover:w-28 transition-all">
                        <div className="flex-wrap-col-center h-20 w-20 rounded-full bg-gradient-to-b from-blue-600 to-blue-400 hover:h-24 hover:w-24 transition-all">
                            <Logo31st className="h-8 w-8" />
                        </div>
                    </div>
                </HashLink>
                <CardBody className="flex-wrap-col-center gap-6 pt-20">
                    <Typography variant="h6" className="m-0 p-0 uppercase text-neutral-600">
                        Sign In
                    </Typography>
                    {signinResult && (
                        <AlertCustom
                            className="my-3"
                            uiMessage={signinResult}
                            visible={Boolean(signinResult)}
                        />
                    )}
                    <Button fullWidth onClick={onClickSignInWithGoogle} color="blue">
                        Sign In with Google
                    </Button>
                </CardBody>
            </Card>
        </div>
    );
};

export default BackShopSignIn;
