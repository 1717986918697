import React from 'react';
import { useFetchAndDispatchBlogCategories } from '../../app/hooks/useFetchAndDispatchBlogCategories';

interface CodeArticleCategoriesProps {
    articleCategories: string[];
    articleCategoriesFilter?: Set<string>;
    theme?: 'base' | 'notePad';
}

// Affichage des catégories liées à un article
const CodeArticleCategories = ({
    articleCategories,
    articleCategoriesFilter = new Set(),
    theme = 'base',
}: CodeArticleCategoriesProps) => {
    // Blog categories
    const { blogCategories } = useFetchAndDispatchBlogCategories();

    return (
        <div className="flex-wrap-row gap-1">
            {articleCategories.map((categoryId, index) => {
                const theCat = blogCategories.find((category) => category.id === categoryId);

                const filteredCategory = articleCategoriesFilter.has(categoryId);

                let catClassName = `${filteredCategory ? `text-slate-700 italic` : `text-white`}`;
                if ('notePad' === theme) {
                    catClassName = `text-white text-sm bg-white/10 border border-white/25 border-dashed px-2 py-1 rounded-md`;
                }

                return (
                    <React.Fragment key={`article-compact-category-${categoryId}`}>
                        {index > 0 ? <i className="fa-solid fa-seedling"></i> : undefined}
                        <div className={catClassName}>{theCat?.label}</div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default CodeArticleCategories;
