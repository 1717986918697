import { combineReducers, configureStore } from '@reduxjs/toolkit';
import auth from '../features/auth/authSlice';
import blogCategory from '../features/blog/blogCategorySlice';
import { FETCH_STATUS } from '../models/generic';

const allReducers = combineReducers({ auth, blogCategory });

export const store = configureStore({
    reducer: allReducers,
    devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Statuts des data des différents "states"
export interface StateCurrentStatus {
    status: FETCH_STATUS;
}
