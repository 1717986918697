import { addDoc, collection, serverTimestamp } from '@firebase/firestore';
import { Button, Card, CardBody, Input, Typography } from '@material-tailwind/react';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFetchAndDispatchBlogCategories } from '../../../app/hooks/useFetchAndDispatchBlogCategories';
import { useUiMessage } from '../../../app/hooks/useUiMessage';
import { firestoreDb } from '../../../lib/firebase';
import { capitalize } from '../../../lib/functionsString';
import { BlogCategory } from '../../../models/backShop/blogCategory';
import { CategoryInput } from '../../../models/form';
import { UI_MESSAGE_LOG } from '../../../models/generic';
import AlertCustom from '../../Common/Alert/AlertCustom';
import FieldErrorMessage from '../../Common/Form/FieldErrorMessage';
import Loading from '../../Common/Loading/Loading';
import BackshopBlogCategory from './BackshopBlogCategory';

const formFieldsDefaultValues = {
    id: undefined,
    label: '',
};

const BackshopBlogCategories = () => {
    // Fetch & dispatch blog categories
    const {
        blogCategories,
        blogCategoriesIsLoading,
        blogCategoriesIsFailed,
        blogCategoriesFetchTrigger,
    } = useFetchAndDispatchBlogCategories();

    const { uiMessageData, setUiMessageSuccess, setUiMessageFailure, resetUiMessage } =
        useUiMessage();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
        clearErrors,
    } = useForm<CategoryInput>({
        defaultValues: {
            ...formFieldsDefaultValues,
        },
    });

    const onSubmitForm = async (data: CategoryInput) => {
        const timeStamp = serverTimestamp();
        resetUiMessage();

        // Check
        if (0 === data.label.trim().length) {
            setUiMessageFailure({
                message: 'Fill the label, please',
            });
            return;
        }

        if (data?.id) {
            try {
                const docRef = doc(firestoreDb, 'blog-category', data.id);

                await updateDoc(docRef, {
                    label: capitalize(data.label),
                    updatedAt: timeStamp,
                });

                setUiMessageSuccess({
                    message: 'Category updated successfully',
                });
            } catch (error) {
                setUiMessageFailure({
                    message: `An error occurred while updating category`,
                    log: {
                        type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                        data: error,
                    },
                });
            }
        } else {
            try {
                await addDoc(collection(firestoreDb, 'blog-category'), {
                    label: capitalize(data.label),
                    createdAt: timeStamp,
                    updatedAt: timeStamp,
                });

                setUiMessageSuccess({
                    message: 'Category created successfully',
                });
            } catch (error) {
                setUiMessageFailure({
                    message: `An error occurred while creating category`,
                    log: {
                        type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                        data: error,
                    },
                });
            }
        }

        blogCategoriesFetchTrigger();
        onClickReset();
    };

    // Reset form
    const onClickReset = () => {
        clearErrors();
        reset({ ...formFieldsDefaultValues });
    };

    // Modification d'une catégorie
    const onClickBlogCategoryEditionHandler = async (category: BlogCategory) => {
        onClickReset();
        resetUiMessage();

        // Hydratation du formulaire
        reset({
            id: category.id,
            label: category.label,
        });
    };

    // Suppression d'une catégorie
    const onClickBlogCategoryDeleteHandler = async (category: BlogCategory) => {
        clearErrors();

        if (!category.id) {
            setUiMessageFailure({
                message: 'Invalid category id, operation cancelled',
            });
            return;
        }

        try {
            const docRef = doc(firestoreDb, 'blog-category', category.id);
            await deleteDoc(docRef);

            blogCategoriesFetchTrigger();
            onClickReset();

            setUiMessageSuccess({
                message: 'Category deleted successfully',
            });
        } catch (error) {
            setUiMessageFailure({
                message: `An error occurred while deleting category`,
                log: {
                    type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                    data: error,
                },
            });
        }
    };

    // Message d'erreur si le fetch des cats a échoué
    useEffect(() => {
        if (!blogCategoriesIsFailed) return;

        setUiMessageFailure({
            message: 'Fetch category list failure',
            log: {
                type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                data: undefined,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogCategoriesIsFailed]);

    return (
        <div className="flex-1">
            <div className="grid grid-flow-row-dense gap-6 grid-cols-3">
                <div className="bs-title col-span-3">
                    <div className="icon-wrapper">
                        <i className="fa-solid fa-tag"></i>
                    </div>
                    Blog / Categories
                </div>
                <Card className="h-fit w-full">
                    <CardBody>
                        <Typography variant="h5" className="font-normal text-neutral-500">
                            Category form
                        </Typography>
                        {uiMessageData && (
                            <AlertCustom
                                className="mb-3"
                                uiMessage={uiMessageData}
                                visible={Boolean(uiMessageData)}
                            />
                        )}
                        <form
                            id="form-categories"
                            onSubmit={handleSubmit(onSubmitForm)}
                            className="flex flex-col flex-wrap gap-6"
                        >
                            <div>
                                <input type="hidden" {...register('id')} />
                                <Input
                                    crossOrigin={'anonymous'}
                                    size="lg"
                                    variant="outlined"
                                    label="Label"
                                    error={!!errors.label}
                                    {...register('label', {
                                        required: 'Fill the "label" please',
                                    })}
                                />
                                {errors.label && (
                                    <FieldErrorMessage
                                        message={errors?.label?.message?.toString() ?? ''}
                                    />
                                )}
                            </div>
                            <div className="flex-wrap-row-between gap-1">
                                <Button
                                    className="flex-1"
                                    type="submit"
                                    disabled={isSubmitting}
                                    color="blue"
                                >
                                    Submit
                                </Button>
                                <Button
                                    className="flex-1"
                                    type="reset"
                                    disabled={isSubmitting}
                                    color="red"
                                    onClick={onClickReset}
                                >
                                    Reset
                                </Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
                <Card className="h-fit w-full">
                    <CardBody>
                        <Typography variant="h5" className="font-normal text-neutral-500">
                            Category list
                        </Typography>
                        {blogCategoriesIsLoading ? (
                            <Loading />
                        ) : (
                            blogCategories.map((category) => {
                                return (
                                    <BackshopBlogCategory
                                        key={`blog-category-${category.id}`}
                                        category={category}
                                        onClickBlogCategoryEditionHandler={
                                            onClickBlogCategoryEditionHandler
                                        }
                                        onClickBlogCategoryDeleteHandler={
                                            onClickBlogCategoryDeleteHandler
                                        }
                                    />
                                );
                            })
                        )}
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default BackshopBlogCategories;
