import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Tag from './Tag';

interface SelectableTagsProps<ENTITY, ENTITY_KEY_TYPE> {
    entities: ENTITY[];
    // Pathes pour accéder au props ( cf: _.get() )
    pathes: {
        key: string;
        label: string;
    };
    className?: string;
    // Limite de sélection max
    maxSelected?: number;
    // "Selected keys" à l'init
    initSelectedKeys?: Set<ENTITY_KEY_TYPE>;
    // "Selected keys" pour le composant parent
    setSelectedKeys?: Dispatch<SetStateAction<Set<ENTITY_KEY_TYPE>>>;
}

const SelectableTags = <ENTITY, ENTITY_KEY_TYPE>({
    className = undefined,
    entities,
    pathes,
    maxSelected = undefined,
    initSelectedKeys = undefined,
    setSelectedKeys = undefined,
}: SelectableTagsProps<ENTITY, ENTITY_KEY_TYPE>) => {
    const [currentSelectedKeys, setCurrentSelectedKeys] = useState<
        Set<ENTITY_KEY_TYPE>
    >(initSelectedKeys ?? new Set());

    const onClickHandler = (entityKey?: ENTITY_KEY_TYPE) => {
        if (!entityKey) return;

        let currentSelectedKeysUpdated = new Set([...currentSelectedKeys]);
        if (currentSelectedKeys.has(entityKey)) {
            currentSelectedKeysUpdated.delete(entityKey);
        } else {
            // Nbre d'entrées limitées: Suppression de la dernière avant insertion
            if (
                maxSelected &&
                maxSelected > 0 &&
                maxSelected <= currentSelectedKeysUpdated.size
            ) {
                const currentSelectedKeysUpdatedArray = [
                    ...currentSelectedKeysUpdated,
                ].slice(1);
                currentSelectedKeysUpdated = new Set(
                    currentSelectedKeysUpdatedArray
                );
            }
            currentSelectedKeysUpdated.add(entityKey);
        }

        setCurrentSelectedKeys(currentSelectedKeysUpdated);
        // Pour le composant parent
        if (setSelectedKeys) {
            setSelectedKeys(currentSelectedKeysUpdated);
        }
    };

    useEffect(() => {
        if (initSelectedKeys && setSelectedKeys) {
            setCurrentSelectedKeys(initSelectedKeys);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initSelectedKeys]);

    return (
        <div className={`flex flex-wrap gap-1${className ? ` className` : ``}`}>
            {entities.map((entity) => {
                const key = _.get(entity, pathes.key);
                const label = _.get(entity, pathes.label);

                return (
                    <Tag
                        key={`tag-${key}`}
                        content={label}
                        selectionSettings={{
                            selectable: true,
                            selected: currentSelectedKeys.has(key),
                            entityKey: key,
                        }}
                        onClickHandler={onClickHandler}
                    />
                );
            })}
        </div>
    );
};

export default SelectableTags;
