import {
    DocumentData,
    Query,
    QueryConstraint,
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    where,
} from 'firebase/firestore';
import { firestoreDb } from '../../lib/firebase';
import { BlogArticle } from '../../models/backShop/blogArticle';
import { PaginationData } from '../../models/generic';

export const firebaseBlogArticle = async (
    paginationData: PaginationData,
    categoriesIds: string[] = []
) => {
    const theCollection = collection(firestoreDb, 'blog-article');

    // Query parameters
    const queryConstraints: QueryConstraint[] = [
        orderBy('createdAt', 'desc'),
        limit(paginationData.itemsPerPage),
    ];

    if (categoriesIds.length) {
        queryConstraints.push(where('categories', 'array-contains-any', categoriesIds));
    }

    if (paginationData.itemsCursors.get(paginationData.currentPage)) {
        queryConstraints.push(
            startAfter(paginationData.itemsCursors.get(paginationData.currentPage))
        );
    }

    // The query
    const theQuery: Query<DocumentData> = query(theCollection, ...queryConstraints);

    // The query snapshot
    const querySnapshot = await getDocs(theQuery);

    // The data
    const blogArticleList: BlogArticle[] = [];
    querySnapshot.forEach((doc) => {
        const article = {
            ...doc.data(),
            id: doc.id,
        } as BlogArticle;

        blogArticleList.push(article);
    });

    return {
        blogArticleList,
        querySnapshot,
    };
};
