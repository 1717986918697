import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import TitleColored from '../Common/TitleColored/TitleColored';
import Skill, { SkillEntry } from '../Skills/Skill';

const skills: SkillEntry[] = [
    {
        label: 'React',
        logoClass: 'logo-react',
    },

    {
        label: 'JavaScript',
        logoClass: 'logo-javascript',
    },
    {
        label: 'TypeScript',
        logoClass: 'logo-typescript',
    },
    {
        label: 'jQuery',
        logoClass: 'logo-jquery',
    },
    {
        label: 'HTML',
        logoClass: 'logo-html',
    },
    {
        label: 'CSS',
        logoClass: 'logo-css',
    },
    {
        label: 'Bootstrap',
        logoClass: 'logo-bootstrap',
    },
    {
        label: 'Tailwind CSS',
        logoClass: 'logo-tailwind-css',
    },
    {
        label: 'PHP',
        logoClass: 'logo-php',
    },
    {
        label: 'MySql/SQL',
        logoClass: 'logo-mysql',
    },
    {
        label: 'Git',
        logoClass: 'logo-git',
    },
    {
        label: 'Yarn',
        logoClass: 'logo-yarn',
    },
    {
        label: 'NPM',
        logoClass: 'logo-npm',
    },
    {
        label: 'Docker',
        logoClass: 'logo-docker',
    },
    {
        label: 'Wordpress',
        logoClass: 'logo-wordpress',
    },
    {
        label: 'Vscode',
        logoClass: 'logo-vscode',
    },
    {
        label: 'Sublime Text',
        logoClass: 'logo-sublime',
    },
    {
        label: 'Photoshop',
        logoClass: 'logo-photoshop',
    },
];

const About = () => {
    return (
        <div className="flex-wrap-col-center gap-8 w-96 sm:w-[60%]">
            <div className="grid w-full grid-cols-[auto_1fr]">
                <div className="title-number">
                    <i className="fa-solid fa-signature"></i>
                </div>
                <div className="pl-6">
                    <TitleColored sentence={'Guess what ?'} className={undefined} />
                    <p className="pb-3 text-justify">
                        ...I won't speak about me at the third person, as usual in the "about"
                        section ( so funny, after all ). My name is{' '}
                        <span className="italic">Grégory</span>, i live in France and i'm currently
                        front developper after doing back-end for a long time.
                    </p>
                    <p className="pb-3 text-justify">
                        The front side is cool in that it allows you to mix creativity and logic,
                        which is one of the first things that prompted me to do web work. Everything
                        also changes very quickly in this environment and this requires frequent
                        questioning of the technologies to be mastered and the way of approaching
                        this activity.
                    </p>
                    <p className="pb-3 text-justify">
                        Even if "JavaScript" and other front languages may sometimes not consider as
                        serious as they are by some old school dev...but this is another debate and
                        i don't really care, i know what i like and it's enought.
                    </p>
                    <p className="pb-3">
                        Otherwise, i eat only <span className="italic">vegetables</span>
                        <i className="fa-solid fa-seedling mx-1 text-lime-400"></i>
                        and no <span className="italic">meat</span> ( 99% of the time ), drink some{' '}
                        <span className="italic">water</span>
                        <i className="fa-solid fa-droplet mx-1 text-sky-300"></i> and coffee{' '}
                        <i className="fa-solid fa-mug-hot mx-1 text-yellow-600"></i>...and try to be{' '}
                        <span className="italic">kind</span> and{' '}
                        <span className="italic">caring</span>.
                    </p>
                    <p className="text-justify">
                        This page's colors are inspired by{' '}
                        <Link to="https://georgemoller.gumroad.com/l/slidely">Slidely</Link>
                    </p>
                </div>
            </div>
            <div className="grid w-full grid-cols-[1fr_auto]">
                <div className="pr-6">
                    <TitleColored sentence={'Why only vegetables ?'} className={'text-right'} />
                    <p className="pb-3 text-justify">
                        Because our amazing planet is tired of our always more, more, more...and the
                        future may <span className="italic">really sucks</span> if we don't make,
                        everyone of us, a <span className="italic">little effort</span> ( the
                        magical words, so scary ) to preserve ressources and wild life{' '}
                        <i className="fa-solid fa-fish mx-1 text-col02-yellow"></i>.
                    </p>
                    <p className="text-justify">
                        When i talk about future, i think first about my daughter's and others kids
                        who do not deserve to pay for our carelessness.
                    </p>
                </div>
                <div className="title-number">
                    <i className="fa-solid fa-seedling"></i>
                </div>
            </div>
            <div className="grid w-full grid-cols-[auto_1fr]">
                <div className="title-number">
                    <i className="fa-solid fa-kitchen-set"></i>
                </div>
                <div className="pl-6">
                    <TitleColored sentence={'Skills & tools'} className={undefined} />
                    <div className="grid w-full grid-col-1 sm:grid-cols-9 gap-3">
                        {skills.map((skill) => {
                            return <Skill key={uuid()} skill={skill} />;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
