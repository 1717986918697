import { Link } from 'react-router-dom';
import { ReactComponent as Logo31st } from '../../../assets/images/logo-31st.svg';

type DataIntroducing31stflexProps = {
    showLinkCheck?: boolean;
};

export const DataIntroducing31stflex = ({
    showLinkCheck = false,
}: DataIntroducing31stflexProps) => {
    return (
        <div>
            <p className="pb-3 text-justify">
                Yet another flexbox online tool to preview and test{' '}
                <span className="italic">Flexbox CSS rules</span>, just for the pleasure of
                launching a <span className="italic">React</span> project that can be useful on a
                daily basis.
            </p>
            <p className="pb-3 text-justify">
                I use this type of tool almost every day in my work and it saves me a lot of time !
            </p>
            <p className="pb-3 text-justify">
                Flexbox is everywhere ( <span className="italic">at least on this site</span> ) and
                is a really powerfull tool that let us remind how difficult it could sometimes be to
                manage a layout without it.
            </p>
            {showLinkCheck ? (
                <p>
                    <Link to="/31stflex/#">Check it out now !</Link>
                </p>
            ) : (
                <p className="flex flex-col items-center justify-center gap-3 py-6">
                    <Logo31st className="h-12 w-12" />
                    <Link to="/">Visit home page</Link>
                </p>
            )}
        </div>
    );
};

export const DataIntroducing31stshiny = () => {
    return (
        <div>
            <p className="pb-3 text-justify">
                How to easily create glowy designed element with a few lines of CSS. I originally
                made this to highlight data on a dashboard page and found it pretty cool and maybe
                usefull to style buttons or something else.
            </p>
            <p>
                Check out <Link to="/31stshiny/#shiny-gallery">the 31st's Shiny gallery</Link> and{' '}
                <Link to="/31stshiny/#shiny-editor">the 31st's Shiny editor</Link>
            </p>
        </div>
    );
};

export const DataIntroducing31stcode = () => {
    return (
        <div>
            <p className="pb-3 text-justify">
                A collection of usefull code snippets, best practices or interesting portions of
                code. Essentially about <span className="italic">React</span>,{' '}
                <span className="italic">TypeScript</span>,{' '}
                <span className="italic">Vanilla JS</span> and{' '}
                <span className="italic">front stuff</span>...
            </p>
            <p>
                <Link to="/31stcode">Let's go</Link>
            </p>
        </div>
    );
};
