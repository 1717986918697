import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useFetchAndDispatchBlogCategories } from '../app/hooks/useFetchAndDispatchBlogCategories';
import BackShop from './BackShop/BackShop';
import BackShopHome from './BackShop/BackShopHome';
import BackShopWhatsUp from './BackShop/BackShopWhatsUp';
import BackshopBlog from './BackShop/BackshopBlog';
import BackshopBlogCategories from './BackShop/BackshopBlog/BackshopBlogCategories';
import Flexbox from './Flexbox';
import Home from './Home';
import Shiny from './Shiny';
import Code from './Code';

const App = () => {
    // Fetch & dispatch blog categories
    const { blogCategoriesFetchTrigger } = useFetchAndDispatchBlogCategories();

    useEffect(() => {
        blogCategoriesFetchTrigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/31stflex" element={<Flexbox />} />
            <Route path="/31stshiny" element={<Shiny />} />
            <Route path="/31stcode/:article?" element={<Code />} />
            <Route
                path="/backshop/home"
                element={
                    <BackShop>
                        <BackShopHome />
                    </BackShop>
                }
            />
            <Route
                path="/backshop/news"
                element={
                    <BackShop>
                        <BackShopWhatsUp />
                    </BackShop>
                }
            />
            <Route
                path="/backshop/blog"
                element={
                    <BackShop>
                        <BackshopBlog />
                    </BackShop>
                }
            />
            <Route
                path="/backshop/blog/category"
                element={
                    <BackShop>
                        <BackshopBlogCategories />
                    </BackShop>
                }
            />
            <Route path="/backshop/*" element={<BackShop />} />
            <Route path="*" element={<Home />} />
        </Routes>
    );
};

export default App;
