interface ButtonIconProps {
    className?: string;
    iconClass: string;
    type?: 'base' | 'danger';
    disabled?: boolean;
    onClickHandler?: () => void;
}

const ButtonIcon = ({
    type = 'base',
    iconClass,
    className = undefined,
    disabled = false,
    onClickHandler = undefined,
}: ButtonIconProps) => {
    const cssClasses = [
        'flex-wrap-col-center',
        'transition-300',
        'h-8',
        'w-8',
        'rounded-full',
        'shadow',
        'text-gray-400',
    ];

    if (className) cssClasses.push(className);

    cssClasses.push(disabled ? 'bg-slate-100' : 'bg-white');

    if (!disabled) {
        if ('danger' === type) {
            cssClasses.push('hover:text-pink-500');
        } else {
            cssClasses.push('hover:text-blue-500');
        }
    }

    return (
        <button
            type="button"
            className={cssClasses.join(' ')}
            onClick={onClickHandler}
            disabled={disabled}
        >
            <i className={`fa-solid ${iconClass}`}></i>
        </button>
    );
};

export default ButtonIcon;
