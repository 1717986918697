import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FLEX_ITEMS_MAX_QUANTITY } from '../constants/constants';
import {
	getContainerStyle,
	getCssMap,
	getFlexOptionDefaultSetting,
	getItemStyle,
	itemSelectorTextFormat,
	settingsDefaultContainer,
	settingsDefaultItem,
	settingsDefaultSelector,
} from '../features/31stflex';
import { implodeOrUndefined } from '../lib/functionsArray';
import {
	CssMap,
	FlexOptionProperty,
	FlexOptionPropertyValueTypes,
	FlexOptionSetting,
	SettingsDefaultMap,
	SettingsMap,
} from '../models/31stflex';
import { DataIntroducing31stflex } from './31st/Data/Data';
import FlexFooterDev from './31stflex/FlexFooter/FlexFooterDev';
import FlexOption from './31stflex/FlexOption/FlexOption';
import FlexSectionTitle from './31stflex/FlexSectionTitle/FlexSectionTitle';
import FlexSectionTitleCssActions from './31stflex/FlexSectionTitle/FlexSectionTitleCssActions';
import FlexSectionTitleRenderActions from './31stflex/FlexSectionTitle/FlexSectionTitleRenderActions';
import SeoMetaData from './Common/Seo/SeoMetaData';
import './Flexbox.css';

const initialItemsNumbers = getFlexOptionDefaultSetting(
    FlexOptionProperty.ITEMS_SELECTOR
).options as number[];

// Code CSS: Génération de la preview
export const getCssMapForDisplay = (cssMap: CssMap) => {
    const entryIsComment = (entry: string) => {
        return entry.startsWith('//') || entry.startsWith('/*');
    };

    return Array.from(cssMap).map(([selector, props]) => {
        let evenOddIndex = -1;

        // Line de commentaire hors selecteur
        if (0 === props.length && entryIsComment(selector)) {
            return (
                <div key={selector} className="font-mono font-thin">
                    {selector}
                </div>
            );
        }

        return (
            <div key={selector} className="font-mono font-thin">
                <div>
                    <span className="css-selector">.{selector}</span> &#123;
                </div>
                {props.map(([property, value], index) => {
                    const isComment = entryIsComment(property);
                    evenOddIndex += isComment ? 2 : 1;

                    return (
                        <div key={`${property}-${index}`} className="pl-8">
                            {isComment ? (
                                <span className="css-comment">{property}</span>
                            ) : (
                                <>
                                    <span className="css-property">
                                        {_.kebabCase(property)}
                                    </span>
                                    :{' '}
                                    <span
                                        className={
                                            0 === evenOddIndex % 2
                                                ? 'css-value-even'
                                                : 'css-value-odd'
                                        }
                                    >
                                        {value}
                                    </span>
                                    ;
                                </>
                            )}
                        </div>
                    );
                })}
                <div>&#125;</div>
            </div>
        );
    });
};

// Code CSS: Génération du text "clipboard"
const getCssTextForClipboard = (cssMap: CssMap) => {
    return Array.from(cssMap)
        .reduce((lines, [selector, props]) => {
            lines.push(`.${selector} {`);
            lines.push(
                ...props.map(([property, value]) => `\t${property}: ${value};`)
            );
            lines.push(`}`);

            return lines;
        }, [] as string[])
        .join('\r\n');
};

// Duplique les settings par défaut pour chaque item
const getItemsSettingsDefault = (
    settingsDefaultItem: SettingsDefaultMap,
    itemNumbers: number[]
): SettingsMap => {
    return itemNumbers.reduce((itemsSettings, itemNumber) => {
        return itemsSettings.set(itemNumber, _.cloneDeep(settingsDefaultItem));
    }, new Map());
};

const Flexbox = () => {
    // State selector setting
    const [selectorSettings, setSelectorSettings] = useState(
        settingsDefaultSelector
    );

    // State container setting
    const [containerSettings, setContainerSettings] = useState(
        settingsDefaultContainer
    );

    // State items settings
    const [itemsSettings, setItemsSettings] = useState(
        getItemsSettingsDefault(settingsDefaultItem, initialItemsNumbers)
    );

    const [currentItemNumber, setCurrentItemNumber] = useState(0);

    const [css, setCss] = useState<JSX.Element[]>([]);

    const bottomRef = useRef(null);

    // Reset item selector
    const selectorOptionResetHandler = () => {
        setSelectorSettings(settingsDefaultSelector);
        setItemsSettings(
            getItemsSettingsDefault(settingsDefaultItem, initialItemsNumbers)
        );
        setCurrentItemNumber(0);
    };

    const fullResetHandler = () => {
        selectorOptionResetHandler();
        setContainerSettings(settingsDefaultContainer);
    };

    // Update de l'entrée "Setting" item selector
    const selectorOptionSelectionHandler = (
        itemNumber: FlexOptionPropertyValueTypes
    ) => {
        // Copie de la liste actuelle
        const settingClone = _.cloneDeep(selectorSettings);
        // Récupération de l'entrée "Setting" items selector
        const settingsEntry = settingClone.get(
            FlexOptionProperty.ITEMS_SELECTOR
        );
        if (!settingsEntry) return;

        // Modifications
        settingsEntry.setting.value = itemNumber;
        settingClone.set(FlexOptionProperty.ITEMS_SELECTOR, settingsEntry);

        // Update
        setCurrentItemNumber(itemNumber as number);
        setSelectorSettings(settingClone);
    };

    // Update d'une entrée "Setting" container
    const containerOptionSelectionHandler = (
        settingUpdated: FlexOptionSetting
    ) => {
        // Copie de la liste actuelle
        const settingClone = _.cloneDeep(containerSettings);
        // Récupération de l'entrée "Setting" ciblée
        const settingsEntry = settingClone.get(settingUpdated.property);
        if (!settingsEntry) return;

        // Modifications
        settingsEntry.setting = settingUpdated;
        settingClone.set(settingUpdated.property, settingsEntry);

        // Update
        setContainerSettings(settingClone);
    };

    // Update d'une entrée "Setting" item
    const itemOptionSelectionHandler = (settingUpdated: FlexOptionSetting) => {
        const itemsSettingsClone = _.cloneDeep(itemsSettings);

        const currentItemSetting = itemsSettingsClone.get(currentItemNumber);
        if (!currentItemSetting) return;

        const settingsEntry = currentItemSetting.get(settingUpdated.property);
        if (!settingsEntry) return;

        settingsEntry.setting = settingUpdated;

        // Current item == "all items": Répercussion sur tous les items
        if (0 === currentItemNumber) {
            itemsSettingsClone.forEach((itemSetting, key) => {
                if (0 === key) return;

                const settingsEntry = itemSetting.get(settingUpdated.property);
                if (!settingsEntry) return;

                settingsEntry.setting = _.cloneDeep(settingUpdated);
            });
        }

        setItemsSettings(itemsSettingsClone);
    };

    // Ajout d'un item
    const itemAddHandler = () => {
        if (itemsSettings.size - 1 >= FLEX_ITEMS_MAX_QUANTITY) return;

        // Settings de l'item
        const itemsSettingsClone = _.cloneDeep(itemsSettings);
        const itemNumber = Math.max(...itemsSettingsClone.keys()) + 1;
        setItemsSettings(
            itemsSettingsClone.set(
                itemNumber,
                _.cloneDeep(itemsSettingsClone.get(0) ?? settingsDefaultItem)
            )
        );

        // Mise à jour du sélecteur
        const settingClone = _.cloneDeep(selectorSettings);
        const settingsEntry = settingClone.get(
            FlexOptionProperty.ITEMS_SELECTOR
        );
        if (!settingsEntry) return;

        // Ajout de l'item
        (settingsEntry.setting.options as number[]).push(itemNumber);
        // Sélection de l'item
        settingsEntry.setting.value = itemNumber;
        settingClone.set(FlexOptionProperty.ITEMS_SELECTOR, settingsEntry);

        // Update
        setItemsSettings(itemsSettingsClone);
        setCurrentItemNumber(itemNumber);
        setSelectorSettings(settingClone);
    };

    // Suppression d'un item
    const itemRemoveHandler = (itemNumber: number) => {
        if (!itemsSettings.has(itemNumber)) return;

        // Settings de l'item
        const itemsSettingsClone = _.cloneDeep(itemsSettings);
        itemsSettingsClone.delete(itemNumber);

        // Mise à jour du sélecteur
        const settingClone = _.cloneDeep(selectorSettings);
        const settingsEntry = settingClone.get(
            FlexOptionProperty.ITEMS_SELECTOR
        );
        if (!settingsEntry) return;

        settingsEntry.setting.options = (
            settingsEntry.setting.options as number[]
        ).filter((itemNum) => itemNum !== itemNumber);

        // Le dernier item sera sélectionné
        const lastItemNumber = settingsEntry.setting.options.slice(-1)[0] ?? 0;
        settingsEntry.setting.value = lastItemNumber;
        settingClone.set(FlexOptionProperty.ITEMS_SELECTOR, settingsEntry);

        // Update
        setItemsSettings(itemsSettingsClone);
        setCurrentItemNumber(lastItemNumber);
        setSelectorSettings(settingClone);
    };

    const getCurrentItemSetting = (itemNumber: number): SettingsDefaultMap => {
        return itemsSettings.get(itemNumber) ?? settingsDefaultItem;
    };

    const onClickItemSelectHandler = (itemNumber: number) => {
        selectorOptionSelectionHandler(itemNumber);
    };

    const onClickItemRemoveHandler = (itemNumber: number) => {
        itemRemoveHandler(itemNumber);
    };

    useEffect(() => {
        setCss(
            getCssMapForDisplay(getCssMap(containerSettings, itemsSettings))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerSettings, itemsSettings]);

    useEffect(() => {
        // React router dom side effect :/
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SeoMetaData
                title={'31stflex, online CSS flexbox generator'}
                description={
                    'Try and play with each CSS flexbox properties to fit your needs as fast as possible and get clean generated CSS'
                }
                type={'website'}
            />
            <Link to="/">
                <div className="title-badge">31st</div>
            </Link>
            <div className="page-31stflex grid min-h-screen grid-cols-6 gap-4 bg-neutral-700 p-4">
                <div className="col-span-6">
                    <h1 className="title-main">
                        {'{ '}f l e x b o x{' }'}
                    </h1>
                    <span className="ml-3 text-neutral-400">
                        Online CSS flexbox generator
                    </span>
                </div>
                <div className="col-span-3 lg:col-span-1">
                    <FlexSectionTitle
                        title={'Flex container'}
                        subtitle={'Settings'}
                        iconName={'fa-expand'}
                    />
                    {Array.from(containerSettings).map(
                        ([_, setting], index) => {
                            return (
                                <FlexOption
                                    key={index}
                                    title={setting.title}
                                    setting={setting.setting}
                                    selectorOptionResetHandler={
                                        selectorOptionResetHandler
                                    }
                                    containerOptionSelectionHandler={
                                        containerOptionSelectionHandler
                                    }
                                    itemOptionSelectionHandler={
                                        itemOptionSelectionHandler
                                    }
                                    selectorOptionSelectionHandler={
                                        selectorOptionSelectionHandler
                                    }
                                />
                            );
                        }
                    )}
                </div>
                <div className="col-span-3 lg:col-span-1">
                    <FlexSectionTitle
                        title={'Flex items'}
                        subtitle={'Settings'}
                        iconName={'fa-th-large'}
                    />
                    {Array.from(selectorSettings).map(([_, setting], index) => {
                        return (
                            <FlexOption
                                key={`${setting.setting.property}-${index}`}
                                title={setting.title}
                                setting={setting.setting}
                                selectorOptionResetHandler={
                                    selectorOptionResetHandler
                                }
                                containerOptionSelectionHandler={
                                    containerOptionSelectionHandler
                                }
                                itemOptionSelectionHandler={
                                    itemOptionSelectionHandler
                                }
                                selectorOptionSelectionHandler={
                                    selectorOptionSelectionHandler
                                }
                            />
                        );
                    })}
                    {Array.from(getCurrentItemSetting(currentItemNumber)).map(
                        ([__, setting], index) => {
                            return (
                                <FlexOption
                                    key={`${setting.setting.property}-${index}`}
                                    title={setting.title}
                                    setting={setting.setting}
                                    selectorOptionResetHandler={
                                        selectorOptionResetHandler
                                    }
                                    containerOptionSelectionHandler={
                                        containerOptionSelectionHandler
                                    }
                                    itemOptionSelectionHandler={
                                        itemOptionSelectionHandler
                                    }
                                    selectorOptionSelectionHandler={
                                        selectorOptionSelectionHandler
                                    }
                                />
                            );
                        }
                    )}
                </div>
                <div className="flex-wrap-col col-span-6 lg:col-span-4">
                    <FlexSectionTitle
                        title={'Render'}
                        subtitle={'What you get'}
                        iconName={'fa-tv'}
                        titleContent={
                            <FlexSectionTitleRenderActions
                                itemAddHandler={itemAddHandler}
                                fullResetHandler={fullResetHandler}
                            />
                        }
                    />
                    <div className="preview">
                        <div
                            className="preview-wrapper"
                            style={{ ...getContainerStyle(containerSettings) }}
                        >
                            {itemsSettings.size &&
                                Array.from(itemsSettings)
                                    .filter(
                                        ([itemNumber, __]) => itemNumber > 0
                                    )
                                    .map(([itemNumber, itemSetting]) => {
                                        const cssClasses = ['preview-item'];
                                        if (
                                            0 === currentItemNumber ||
                                            itemNumber === currentItemNumber
                                        ) {
                                            cssClasses.push('selected');
                                        }

                                        return (
                                            <div
                                                key={String(itemNumber)}
                                                className={implodeOrUndefined(
                                                    cssClasses
                                                )}
                                                style={{
                                                    ...getItemStyle(
                                                        itemSetting
                                                    ),
                                                }}
                                            >
                                                <div className="number-wrapper">
                                                    <div className="number">
                                                        {itemSelectorTextFormat(
                                                            itemNumber
                                                        )}
                                                    </div>
                                                    <button
                                                        onClick={() =>
                                                            onClickItemSelectHandler(
                                                                itemNumber
                                                            )
                                                        }
                                                    >
                                                        <i className="fa-solid fa-check"></i>
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            onClickItemRemoveHandler(
                                                                itemNumber
                                                            )
                                                        }
                                                    >
                                                        <i className="fa-solid fa-xmark"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>
                </div>
                <div className="order-last col-span-3 lg:order-none lg:col-span-1">
                    <FlexSectionTitle
                        title={'What about this page ?'}
                        subtitle={'Informations'}
                        iconName={'fa-heart'}
                    />
                    <div className="wrapper-bordered">
                        <DataIntroducing31stflex />
                    </div>
                </div>
                <div className="order-last col-span-3 lg:order-none lg:col-span-1">
                    <FlexSectionTitle
                        title={'Making of'}
                        subtitle={'Back shop'}
                        iconName={'fa-terminal'}
                    />
                    <div className="wrapper-bordered">
                        <FlexFooterDev />
                    </div>
                </div>
                <div className="order-4 col-span-6 lg:col-span-4" id="csscode">
                    <FlexSectionTitle
                        title={'Can i see the CSS ?'}
                        subtitle={'Of course...'}
                        iconName={'fa-code'}
                        titleContent={
                            <FlexSectionTitleCssActions
                                getCssTextForClipboard={() =>
                                    getCssTextForClipboard(
                                        getCssMap(
                                            containerSettings,
                                            itemsSettings
                                        )
                                    )
                                }
                            />
                        }
                    />
                    <div className="wrapper-bordered">{css}</div>
                </div>
            </div>
            <div ref={bottomRef} />
        </>
    );
};

export default Flexbox;
