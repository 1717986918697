import { FunctionComponent } from 'react';

export type ConditionalWrapperProps = {
    // Wrap on/off
    condition: boolean;
    // Wrapper
    wrapper: FunctionComponent;
    // Composant(s) à wrapper
    children: React.ReactNode;
};

// Permet de "wrapper" un composant sous condition
export const ConditionalWrapper: FunctionComponent<ConditionalWrapperProps> = (props) => {
    const { condition, wrapper, children } = props;

    return <>{condition && children ? wrapper(children) : children}</>;
};

export default ConditionalWrapper;
