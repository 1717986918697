import _ from 'lodash';
import { implodeOrUndefined } from '../../../../lib/functionsArray';
import { Titles } from '../../../../models/31st';
import HtmlTag from '../HtmlTag/HtmlTag';

type TitleColoredProps = {
    // Phrase
    sentence: string;
    // CSS classes
    className?: string;
    // Contenu optionnel après la phrase
    suffixContent?: JSX.Element;
    // Tag HTML H1, H2...
    titleTag?: Titles;
    // Alterances de styles d'un mot sur deux
    textClasses?: [string, string];
};

// Génération des mots colorés
const getSentenceColored = (sentence: string, textClasses: [string, string]) => {
    const words = sentence.split(' ');

    return words.map((word, index: number) => {
        const ndx = (index + 1) % 2 > 0 ? 0 : 1;
        const textClass = textClasses[ndx];

        return (
            <span key={`word-${index}`} className={textClass}>
                {_.capitalize(word.toLocaleLowerCase())}
            </span>
        );
    });
};

// Génération d'un titre coloré: Alternance de style d'1/2 mots, conversion en CamelCase
const TitleColored = ({
    sentence,
    suffixContent,
    className,
    titleTag = Titles.H2,
    textClasses = ['text-col01-lighter', 'text-col02-yellow'],
}: TitleColoredProps) => {
    const cssClasses = ['flex-wrap-row-between w-full'];
    if (className) cssClasses.push(className);

    return (
        <HtmlTag tagname={titleTag} className={implodeOrUndefined(cssClasses)}>
            <div className="grow">{getSentenceColored(sentence, textClasses)}</div>

            {!!suffixContent && suffixContent}
        </HtmlTag>
    );
};

export default TitleColored;
