import { useEffect, useState } from 'react';
import { useFetchAndDispatchBlogCategories } from '../../app/hooks/useFetchAndDispatchBlogCategories';
import { getFirebaseCollectionCount } from '../../features/firebase/firebase';

interface Counters {
    news: number;
    blogCategories: number;
}

const BackShopHome = () => {
    // Blog categories
    const { blogCategoriesCount } = useFetchAndDispatchBlogCategories();

    const [counters, setCounters] = useState<Counters>({
        news: 0,
        blogCategories: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            let newsCount = counters.news;

            if (!newsCount) {
                newsCount = await getFirebaseCollectionCount('news');
            }

            setCounters({
                ...counters,
                news: newsCount,
                blogCategories: blogCategoriesCount,
            });
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogCategoriesCount]);

    return (
        <div className="flex-wrap-col-center gap-24 flex-1">
            <div className="border border-dashed rounded-md px-6 py-3 text-center leading-8">
                Hi there! Welcome to 31st's back office
                <br />
                Don't forget the <span className="italic">"Do something principle"</span> and have
                fun!
            </div>
            <div className="flex-wrap-row-center gap-24">
                <div className="rounded-full h-64 w-64 bg-white flex-wrap-col-center text-slate-700 outline outline-8 outline-white/10 relative">
                    <div className="rounded-full bg-white h-16 w-16 absolute left-2 top-2 flex-wrap-col-center outline outline-8 outline-white/10">
                        <i className="fa-solid fa-star text-blue-500"></i>
                    </div>
                    <h4 className="text-slate-600">Online news</h4>
                    <div className="text-6xl text-rose-400">{counters.news}</div>
                </div>
                <div className="rounded-full h-64 w-64 bg-white flex-wrap-col-center text-slate-700 outline outline-8 outline-white/10 relative">
                    <div className="rounded-full bg-white h-16 w-16 absolute left-2 top-2 flex-wrap-col-center outline outline-8 outline-white/10">
                        <i className="fa-solid fa-tag text-blue-500"></i>
                    </div>
                    <h4 className="text-slate-600">Blog's categories</h4>
                    <div className="text-6xl text-rose-400">{counters.blogCategories}</div>
                </div>
            </div>
        </div>
    );
};

export default BackShopHome;
