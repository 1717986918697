import uuid from 'react-uuid';

export type SkillEntry = {
    label: string;
    logoClass?: string;
};

type SkillProps = {
    skill: SkillEntry;
    className?: string;
};

const Skill = ({ skill, className }: SkillProps) => {
    const cssClasses = [
        'flex-wrap-col-center h-24 bg-col01-base gap-3 border border-dashed border-col01-light',
    ];
    if (className) cssClasses.push(className);

    return (
        <div key={uuid()} className={cssClasses.join(' ')}>
            <div className={skill.logoClass}>&nbsp;</div>
            <div className="text-xs uppercase text-col01-lighter">{skill.label}</div>
        </div>
    );
};

export default Skill;
