import { Dispatch } from 'react';
import uuid from 'react-uuid';

export enum ShinyTheme {
    NEUTRAL = 'shiny-neutral',
    SLATE = 'shiny-slate',
    LIME = 'shiny-lime',
    YELLOW = 'shiny-yellow',
    ORANGE = 'shiny-orange',
    RED = 'shiny-red',
    ROSE = 'shiny-rose',
    BLUE = 'shiny-blue',
    VIOLET = 'shiny-violet',
}

interface ShinyEditorThemeSelectorProps {
    currentTheme: ShinyTheme;
    setCurrentTheme: Dispatch<React.SetStateAction<ShinyTheme>>;
}

// Déclaration des classes border ( non dynamique sinon Tailwind ne les inclus pas )
export const cssBorderClasses = [
    'border-neutral-700',
    'border-slate-700',
    'border-lime-700',
    'border-yellow-700',
    'border-orange-700',
    'border-red-700',
    'border-rose-700',
    'border-blue-700',
    'border-violet-700',
];

const cssBaseClasses = [
    'bg-gradient-to-tl',
    'border-4',
    'cursor-pointer',
    'h-8',
    'shadow-none',
    'w-8',
];

const themeBordersClasses: Map<ShinyTheme, string> = Object.values(ShinyTheme).reduce(
    (borderClasses, theme) => {
        const themeClean = String(theme).replace('shiny-', '');

        return borderClasses.set(
            theme,
            cssBorderClasses.find((borderClass) => borderClass.includes(themeClean))
        );
    },
    new Map()
);

const ShinyEditorThemeSelector = ({
    currentTheme,
    setCurrentTheme,
}: ShinyEditorThemeSelectorProps) => {
    const getCssClasses = (theme: ShinyTheme) => {
        const isCurrent = currentTheme === theme;

        const borderClass = isCurrent ? themeBordersClasses.get(theme) : 'border-transparent';
        const cssClasses = [...cssBaseClasses, theme, borderClass];

        return cssClasses.join(' ');
    };

    return (
        <>
            {Array.from(themeBordersClasses.keys()).map((theme) => {
                return (
                    <div
                        key={uuid()}
                        onClick={() => setCurrentTheme(theme)}
                        className={getCssClasses(theme)}
                    >
                        &nbsp;
                    </div>
                );
            })}
        </>
    );
};

export default ShinyEditorThemeSelector;
