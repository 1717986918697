import { getAuth } from 'firebase/auth';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuthIsLoggedIn } from '../../features/auth/authSelector';
import { setCurrentUser } from '../../features/auth/authSlice';
import BackShopHeader from './BackShopHeader';
import BackShopNavigation from './BackShopNavigation';
import BackShopSignIn from './BackShopSignIn';

interface BackShopProps {
    children?: ReactNode;
}

const BackShop = ({ children = undefined }: BackShopProps) => {
    const dispatch = useAppDispatch();

    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();

    const [collapsed, setcollapsed] = useState(true);

    const onClickToggleCollapsed = () => {
        setcollapsed(!collapsed);
    };

    const authIsLoggedIn = useAppSelector(selectAuthIsLoggedIn);

    // Firebase init. & auth change
    useEffect(() => {
        // Init. auth
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _auth = auth;

        // Auth status
        const firebaseAuth = getAuth();

        // Auth listener
        firebaseAuth.onAuthStateChanged((user) => {
            dispatch(
                setCurrentUser({
                    displayName: user?.displayName ?? null,
                    email: user?.email ?? null,
                    isLoggedIn: !!user?.uid,
                })
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ('/backshop/logout' === location.pathname) {
            getAuth()
                .signOut()
                .then(() => {
                    // Clean up URL
                    navigate('/backshop');
                })
                .catch((error) => {
                    console.error(error?.code, error?.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <div
            className={`page-backshop  user-logged-${
                authIsLoggedIn ? `in` : `out`
            }`}
        >
            {authIsLoggedIn && Boolean(children) ? (
                <>
                    <BackShopHeader
                        collapsed={collapsed}
                        onClickToggleCollapsed={onClickToggleCollapsed}
                    />
                    <BackShopNavigation
                        collapsed={collapsed}
                        onClickToggleCollapsed={onClickToggleCollapsed}
                    />
                    <main className="flex flex-1 flex-wrap p-3 md:p-6">
                        {children}
                    </main>
                </>
            ) : (
                <BackShopSignIn />
            )}
        </div>
    );
};

export default BackShop;
