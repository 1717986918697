import { CSSProperties, MouseEventHandler } from 'react';
import { SliderStatus } from '../../31st/Common/Slider/Slider';
import { ShinyTheme } from './ShinyEditorThemeSelector';

export interface ShinyDummyParameters {
    borderRadiusTL: SliderStatus;
    borderRadiusTR: SliderStatus;
    borderRadiusBL: SliderStatus;
    borderRadiusBR: SliderStatus;
    widthAndHeight: SliderStatus;
    padding: SliderStatus;
}

interface ShinyDummyProps {
    className?: string;
    theme: ShinyTheme;
    parameters: ShinyDummyParameters;
    showText?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export const getShinyDummyStyle = (
    parameters: ShinyDummyParameters
): CSSProperties => {
    const {
        borderRadiusTL,
        borderRadiusTR,
        borderRadiusBR,
        borderRadiusBL,
        widthAndHeight,
        padding: pad,
    } = parameters;

    const radius = `${borderRadiusTL.value}% ${borderRadiusTR.value}% ${borderRadiusBR.value}% ${borderRadiusBL.value}%`;
    const baseSize = 256;
    const baseFontSize = 12;

    const sizeExact = (baseSize * widthAndHeight.value) / 100;
    const size = Math.floor(sizeExact);
    const padding = Math.floor(((sizeExact / 2) * pad.value) / 100);
    const fontSize = Math.floor((baseFontSize * (size - padding * 2)) / 100);

    return {
        borderRadius: `${radius} / ${radius}`,
        width: `${size}px`,
        height: `${size}px`,
        padding: `${padding}px`,
        fontSize: `${fontSize}px`,
    };
};

const ShinyDummy = ({
    theme,
    parameters,
    className = undefined,
    showText = true,
}: ShinyDummyProps) => {
    const style = getShinyDummyStyle(parameters);

    return (
        <div
            className={`shiny ${theme}${className ? ` ${className}` : ``}`}
            style={style}
        >
            <div
                className="content"
                style={{ borderRadius: style['borderRadius'] }}
            >
                {showText && theme.toUpperCase()}
            </div>
        </div>
    );
};

export default ShinyDummy;
