// import 'draft-js/dist/Draft.css';
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import {
	Dispatch,
	Ref,
	SetStateAction,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import ReactQuill from 'react-quill';
import Gist from 'super-react-gist';
import { regLineBreaks } from '../../../../lib/regExp';

interface RichTextEditorProps {
    setDisplayContent?: Dispatch<SetStateAction<string>>;
    initialContent?: string;
    warning?: boolean;
}

interface RichTextEditorDisplayProps {
    className?: string;
    content: string;
}

export interface RichTextEditorForwardedRef {
    resetContent: () => void;
    updateContent: (updatedContent: string) => void;
}

// https://gist.github.com/31stfr/0ae0d9b56467b75f854cba2d87c764cb
// https://gist.github.com/31stfr/89b674712eccc02ab7066c208214afb3

// Indique si le contenu "RichTextEditor" est alimenté ou non ( "<p><br></p>" = valeur par défaut, non supprimable )
export const isRichTextFilled = (text: string) => {
    return !!text && '<p><br></p>' !== text;
};

// Parser ( html-react-parser ): Permet de retourner un composant React en remplacement d'un bloc raw HTML
const parserOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
        if (
            domNode instanceof Element &&
            'pre' === domNode.name &&
            domNode.firstChild &&
            'text' === domNode.firstChild.type
        ) {
            try {
                const data = domNode.firstChild.data.trim();
                const url = new URL(data);
                if ('gist.github.com' === url.host) {
                    return url.pathname.length > 20 ? (
                        <Gist url={url.href} />
                    ) : (
                        <></>
                    );
                }
            } catch (_error) {}
        }
    },
};

// Affichage du contenu généré par le composant "RichTextEditor" incluant le parsing des code-block "gist"
export const RichTextEditorDisplay = ({
    content,
    className = undefined,
}: RichTextEditorDisplayProps) => {
    return (
        <div
            className={`rich-text-display py-6${
                className ? ` ${className}` : ``
            }`}
        >
            {parse(content, parserOptions)}
        </div>
    );
};

// Rich text editor basé sur Quill ( Pour les codes blocks, consulter le readme )
const RichTextEditor = forwardRef(
    (
        props: RichTextEditorProps,
        forwardedRef: Ref<RichTextEditorForwardedRef>
    ) => {
        const {
            initialContent = undefined,
            setDisplayContent = undefined,
            warning = false,
        } = props;
        const [content, setContent] = useState('');

        const contentPreviewParsing = (contentUpdated: string) => {
            let contentUpdatedWoLineBreaks = contentUpdated.replaceAll(
                regLineBreaks,
                ''
            );

            setContent(contentUpdated);
            // Pour le composant parent ( preview | affichage final )
            if (setDisplayContent) {
                setDisplayContent(contentUpdatedWoLineBreaks);
            }
        };

        const quillModules = {
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'blockquote',
                    'code-block',
                ],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
                ['clean'],
            ],
        };

        useImperativeHandle(forwardedRef, () => ({
            resetContent: () => setContent(''),
            updateContent: (updatedContent: string) =>
                setContent(updatedContent),
        }));

        useEffect(() => {
            if (undefined === initialContent) return;

            setContent(initialContent);
        }, [initialContent]);

        return (
            <div
                className={`rich-text-editor-wrapper bg-white flex-1 text-gray-800${
                    warning ? ` warning` : ``
                }`}
            >
                <ReactQuill
                    modules={quillModules}
                    onChange={contentPreviewParsing}
                    defaultValue={initialContent}
                    value={content}
                />
            </div>
        );
    }
);

export default RichTextEditor;
