import { CSSProperties, Dispatch, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import Slider, { SliderStatus } from '../../31st/Common/Slider/Slider';
import ShinyDummy, {
	ShinyDummyParameters,
	getShinyDummyStyle,
} from './ShinyDummy';
import ShinyEditorThemeSelector, {
	ShinyTheme,
} from './ShinyEditorThemeSelector';

interface ShinyEditorProps {
    className?: string;
    setShinyDummyStyleForCode: Dispatch<React.SetStateAction<CSSProperties>>;
    setShinyCurrentThemeForCode: Dispatch<React.SetStateAction<ShinyTheme>>;
}

let radiusInitialValue = 100;
const paddingInitialValue = 6;

const presetDefault: ShinyDummyParameters = {
    borderRadiusTL: {
        value: radiusInitialValue,
        isChanging: false,
    },
    borderRadiusTR: {
        value: radiusInitialValue,
        isChanging: false,
    },
    borderRadiusBR: {
        value: radiusInitialValue,
        isChanging: false,
    },
    borderRadiusBL: {
        value: radiusInitialValue,
        isChanging: false,
    },
    widthAndHeight: {
        value: radiusInitialValue,
        isChanging: false,
    },
    padding: {
        value: paddingInitialValue,
        isChanging: false,
    },
};

const presetDefaultXs = {
    ...presetDefault,
    widthAndHeight: {
        value: 30,
    },
};
const presets: ShinyDummyParameters[] = [
    {
        ...presetDefaultXs,
        borderRadiusTL: {
            value: 20,
        },
    },
    {
        ...presetDefaultXs,
        borderRadiusBR: {
            value: 70,
        },
        borderRadiusBL: {
            value: 20,
        },
    },
    {
        ...presetDefaultXs,
        borderRadiusTL: {
            value: 40,
        },
        borderRadiusTR: {
            value: 60,
        },
        borderRadiusBL: {
            value: 36,
        },
        padding: {
            value: 40,
        },
    },
    {
        ...presetDefaultXs,
        borderRadiusTR: {
            value: 90,
        },
        borderRadiusBR: {
            value: 36,
        },
        padding: {
            value: 100,
        },
    },
    {
        ...presetDefaultXs,
        borderRadiusBR: {
            value: 23,
        },
        borderRadiusTL: {
            value: 23,
        },
    },
    {
        ...presetDefaultXs,
        borderRadiusTR: {
            value: 23,
        },
        borderRadiusBR: {
            value: 47,
        },
        borderRadiusBL: {
            value: 23,
        },
        padding: {
            value: 46,
        },
    },
    {
        ...presetDefaultXs,
        borderRadiusBR: {
            value: 0,
        },
        borderRadiusBL: {
            value: 0,
        },
        padding: {
            value: 20,
        },
    },
    {
        ...presetDefaultXs,
        borderRadiusBR: {
            value: 10,
        },
        borderRadiusBL: {
            value: 10,
        },
        borderRadiusTL: {
            value: 10,
        },
        borderRadiusTR: {
            value: 10,
        },
        padding: {
            value: 40,
        },
    },
    {
        ...presetDefaultXs,
        borderRadiusBL: {
            value: 50,
        },
        borderRadiusTL: {
            value: 50,
        },
        borderRadiusTR: {
            value: 50,
        },
        padding: {
            value: 100,
        },
    },
];

const ShinyEditor = ({
    className = undefined,
    setShinyDummyStyleForCode,
    setShinyCurrentThemeForCode,
}: ShinyEditorProps) => {
    // Thème
    const [currentTheme, setCurrentTheme] = useState<ShinyTheme>(
        ShinyTheme.SLATE
    );

    // Borders radius
    const [sliderStatusTL, setSliderStatusTL] = useState<SliderStatus>({
        value: radiusInitialValue,
        isChanging: false,
    });
    const [sliderStatusTR, setSliderStatusTR] = useState<SliderStatus>({
        value: radiusInitialValue,
        isChanging: false,
    });
    const [sliderStatusBL, setSliderStatusBL] = useState<SliderStatus>({
        value: radiusInitialValue,
        isChanging: false,
    });
    const [sliderStatusBR, setSliderStatusBR] = useState<SliderStatus>({
        value: radiusInitialValue,
        isChanging: false,
    });

    // Width & Height
    const [sliderStatusOuterW, setSliderStatusOuterW] = useState<SliderStatus>({
        value: radiusInitialValue,
        isChanging: false,
    });

    // Padding
    const [sliderStatusPadding, setSliderStatusPadding] =
        useState<SliderStatus>({
            value: paddingInitialValue,
            isChanging: false,
        });

    const [currentPreset, setCurrentPreset] =
        useState<ShinyDummyParameters>(presetDefault);

    const onClickPreset = (preset: ShinyDummyParameters) => {
        setSliderStatusTL(preset.borderRadiusTL);
        setSliderStatusTR(preset.borderRadiusTR);
        setSliderStatusBL(preset.borderRadiusBL);
        setSliderStatusBR(preset.borderRadiusBR);
        setSliderStatusOuterW({
            value: 100,
        });
        setSliderStatusPadding(preset.padding);

        setCurrentPreset({ ...preset, widthAndHeight: { value: 100 } });
    };

    const getDummyParameters = () => {
        return {
            borderRadiusTL: sliderStatusTL,
            borderRadiusTR: sliderStatusTR,
            borderRadiusBL: sliderStatusBL,
            borderRadiusBR: sliderStatusBR,
            widthAndHeight: sliderStatusOuterW,
            padding: sliderStatusPadding,
        };
    };

    useEffect(() => {
        setShinyDummyStyleForCode(getShinyDummyStyle(getDummyParameters()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sliderStatusTL,
        sliderStatusTR,
        sliderStatusBL,
        sliderStatusBR,
        sliderStatusOuterW,
        sliderStatusPadding,
    ]);

    useEffect(() => {
        setShinyCurrentThemeForCode(currentTheme);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTheme]);

    return (
        <div
            className={`shiny-editor flex-wrap-row grow justify-around gap-6${
                className ? className : ``
            }`}
        >
            <div className="flex-wrap-col-center gap-16">
                <div className="flex-wrap-col-center gap-4">
                    <h4 className="p-0 text-justify uppercase text-slate-600">
                        Control sizes
                    </h4>
                    <h6 className="p-0 text-justify text-slate-500">
                        External size
                    </h6>
                    <Slider
                        initialValue={currentPreset.widthAndHeight.value}
                        setExternalStatusState={setSliderStatusOuterW}
                        className="w-40 px-6"
                    />
                    <h6 className="p-0 text-justify text-slate-500">
                        Internal size ( External size - padding )
                    </h6>
                    <Slider
                        initialValue={currentPreset.padding.value}
                        setExternalStatusState={setSliderStatusPadding}
                        className="w-40 px-6"
                    />
                </div>

                <div className="flex-wrap-col-center gap-4">
                    <h4 className="p-0 text-justify uppercase text-slate-600">
                        Control external border radius
                    </h4>
                    <div className="grid w-fit grid-cols-2 grid-rows-2 gap-[2px]">
                        <div className="flex-wrap-col items-end gap-6">
                            <Slider
                                initialValue={
                                    currentPreset.borderRadiusTL.value
                                }
                                setExternalStatusState={setSliderStatusTL}
                                className="w-40 px-6"
                            />
                            <div
                                className={`square-corner tl${
                                    sliderStatusTL.isChanging ? ` active` : ``
                                }`}
                            >
                                <i className="fa-solid fa-up-long -rotate-45"></i>
                            </div>
                        </div>
                        <div className="flex-wrap-col items-start gap-6">
                            <Slider
                                initialValue={
                                    currentPreset.borderRadiusTR.value
                                }
                                setExternalStatusState={setSliderStatusTR}
                                className="w-40 px-6"
                            />
                            <div
                                className={`square-corner tr${
                                    sliderStatusTR.isChanging ? ` active` : ``
                                }`}
                            >
                                <i className="fa-solid fa-up-long rotate-45"></i>
                            </div>
                        </div>
                        <div className="flex-wrap-col items-end gap-6">
                            <div
                                className={`square-corner bl${
                                    sliderStatusBL.isChanging ? ` active` : ``
                                }`}
                            >
                                <i className="fa-solid fa-up-long -rotate-[135deg]"></i>
                            </div>
                            <Slider
                                initialValue={
                                    currentPreset.borderRadiusBL.value
                                }
                                setExternalStatusState={setSliderStatusBL}
                                className="w-40 px-6"
                                badgePositionY="bottom"
                            />
                        </div>
                        <div className="flex-wrap-col items-start gap-6">
                            <div
                                className={`square-corner br${
                                    sliderStatusBR.isChanging ? ` active` : ``
                                }`}
                            >
                                <i className="fa-solid fa-up-long rotate-[135deg]"></i>
                            </div>
                            <Slider
                                initialValue={
                                    currentPreset.borderRadiusBR.value
                                }
                                setExternalStatusState={setSliderStatusBR}
                                className="w-40 px-6"
                                badgePositionY="bottom"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-wrap-col-center">
                <div className="shiny-playground flex-wrap-col-center h-96 w-96 border-[4px] border-dashed border-slate-200">
                    <ShinyDummy
                        theme={currentTheme}
                        parameters={{
                            ...getDummyParameters(),
                        }}
                    />
                </div>
            </div>
            <div className="flex-wrap-col-center">
                <h4 className="pb-4 text-justify uppercase text-slate-600">
                    Colorize
                </h4>
                <div className="pb-4 text-sm text-neutral-500">
                    All colors comes from{' '}
                    <Link
                        to={'https://tailwindcss.com/docs/customizing-colors'}
                    >
                        Tailwind default color palette
                    </Link>
                </div>
                <div className="grid w-fit grid-cols-3 gap-4 pb-4">
                    <ShinyEditorThemeSelector
                        setCurrentTheme={setCurrentTheme}
                        currentTheme={currentTheme}
                    />
                </div>
                <h4 className="py-4 text-justify uppercase text-slate-600">
                    Presets
                </h4>
                <div className="grid w-fit grid-cols-3 gap-6 pb-4">
                    {presets.map((preset) => {
                        return (
                            <div
                                key={uuid()}
                                className="transition-300 border-4 border-dashed border-slate-200 p-3 hover:border-slate-300"
                                onClick={() => onClickPreset(preset)}
                            >
                                <ShinyDummy
                                    className="cursor-pointer"
                                    theme={currentTheme}
                                    parameters={preset}
                                    showText={false}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ShinyEditor;
