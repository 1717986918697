import { ThemeProvider } from '@material-tailwind/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './app/store';
import './index.css';
import App from './modules/App';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';

ReactGA.initialize('G-TQ5W4GN2QW');

// If you are using react-helmet-async on server side
const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider context={helmetContext}>
                <BrowserRouter>
                    <ThemeProvider>
                        <App />
                    </ThemeProvider>
                </BrowserRouter>
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);
