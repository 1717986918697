import { Button, Card, CardBody, Input, Typography } from '@material-tailwind/react';
import { addDoc, collection, deleteDoc, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useBlogArticle } from '../../app/hooks/useBlogArticle';
import { useFetchAndDispatchBlogCategories } from '../../app/hooks/useFetchAndDispatchBlogCategories';
import { useUiMessage } from '../../app/hooks/useUiMessage';
import { BLOG_ARTICLE_TYPES } from '../../constants/constants';
import { firestoreDb } from '../../lib/firebase';
import { capitalize } from '../../lib/functionsString';
import { UnpackArray } from '../../lib/typeHelpers';
import { BlogArticle, BlogArticleTypesKeys } from '../../models/backShop/blogArticle';
import { BlogCategory } from '../../models/backShop/blogCategory';
import { ArticleInput } from '../../models/form';
import { PaginationData, UI_MESSAGE_LOG } from '../../models/generic';
import RichTextEditor, {
    RichTextEditorDisplay,
    RichTextEditorForwardedRef,
    isRichTextFilled,
} from '../31st/Common/RichTextEditor/RichTextEditor';
import SelectableTags from '../31st/Common/Tag/SelectableTags';
import AlertCustom from '../Common/Alert/AlertCustom';
import FieldErrorMessage from '../Common/Form/FieldErrorMessage';
import Loading from '../Common/Loading/Loading';
import Pagination from '../Common/Pagination/Pagination';
import BackshopBlogArticle from './BackshopBlog/BackshopBlogArticle';

const formFieldsDefaultValues: ArticleInput = {
    id: '',
    title: '',
    content: '',
	seoDescription: '',
    type: '',
    categories: [],
};

const BackshopBlog = () => {
    const richTextEditorRef = useRef<RichTextEditorForwardedRef>(null);

    const [richTextPreview, setRichTextPreview] = useState('');

    // Blog categories
    const { blogCategories, blogCategoriesIsLoading, blogCategoriesIsFailed } =
        useFetchAndDispatchBlogCategories();

    const [selectedCategoryIds, setSelectedCategoryIds] = useState<Set<string>>(new Set());

    const [selectedType, setSelectedType] = useState<Set<BlogArticleTypesKeys>>(new Set());

    const { uiMessageData, setUiMessageSuccess, setUiMessageFailure, resetUiMessage } =
        useUiMessage();

    const {
        articles,
        articlesCount,
        loading,
        pagination,
        setCurrentPagination,
        triggerArticlesFetch,
    } = useBlogArticle(5);

    const setPaginationDataFromPaginationComponent = (
        paginationPartial: Partial<PaginationData>
    ): void => {
        setCurrentPagination({
            ...pagination,
            ...paginationPartial,
        });
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, isSubmitting },
        watch,
        clearErrors,
    } = useForm<ArticleInput>({
        mode: 'onChange',
        defaultValues: {
            ...formFieldsDefaultValues,
        },
    });

    const onSubmitForm = async (data: ArticleInput) => {
        const timeStamp = serverTimestamp();
        resetUiMessage();

        if (data?.id) {
            try {
                const docRef = doc(firestoreDb, 'blog-article', data.id);

                await updateDoc(docRef, {
                    title: capitalize(data.title),
                    content: data.content,
                    categories: data.categories,
					seoDescription: data.seoDescription,
                    type: data.type,
                    updatedAt: timeStamp,
                });
				
                setUiMessageSuccess({
					message: 'Article updated successfully',
                });
            } catch (error) {
                setUiMessageFailure({
                    message: `An error occurred while updating article`,
                    log: {
						type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                        data: error,
                    },
                });
            }
        } else {
			try {
				await addDoc(collection(firestoreDb, 'blog-article'), {
					title: capitalize(data.title),
                    content: data.content,
                    categories: data.categories,
					seoDescription: data.seoDescription,
                    type: data.type,
                    createdAt: timeStamp,
                    updatedAt: timeStamp,
                });

                setUiMessageSuccess({
                    message: 'Article created successfully',
                });
            } catch (error) {
                setUiMessageFailure({
                    message: `An error occurred while creating article`,
                    log: {
                        type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                        data: error,
                    },
                });
            }
        }

        onClickReset();
        triggerArticlesFetch();
    };

    // Reset
    const onClickReset = () => {
        // Rich text editor
        setRichTextPreview('');
        richTextEditorRef?.current?.resetContent();

        // Categories
        setSelectedCategoryIds(new Set());

        // Type
        setSelectedType(new Set());

        // Form
        reset({ ...formFieldsDefaultValues });

        // Reset des erreurs différé car le reset des catégories et du type vont déclencher la vérification des champs correspondants
        setTimeout(() => {
            clearErrors();
        }, 100);
    };

    const onClickArticleEditionHandler = (article: BlogArticle) => {
        onClickReset();
        resetUiMessage();

        // Hydratation du formulaire
        reset({
            id: article.id,
            title: article.title,
            content: article.content,
			seoDescription: article.seoDescription,
            type: article.type,
            categories: [],
        });
        setRichTextPreview(article.content);
        richTextEditorRef?.current?.updateContent(article.content);

        // Type
        setSelectedType(new Set([article.type]));
        // Catégories
        setSelectedCategoryIds(new Set(article.categories));
    };

    const onClickArticleDeleteHandler = async (article: BlogArticle) => {
        resetUiMessage();

        if (!article.id) {
            setUiMessageFailure({
                message: 'Invalid article id, operation cancelled',
            });
            return;
        }

        try {
            const docRef = doc(firestoreDb, 'blog-article', article.id);
            await deleteDoc(docRef);

            onClickReset();
            triggerArticlesFetch();

            setUiMessageSuccess({
                message: 'Article deleted successfully',
            });
        } catch (error) {
            setUiMessageFailure({
                message: `An error occurred while deleting article`,
                log: {
                    type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                    data: error,
                },
            });
        }
    };

    useEffect(() => {
        const content = isRichTextFilled(richTextPreview) ? richTextPreview : '';

        setValue('content', content.trim());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [richTextPreview]);

    // Message d'erreur si le fetch des cats a échoué
    useEffect(() => {
        if (!blogCategoriesIsFailed) return;

        setUiMessageFailure({
            message: 'Fetch category list failure',
            log: {
                type: UI_MESSAGE_LOG.ERROR_FIREBASE_FIRESTORE,
                data: undefined,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogCategoriesIsFailed]);

    // Alimente le "fake categories hidden field" en fonction du nombre de catégories sélectionnées
    useEffect(() => {
        setValue(
            'categories',
            selectedCategoryIds.size > 0 ? Array.from(selectedCategoryIds) : [],
            { shouldValidate: true }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategoryIds]);

    // Alimente le "fake type hidden field" en fonction de la sélection du type
    useEffect(() => {
        setValue('type', selectedType.size > 0 ? [...selectedType][0] : '', {
            shouldValidate: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType]);

    useEffect(() => {
        // Reset des erreurs différé car le reset des catégories et du type vont déclencher la vérification des champs correspondants
        setTimeout(() => {
            clearErrors();
        }, 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex-1">
            <div className="grid grid-flow-row-dense grid-cols-1 gap-6 md:grid-cols-3">
                <div className="bs-title md:col-span-3">
                    <div className="icon-wrapper">
                        <i className="fa-solid fa-pepper-hot"></i>
                    </div>
                    Blog / Article
                    <span className="ml-auto mr-3">{`${articlesCount} article${
                        articlesCount > 1 ? `s` : ``
                    }`}</span>
                </div>
                <Card className="h-fit w-full">
                    <CardBody>
                        <Typography variant="h5" className="font-normal text-neutral-500">
                            Article form
                        </Typography>
                        {uiMessageData && (
                            <AlertCustom
                                className="mb-3"
                                uiMessage={uiMessageData}
                                visible={Boolean(uiMessageData)}
                            />
                        )}
                        <form
                            id="form-blog-article"
                            onSubmit={handleSubmit(onSubmitForm)}
                            className="flex flex-col flex-wrap gap-6"
                        >
                            <div>
                                <input type="hidden" {...register('id')} />
                                <Input
                                    crossOrigin={'anonymous'}
                                    size="lg"
                                    variant="outlined"
                                    label="Title"
                                    error={!!errors.title}
                                    {...register('title', {
                                        required: 'Fill the "title" please',
                                    })}
                                />
                                {errors.title && (
                                    <FieldErrorMessage
                                        message={errors?.title?.message?.toString() ?? ''}
                                    />
                                )}
                            </div>
                            <div>
                                <RichTextEditor
                                    ref={richTextEditorRef}
                                    initialContent={''}
                                    setDisplayContent={setRichTextPreview}
                                    warning={!!errors?.content?.message}
                                />
                                <input
                                    type="hidden"
                                    {...register('content', {
                                        required: 'Fill the "content" please',
                                    })}
                                />
                                {errors.content && (
                                    <FieldErrorMessage
                                        message={errors?.content?.message?.toString() ?? ''}
                                    />
                                )}
                            </div>
							<div>
                                <Input
                                    crossOrigin={'anonymous'}
                                    size="lg"
                                    variant="outlined"
                                    label="SEO description"
                                    error={!!errors.seoDescription}
                                    {...register('seoDescription', {
                                        required: 'Fill the "SEO description" please',
                                    })}
                                />
                                {errors.seoDescription && (
                                    <FieldErrorMessage
                                        message={errors?.seoDescription?.message?.toString() ?? ''}
                                    />
                                )}
                            </div>
                            <div>
                                <Typography
                                    variant="h6"
                                    className="font-normal text-neutral-500 pb-3"
                                >
                                    Type
                                </Typography>
                                <SelectableTags<
                                    UnpackArray<typeof BLOG_ARTICLE_TYPES>,
                                    BlogArticleTypesKeys
                                >
                                    entities={BLOG_ARTICLE_TYPES}
                                    initSelectedKeys={selectedType}
                                    setSelectedKeys={setSelectedType}
                                    maxSelected={1}
                                    pathes={{
                                        key: 'key',
                                        label: 'value',
                                    }}
                                />
                                {errors.categories && (
                                    <FieldErrorMessage
                                        message={errors?.type?.message?.toString() ?? ''}
                                    />
                                )}
                                <input
                                    type="hidden"
                                    {...register('type', {
                                        required: 'Select a type please',
                                    })}
                                />
                            </div>
                            <div>
                                <Typography
                                    variant="h6"
                                    className="font-normal text-neutral-500 pb-3"
                                >
                                    Categories
                                </Typography>
                                <input
                                    type="hidden"
                                    {...register('categories', {
                                        required: 'Select at least one category please',
                                    })}
                                />

                                {blogCategoriesIsLoading ? (
                                    <Loading />
                                ) : (
                                    <SelectableTags<BlogCategory, string>
                                        entities={blogCategories}
                                        initSelectedKeys={selectedCategoryIds}
                                        setSelectedKeys={setSelectedCategoryIds}
                                        pathes={{
                                            key: 'id',
                                            label: 'label',
                                        }}
                                    />
                                )}
                                {errors.categories && (
                                    <FieldErrorMessage
                                        message={errors?.categories?.message?.toString() ?? ''}
                                    />
                                )}
                            </div>
                            <div className="flex-wrap-row-between gap-1">
                                <Button
                                    className="flex-1"
                                    type="submit"
                                    disabled={isSubmitting}
                                    color="blue"
                                >
                                    Submit
                                </Button>
                                <Button
                                    className="flex-1"
                                    type="reset"
                                    disabled={isSubmitting}
                                    color="red"
                                    onClick={onClickReset}
                                >
                                    Reset
                                </Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Typography variant="h5" className="font-normal text-neutral-500">
                            Article preview
                        </Typography>
                        <Typography variant="h6" className="font-normal text-neutral-500">
                            {watch('title') || (
                                <span className="text-gray-400 italic">Currently no title</span>
                            )}
                        </Typography>
                        <div>
                            {isRichTextFilled(richTextPreview) ? (
                                <RichTextEditorDisplay content={richTextPreview} />
                            ) : (
                                <span className="text-gray-400 italic">Currently no content</span>
                            )}
                        </div>
                    </CardBody>
                </Card>
                <Card className="min-h-full">
                    <CardBody className="flex-1 divide-y divide-dashed divide-gray-300">
                        <Pagination
                            setCurrentPagination={setPaginationDataFromPaginationComponent}
                            currentPagination={pagination}
                            showInfos={true}
                        />
                        {loading ? (
                            <Loading />
                        ) : articles.length > 0 ? (
                            articles.map((article) => {
                                return (
                                    <BackshopBlogArticle
                                        key={`article-${article.id}`}
                                        article={article}
                                        className="py-7"
                                        onClickArticleEditionHandler={onClickArticleEditionHandler}
                                        onClickArticleDeleteHandler={onClickArticleDeleteHandler}
                                    />
                                );
                            })
                        ) : (
                            <div className="text-gray-400 italic text-center pt-8">
                                No article available
                            </div>
                        )}
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default BackshopBlog;
