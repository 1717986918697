
type FlexSectionTitleCssActionsProps = {
    getCssTextForClipboard: () => string;
};

// Actions disponible dans la barre de titre "css"
const FlexSectionTitleCssActions = ({
    getCssTextForClipboard,
}: FlexSectionTitleCssActionsProps) => {
    if (!('clipboard' in navigator)) {
        return <></>;
    }

    const onclickCopyToClipboardHandler = () => {
        navigator.clipboard.writeText(getCssTextForClipboard());
    };

    return (
        <div className="row flex gap-px">
            <button
                className="button action-copy-to-clipboard rounded-md"
                onClick={onclickCopyToClipboardHandler}
            >
                <i className="fa-solid fa-clone"></i>Copy CSS to clipboard
            </button>
        </div>
    );
};

export default FlexSectionTitleCssActions;
