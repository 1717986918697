// Integer >= 0
export const regInteger = /^[0-9]+$/;

// Integer <=> 0
export const regIntegerNeg = /^-?[0-9]+$/;

// Flex-basis: >= 0 | auto | content
export const regFlexBasis = /^[0-9]|auto|content+$/;

// Line breaks
export const regLineBreaks = /(\r\n|\r|\n)/g;
