type FlexSectionTitleProps = {
    title: string;
    subtitle: string;
    iconName: string;
    titleContent?: JSX.Element;
};

const FlexSectionTitle = ({
    title,
    subtitle,
    iconName,
    titleContent = undefined,
}: FlexSectionTitleProps) => {
    return (
        <div className="section-title">
            <div className="title-square">
                <i className={`fa-solid ${iconName}`}></i>
            </div>
            {titleContent}
            <div>
                <div>{title}</div>
                <div>{subtitle}</div>
            </div>
        </div>
    );
};

export default FlexSectionTitle;
