import { useState } from 'react';
import { ReactComponent as LogoFirefox } from '../../../assets/images/logo-firefox.svg';
import { ReactComponent as LogoJavascript } from '../../../assets/images/logo-javascript.svg';
import { ReactComponent as LogoReact } from '../../../assets/images/logo-react.svg';
import { ReactComponent as LogoTailwind } from '../../../assets/images/logo-tailwind-css.svg';
import { ReactComponent as LogoTypescript } from '../../../assets/images/logo-typescript.svg';
import { ReactComponent as LogoVscode } from '../../../assets/images/logo-vscode.svg';

enum Icon {
    FIREFOX = 'firefox',
    GITHUB = 'github',
    JAVASCRIPT = 'javascript',
    REACT = 'react',
    TAILWIND = 'tailwind',
    TYPESCRIPT = 'typescript',
    VSCODE = 'vscode',
}

type IconHovered = {
    [k: string]: boolean;
};

type FlexFooterDevProps = {
    className?: string;
};

const FlexFooterDev = ({ className }: FlexFooterDevProps) => {
    const [iconHovered, setIconHovered] = useState<IconHovered>({
        [Icon.FIREFOX]: false,
        [Icon.JAVASCRIPT]: false,
        [Icon.REACT]: false,
        [Icon.TAILWIND]: false,
        [Icon.TYPESCRIPT]: false,
    });

    const onMouseOverLink = (icon: string) => {
        setIconHovered({ ...iconHovered, [icon]: true });
    };

    const onMouseOutLink = (icon: string) => {
        setIconHovered({ ...iconHovered, [icon]: false });
    };

    const getLogoCssClasses = (icon: string) => {
        const cssClasses = ['logo-brand'];
        if (iconHovered[icon]) cssClasses.push('hovered');

        return cssClasses.join(' ');
    };

    return (
        <div className={className}>
            <p className="pb-6 text-justify">
                Made with love
                <i className="fa-solid fa-heart mx-2 text-flex01-base"></i>using
                awesome
                <i className="fa-solid fa-burst mx-2 text-orange-400"></i>tools
                that makes web dev so cool.
            </p>
            <div className="flex-wrap-row-between pb-6">
                <div className="badge-icon">
                    <a
                        href="https://www.mozilla.org/"
                        onMouseOver={() => onMouseOverLink(Icon.FIREFOX)}
                        onMouseOut={() => onMouseOutLink(Icon.FIREFOX)}
                    >
                        <LogoFirefox
                            className={getLogoCssClasses(Icon.FIREFOX)}
                        />
                    </a>
                </div>
                <div className="badge-icon">
                    <a
                        href="https://www.javascript.com/"
                        onMouseOver={() => onMouseOverLink(Icon.JAVASCRIPT)}
                        onMouseOut={() => onMouseOutLink(Icon.JAVASCRIPT)}
                    >
                        <LogoJavascript
                            className={getLogoCssClasses(Icon.JAVASCRIPT)}
                        />
                    </a>
                </div>
                <div className="badge-icon">
                    <a
                        href="https://reactjs.org/"
                        onMouseOver={() => onMouseOverLink(Icon.REACT)}
                        onMouseOut={() => onMouseOutLink(Icon.REACT)}
                    >
                        <LogoReact className={getLogoCssClasses(Icon.REACT)} />
                    </a>
                </div>
                <div className="badge-icon">
                    <a
                        href="https://www.typescriptlang.org/"
                        onMouseOver={() => onMouseOverLink(Icon.TYPESCRIPT)}
                        onMouseOut={() => onMouseOutLink(Icon.TYPESCRIPT)}
                    >
                        <LogoTypescript
                            className={getLogoCssClasses(Icon.TYPESCRIPT)}
                        />
                    </a>
                </div>
                <div className="badge-icon">
                    <a
                        href="https://tailwindcss.com/"
                        onMouseOver={() => onMouseOverLink(Icon.TAILWIND)}
                        onMouseOut={() => onMouseOutLink(Icon.TAILWIND)}
                    >
                        <LogoTailwind
                            className={getLogoCssClasses(Icon.TAILWIND)}
                        />
                    </a>
                </div>
                <div className="badge-icon">
                    <a
                        href="https://code.visualstudio.com/"
                        onMouseOver={() => onMouseOverLink(Icon.VSCODE)}
                        onMouseOut={() => onMouseOutLink(Icon.VSCODE)}
                    >
                        <LogoVscode
                            className={getLogoCssClasses(Icon.VSCODE)}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FlexFooterDev;
