import { collection, getCountFromServer } from '@firebase/firestore';
import { query, where } from 'firebase/firestore';
import { firestoreDb } from '../../lib/firebase';

export const getFirebaseCollectionCount = async (collectionName: string) => {
    const firebaseCollection = collection(firestoreDb, collectionName);
    const snapshotCount = await getCountFromServer(firebaseCollection);

    return snapshotCount.data().count;
};

export const getFirebaseCollectionCountWhereArrayContains = async <T extends string | number>(
    collectionName: string,
    fieldName: string,
    fieldValue: T
) => {
    const firebaseCollection = collection(firestoreDb, collectionName);

    // Query parameters
    const q = query(firebaseCollection, where(fieldName, 'array-contains', fieldValue));

    const snapshotCount = await getCountFromServer(q);

    return snapshotCount.data().count;
};

export const getFirebaseCollectionCountWhereArrayContainsAny = async <T extends string | number>(
    collectionName: string,
    fieldName: string,
    fieldValues: T[]
) => {
    const firebaseCollection = collection(firestoreDb, collectionName);

    // Query parameters
    const q = query(firebaseCollection, where(fieldName, 'array-contains-any', fieldValues));

    const snapshotCount = await getCountFromServer(q);

    return snapshotCount.data().count;
};
