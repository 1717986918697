import { getAuth } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as Logo31st } from '../../assets/images/logo-31st.svg';

interface BackShopNavigationProps {
    collapsed: boolean;
    onClickToggleCollapsed: () => void;
}

const navigationElements = [
    {
        hash: '/backshop/home',
        className: 'nav-item',
        iconClassName: 'fa-solid fa-house',
        label: 'Home',
    },
    {
        hash: '/backshop/news',
        className: 'nav-item',
        iconClassName: 'fa-solid fa-star',
        label: 'News',
    },
    {
        hash: '/backshop/blog',
        className: 'nav-item',
        iconClassName: 'fa-solid fa-pepper-hot',
        label: 'Blog',
    },
    {
        hash: '/backshop/blog/category',
        className: 'nav-item',
        iconClassName: 'fa-solid fa-tag',
        label: "Blog's categories",
    },
    {
        hash: '/backshop/logout',
        className: 'nav-item',
        iconClassName: 'fa-solid fa-square-arrow-up-right',
        label: 'Logout',
    },
];

const BackShopNavigation = ({ collapsed, onClickToggleCollapsed }: BackShopNavigationProps) => {
    const cssClasses = ['backshop-navigation'];
    if (collapsed) cssClasses.push('collapsed');

    const auth = getAuth();
    const { pathname } = useLocation();

    return (
        <aside className={cssClasses.join(' ')}>
            <div className="nav-item" onClick={onClickToggleCollapsed}>
                <i className="fa-solid fa-bars"></i> Toggle
            </div>
            {navigationElements.map((element) => {
                const isCurrent = element.hash === pathname;
                const elementClassName = element.className + (isCurrent ? ` current` : ``);

                return (
                    <HashLink
                        key={`backShop-nav-link-${element.hash}`}
                        to={element.hash}
                        className={elementClassName}
                    >
                        <i className={element.iconClassName}></i> {element.label}
                    </HashLink>
                );
            })}
            {!collapsed && auth.currentUser && (
                <div className="flex-wrap-col-center m-3 rounded-md bg-rose-400 p-3 text-sm">
                    Logged in as{' '}
                    <span className="italic">
                        {auth.currentUser.displayName ?? auth.currentUser.email}
                    </span>
                </div>
            )}
            <div
                className={`mt-auto flex flex-wrap justify-center ${
                    collapsed ? `w-full` : `w-auto`
                }`}
            >
                <HashLink to={'/'}>
                    <Logo31st className="h-10 w-10" />
                </HashLink>
            </div>
        </aside>
    );
};

export default BackShopNavigation;
