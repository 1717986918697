// Array elements to string or "undefined" if the array is empty
export const implodeOrUndefined = (list: string[], separator = ' ') => {
    if (!list.length) return undefined;

    return list.join(separator);
};

// Deep copie d'un object "Map"
export const mapClone = <T, U>(map: Map<T, U>): Map<T, U> => {
    return new Map(JSON.parse(JSON.stringify(Array.from(map))));
};
