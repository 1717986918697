import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { CONTACT_EMAIL, TWITTER_ACCOUNT } from '../../../constants/constants';

const windowInner768 = () => {
    return window.innerWidth <= 768;
};

const HomeNavigation = () => {
    const [isSm, setIsSm] = useState(windowInner768());
    const [menuSmIsOpen, setMenuSmIsOpen] = useState(true);

    const onClickToggleMenuSm = () => {
        setMenuSmIsOpen(isSm ? !menuSmIsOpen : true);
    };

    const cssClasses = ['navigation navigation-main-sm md:navigation-main'];
    if (!menuSmIsOpen) {
        cssClasses.push('navigation-main-sm-collapsed');
    }

    const windowSizeChangedHandler = () => {
        setIsSm(windowInner768());
    };

    useEffect(() => {
        window.addEventListener('resize', windowSizeChangedHandler);

        return window.removeEventListener('resize', windowSizeChangedHandler);
    }, []);

    return (
        <>
            <nav className={cssClasses.join(' ')}>
                <HashLink
                    smooth
                    to={''}
                    className="navigation-main-item toogle-menu alink md:hidden"
                    onClick={onClickToggleMenuSm}
                >
                    <div className="icon">
                        <i className={`fa-solid fa-bars`}></i>
                    </div>{' '}
                    <div className="text">Toogle menu</div>
                </HashLink>
                {menuSmIsOpen && (
                    <>
                        <HashLink
                            smooth
                            to={'/#home'}
                            onClick={onClickToggleMenuSm}
                            className="navigation-main-item"
                        >
                            <div>
                                <i className={`fa-solid fa-arrow-up`}></i>
                            </div>{' '}
                            Home
                        </HashLink>
                        <HashLink
                            smooth
                            to={'/#whatsup'}
                            onClick={onClickToggleMenuSm}
                            className="navigation-main-item"
                        >
                            <div>
                                <i className={`fa-solid fa-star`}></i>
                            </div>{' '}
                            What's up ?
                        </HashLink>
                        <HashLink
                            smooth
                            to={'/#31stcode'}
                            onClick={onClickToggleMenuSm}
                            className="navigation-main-item"
                        >
                            <div>
                                <i className={`fa-solid fa-code`}></i>
                            </div>{' '}
                            31stcode
                        </HashLink>
                        <HashLink
                            smooth
                            to={'/#31stflex'}
                            onClick={onClickToggleMenuSm}
                            className="navigation-main-item"
                        >
                            <div>
                                <i className={`fa-solid fa-robot`}></i>
                            </div>{' '}
                            31stflex
                        </HashLink>
                        <HashLink
                            smooth
                            to={'/#31stshiny'}
                            onClick={onClickToggleMenuSm}
                            className="navigation-main-item"
                        >
                            <div>
                                <i className={`fa-solid fa-star`}></i>
                            </div>{' '}
                            31stshiny
                        </HashLink>
                        <HashLink
                            smooth
                            to={'/#about'}
                            onClick={onClickToggleMenuSm}
                            className="navigation-main-item"
                        >
                            <div>
                                <i className={`fa-solid fa-seedling`}></i>
                            </div>{' '}
                            About
                        </HashLink>
                        <p className="flex justify-start gap-3 p-3">
                            <a href={`mailto:${CONTACT_EMAIL}`}>
                                <i className="fa-solid fa-envelope"></i>
                            </a>
                            <a
                                href={`https://twitter.com/intent/follow?screen_name=${TWITTER_ACCOUNT}`}
                            >
                                <i className="fa-brands fa-twitter"></i>
                            </a>
                        </p>
                    </>
                )}
            </nav>
        </>
    );
};

export default HomeNavigation;
