import { useEffect, useState } from 'react';
import { BlogCategory } from '../../../models/backShop/blogCategory';
import ButtonIcon from '../../Common/Button/ButtonIcon';
import DialogCustom from '../../Common/Dialog/DialogCustom';
import { getFirebaseCollectionCountWhereArrayContains } from '../../../features/firebase/firebase';

interface BackshopBlogCategoryProps {
    className?: string;
    category: BlogCategory;
    onClickBlogCategoryEditionHandler?: (category: BlogCategory) => void;
    onClickBlogCategoryDeleteHandler?: (category: BlogCategory) => Promise<void>;
}

const BackshopBlogCategory = ({
    className = undefined,
    category,
    onClickBlogCategoryEditionHandler = undefined,
    onClickBlogCategoryDeleteHandler = undefined,
}: BackshopBlogCategoryProps) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [inUse, setInUse] = useState(0);

    const onClickDeleteCategoryConfirmHandler = async () => {
        if (onClickBlogCategoryDeleteHandler) {
            await onClickBlogCategoryDeleteHandler(category);
        }
        setDialogOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const catUsageCount = await getFirebaseCollectionCountWhereArrayContains<string>(
                'blog-article',
                'categories',
                category.id
            );

            setInUse(catUsageCount);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`py-1 flex-wrap-row-end gap-2${className ? ` ${className}` : ``}`}>
            <DialogCustom<BlogCategory>
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                callbackHandlerParam={category}
                callbackHandler={onClickDeleteCategoryConfirmHandler}
                dialogMessage={`Confirm category "${category.label}" deletion ?`}
            />
            <div className="mr-auto flex-wrap-row gap-1">
                <span className="text-xs text-white flex-wrap-col-center rounded-md w-8 h-5 bg-slate-500">
                    {inUse}
                </span>
                {category.label}
            </div>

            {onClickBlogCategoryEditionHandler && (
                <ButtonIcon
                    iconClass={'fa-pen'}
                    onClickHandler={
                        onClickBlogCategoryEditionHandler
                            ? () => onClickBlogCategoryEditionHandler(category)
                            : undefined
                    }
                />
            )}
            {onClickBlogCategoryDeleteHandler && (
                <ButtonIcon
                    iconClass={'fa-xmark'}
                    onClickHandler={() => setDialogOpen(true)}
                    type="danger"
                    disabled={!!inUse}
                />
            )}
        </div>
    );
};

export default BackshopBlogCategory;
