import { CSSProperties } from 'react';
import { implodeOrUndefined } from '../../../lib/functionsArray';
import { getDateDataFromTimestamp } from '../../../lib/functionsDate';
import { NewsType, Titles } from '../../../models/31st';
import { NewsFirebase } from '../../../models/backShop/news';
import TitleColored from '../Common/TitleColored/TitleColored';

interface NewsDisplayProps {
    type: NewsType.NEWS;
    data: NewsFirebase;
    className?: string;
}

interface NewsDisplayEmptyProps
    extends Omit<NewsDisplayProps, 'type' | 'data'> {
    type: NewsType.NEWS_EMPTY;
}

const getNewsCssClasses = (
    props: NewsDisplayProps | NewsDisplayEmptyProps,
    otherClasses: string[] = []
) => {
    const { type, className } = props;

    const cssClasses = [type] as string[];
    if (className) cssClasses.push(className);

    return [...cssClasses, ...otherClasses];
};

const getNewsImage = (imageURL?: string) => {
    const cssProps: CSSProperties | undefined = imageURL
        ? {
              backgroundImage:
                  'url("' + process.env.PUBLIC_URL + imageURL + '")',
          }
        : undefined;

    const cssClasses = ['mb-3 h-64 w-full'];
    if (imageURL) {
        cssClasses.push('bg-cover bg-no-repeat');
    } else {
        cssClasses.push('preview-31stflex');
    }

    return (
        <div className={implodeOrUndefined(cssClasses)} style={cssProps}>
            &nbsp;
        </div>
    );
};

// News sans data
const NewsEmpty = (props: NewsDisplayEmptyProps) => {
    const cssClasses = getNewsCssClasses(props, ['h-full']);

    return (
        <div className={implodeOrUndefined(cssClasses)}>
            <TitleColored
                className="preview-31stflex mb-6 flex flex-row"
                sentence={''}
                titleTag={Titles.H4}
                suffixContent={<></>}
            />
            {getNewsImage()}
            <div className="preview-31stflex h-24 w-full"></div>
        </div>
    );
};

// News avec data
const NewsFilled = (props: NewsDisplayProps) => {
    const {
        data: { title, content, imageUrl, createdAt, updatedAt },
    } = props;
    const cssClasses = getNewsCssClasses(props);

    const dateData = getDateDataFromTimestamp(createdAt, updatedAt);
    const newsDate = dateData.date.format('LL');

    return (
        <article className={implodeOrUndefined(cssClasses)}>
            <TitleColored
                sentence={title}
                titleTag={Titles.H4}
                suffixContent={
                    <time className="rounded-r-md border-l border-l-col01-lightest bg-col01-base px-2 py-1 text-sm text-col01-lightest">
                        {newsDate}
                    </time>
                }
            />
            {getNewsImage(imageUrl)}
            <div className="text-justify">{content}</div>
        </article>
    );
};

// Affichage d'une news
const NewsDisplay = (props: NewsDisplayProps | NewsDisplayEmptyProps) => {
    const { type } = props;

    if (NewsType.NEWS === type) {
        return <NewsFilled {...props} />;
    }

    return <NewsEmpty {...props} />;
};

export default NewsDisplay;
