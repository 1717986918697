type SocialNetworks = 'twitter' | 'facebook' | 'linkedin';

interface ButtonSocialShareProps {
    className?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    type: SocialNetworks;
    data: {
        url: string;
        title?: string;
        handle?: string;
    };
}

interface SocialButtonSetting {
    type: SocialNetworks;
    iconCssClass: string;
    url: string;
}

const socialButtonsSettings: SocialButtonSetting[] = [
    {
        type: 'twitter',
        iconCssClass: 'fa-brands fa-twitter',
        url: 'https://twitter.com/intent/tweet',
    },
    {
        type: 'facebook',
        iconCssClass: 'fa-brands fa-facebook',
        url: 'https://www.facebook.com/sharer/sharer.php',
    },
    {
        type: 'linkedin',
        iconCssClass: 'fa-brands fa-linkedin',
        url: 'https://www.linkedin.com/shareArticle',
    },
];

const getSharingUrl = (type: SocialNetworks, dataPicked: Pick<ButtonSocialShareProps, 'data'>) => {
    const networkUrl =
        socialButtonsSettings.find((setting) => setting.type === type)?.url ?? 'https://31st.fr';
    const sharedUrl = dataPicked?.data.url;
    const title = encodeURI(dataPicked?.data?.title ?? '');
    const handle = dataPicked?.data?.handle;

    switch (type) {
        case 'twitter':
            return `${networkUrl}?url=${sharedUrl}&text=${title}&via=${handle}`;

        case 'facebook':
            return `${networkUrl}?u=${sharedUrl}`;

        case 'linkedin':
            return `${networkUrl}?url=${sharedUrl}&title=${title}`;

        default:
            return `https://31st.fr`;
    }
};

const ButtonSocialShare = ({
    type,
    data,
    size = 'md',
    className = undefined,
}: ButtonSocialShareProps) => {
    const cssClasses = [
        'flex-wrap-col-center',
        'text-white',
        'bg-white/10',
        'border',
        'border-white/15',
        'border-dashed',
        'rounded-full',
        'transition-300',
        'cursor-pointer',
        'hover:bg-white/25',
    ];

    switch (size) {
        case 'xs':
            cssClasses.push('h-8 w-8 text-xs');
            break;
        case 'sm':
            cssClasses.push('h-10 w-10');
            break;
        case 'md':
            cssClasses.push('h-12 w-12 text-xl');
            break;
        case 'lg':
            cssClasses.push('h-16 w-16 text-3xl');
            break;
        case 'xl':
            cssClasses.push('h-20 w-20 text-4xl');
            break;
        default:
            cssClasses.push('h-12 w-12');
            break;
    }

    const iconCssClasses =
        socialButtonsSettings.find((setting) => setting.type === type)?.iconCssClass ?? undefined;

    if (className) {
        cssClasses.push(className);
    }

    return (
        <a
            className={cssClasses.join(' ')}
            href={getSharingUrl(type, { data })}
            target="_blank"
            rel="nofollow noopener noreferrer"
        >
            <i className={iconCssClasses}></i>
        </a>
    );
};

export default ButtonSocialShare;
