import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

// Mise en forme d'un timestamp en date + libellé ( created | updated )
export const getDateDataFromTimestamp = (createdAt: Timestamp, updatedAt: Timestamp) => {
    const createdDate = dayjs(createdAt.toDate());
    const updatedDate = dayjs(updatedAt.toDate());

    if (createdDate.isBefore(updatedDate)) {
        return {
            date: updatedDate,
            label: 'Updated',
        };
    }

    return {
        date: createdDate,
        label: 'Created',
    };
};
