import _ from 'lodash';
import { regFlexBasis, regInteger, regIntegerNeg } from '../lib/regExp';
import {
    CssMap,
    FlexOptionProperty,
    FlexOptionPropertyValue,
    FlexOptionPropertyValueUnit,
    FlexOptionSetting,
    FlexOptionType,
    FlexOptionTypeFamily,
    InlineStyle,
    SettingsDefaultMap,
    SettingsMap,
    TupleStringString,
} from '../models/31stflex';

export const getFlexOptionDefaultSetting = (
    flexOptionProperty: FlexOptionProperty
): FlexOptionSetting => {
    switch (flexOptionProperty) {
        case FlexOptionProperty.DISPLAY:
            return {
                type: FlexOptionType.FLEX_CONTAINER_PROP,
                property: flexOptionProperty,
                options: [
                    FlexOptionPropertyValue.FLEX,
                    FlexOptionPropertyValue.INLINE_FLEX,
                ],
                default: FlexOptionPropertyValue.FLEX,
                value: FlexOptionPropertyValue.FLEX,
            };

        case FlexOptionProperty.FLEX_DIRECTION:
            return {
                type: FlexOptionType.FLEX_CONTAINER_PROP,
                property: flexOptionProperty,
                options: [
                    FlexOptionPropertyValue.ROW,
                    FlexOptionPropertyValue.ROW_REVERSE,
                    FlexOptionPropertyValue.COLUMN,
                    FlexOptionPropertyValue.COLUMN_REVERSE,
                ],
                default: FlexOptionPropertyValue.ROW,
                value: FlexOptionPropertyValue.ROW,
            };

        case FlexOptionProperty.FLEX_WRAP:
            return {
                type: FlexOptionType.FLEX_CONTAINER_PROP,
                property: flexOptionProperty,
                options: [
                    FlexOptionPropertyValue.NOWRAP,
                    FlexOptionPropertyValue.WRAP,
                    FlexOptionPropertyValue.WRAP_REVERSE,
                ],
                default: FlexOptionPropertyValue.NOWRAP,
                value: FlexOptionPropertyValue.NOWRAP,
            };

        case FlexOptionProperty.JUSTIFY_CONTENT:
            return {
                type: FlexOptionType.FLEX_CONTAINER_PROP,
                property: flexOptionProperty,
                options: [
                    FlexOptionPropertyValue.FLEX_START,
                    FlexOptionPropertyValue.FLEX_END,
                    FlexOptionPropertyValue.CENTER,
                    FlexOptionPropertyValue.SPACE_BETWEEN,
                    FlexOptionPropertyValue.SPACE_AROUND,
                ],
                default: FlexOptionPropertyValue.FLEX_START,
                value: FlexOptionPropertyValue.FLEX_START,
            };

        case FlexOptionProperty.ALIGN_ITEMS:
            return {
                type: FlexOptionType.FLEX_CONTAINER_PROP,
                property: flexOptionProperty,
                options: [
                    FlexOptionPropertyValue.FLEX_START,
                    FlexOptionPropertyValue.FLEX_END,
                    FlexOptionPropertyValue.CENTER,
                    FlexOptionPropertyValue.BASELINE,
                    FlexOptionPropertyValue.STRETCH,
                ],
                default: FlexOptionPropertyValue.FLEX_START,
                value: FlexOptionPropertyValue.FLEX_START,
            };

        case FlexOptionProperty.ALIGN_CONTENT:
            return {
                type: FlexOptionType.FLEX_CONTAINER_PROP,
                property: flexOptionProperty,
                options: [
                    FlexOptionPropertyValue.FLEX_START,
                    FlexOptionPropertyValue.FLEX_END,
                    FlexOptionPropertyValue.CENTER,
                    FlexOptionPropertyValue.SPACE_BETWEEN,
                    FlexOptionPropertyValue.SPACE_AROUND,
                    FlexOptionPropertyValue.STRETCH,
                ],
                default: FlexOptionPropertyValue.FLEX_START,
                value: FlexOptionPropertyValue.FLEX_START,
            };

        case FlexOptionProperty.GAP:
            return {
                type: FlexOptionType.FLEX_CONTAINER_VALUE,
                property: flexOptionProperty,
                options: [0], ///////////////////////////// ???????????????
                default: '0',
                value: '0',
                regex: new RegExp(regInteger),
                units: [
                    FlexOptionPropertyValueUnit.PIXEL,
                    FlexOptionPropertyValueUnit.REM,
                    FlexOptionPropertyValueUnit.EM,
                    FlexOptionPropertyValueUnit.PERCENT,
                ],
                unit: FlexOptionPropertyValueUnit.PIXEL,
                unitDefault: FlexOptionPropertyValueUnit.PIXEL,
            };

        case FlexOptionProperty.ITEMS_SELECTOR:
            return {
                type: FlexOptionType.FLEX_ITEM_SELECTOR,
                property: flexOptionProperty,
                options: [...Array(4).keys()],
                default: 0,
                value: 0,
            };

        case FlexOptionProperty.ORDER:
            return {
                type: FlexOptionType.FLEX_ITEM_VALUE,
                property: flexOptionProperty,
                options: [0],
                default: 0,
                value: 0,
                regex: new RegExp(regIntegerNeg),
            };

        case FlexOptionProperty.FLEX_GROW:
            return {
                type: FlexOptionType.FLEX_ITEM_VALUE,
                property: flexOptionProperty,
                options: [...Array(100).keys()],
                default: 0,
                value: 0,
                regex: new RegExp(regInteger),
            };

        case FlexOptionProperty.FLEX_SHRINK:
            return {
                type: FlexOptionType.FLEX_ITEM_VALUE,
                property: flexOptionProperty,
                options: [...Array(100).keys()],
                default: 0,
                value: 0,
                regex: new RegExp(regInteger),
            };

        case FlexOptionProperty.FLEX_BASIS:
            return {
                type: FlexOptionType.FLEX_ITEM_PROP_AND_VALUE,
                property: flexOptionProperty,
                options: [
                    FlexOptionPropertyValue.AUTO,
                    FlexOptionPropertyValue.CONTENT,
                ],
                default: FlexOptionPropertyValue.AUTO,
                value: FlexOptionPropertyValue.AUTO,
                units: [
                    FlexOptionPropertyValueUnit.PIXEL,
                    FlexOptionPropertyValueUnit.REM,
                    FlexOptionPropertyValueUnit.EM,
                    FlexOptionPropertyValueUnit.PERCENT,
                ],
                unit: FlexOptionPropertyValueUnit.PIXEL,
                unitDefault: FlexOptionPropertyValueUnit.PIXEL,
                regex: new RegExp(regFlexBasis),
            };

        case FlexOptionProperty.ALIGN_SELF:
            return {
                type: FlexOptionType.FLEX_ITEM_PROP,
                property: flexOptionProperty,
                options: [
                    FlexOptionPropertyValue.AUTO,
                    FlexOptionPropertyValue.FLEX_START,
                    FlexOptionPropertyValue.FLEX_END,
                    FlexOptionPropertyValue.CENTER,
                    FlexOptionPropertyValue.SPACE_BETWEEN,
                    FlexOptionPropertyValue.SPACE_AROUND,
                    FlexOptionPropertyValue.STRETCH,
                ],
                default: FlexOptionPropertyValue.AUTO,
                value: FlexOptionPropertyValue.AUTO,
            };
    }
};

// Default settings: Selector
export const settingsDefaultSelector: SettingsDefaultMap = new Map([
    [
        FlexOptionProperty.ITEMS_SELECTOR,
        {
            title: 'Items selector',
            setting: getFlexOptionDefaultSetting(
                FlexOptionProperty.ITEMS_SELECTOR
            ),
            order: 0,
        },
    ],
]);

// Default settings: Container
export const settingsDefaultContainer: SettingsDefaultMap = new Map([
    [
        FlexOptionProperty.DISPLAY,
        {
            title: 'display',
            setting: getFlexOptionDefaultSetting(FlexOptionProperty.DISPLAY),
            order: 0,
        },
    ],
    [
        FlexOptionProperty.FLEX_DIRECTION,
        {
            title: 'flex-direction',
            setting: getFlexOptionDefaultSetting(
                FlexOptionProperty.FLEX_DIRECTION
            ),
            order: 1,
        },
    ],
    [
        FlexOptionProperty.FLEX_WRAP,
        {
            title: 'flex-wrap',
            setting: getFlexOptionDefaultSetting(FlexOptionProperty.FLEX_WRAP),
            order: 2,
        },
    ],
    [
        FlexOptionProperty.JUSTIFY_CONTENT,
        {
            title: 'justify-content',
            setting: getFlexOptionDefaultSetting(
                FlexOptionProperty.JUSTIFY_CONTENT
            ),
            order: 3,
        },
    ],
    [
        FlexOptionProperty.ALIGN_ITEMS,
        {
            title: 'align-items',
            setting: getFlexOptionDefaultSetting(
                FlexOptionProperty.ALIGN_ITEMS
            ),
            order: 4,
        },
    ],
    [
        FlexOptionProperty.ALIGN_CONTENT,
        {
            title: 'align-content',
            setting: getFlexOptionDefaultSetting(
                FlexOptionProperty.ALIGN_CONTENT
            ),
            order: 5,
        },
    ],
    [
        FlexOptionProperty.GAP,
        {
            title: 'gap',
            setting: getFlexOptionDefaultSetting(FlexOptionProperty.GAP),
            order: 6,
        },
    ],
]);

// Default settings: Item
export const settingsDefaultItem: SettingsDefaultMap = new Map([
    [
        FlexOptionProperty.ORDER,
        {
            title: 'order',
            setting: getFlexOptionDefaultSetting(FlexOptionProperty.ORDER),
            order: 3,
        },
    ],
    [
        FlexOptionProperty.FLEX_GROW,
        {
            title: 'flex-grow',
            setting: getFlexOptionDefaultSetting(FlexOptionProperty.FLEX_GROW),
            order: 0,
        },
    ],
    [
        FlexOptionProperty.FLEX_SHRINK,
        {
            title: 'flex-shrink',
            setting: getFlexOptionDefaultSetting(
                FlexOptionProperty.FLEX_SHRINK
            ),
            order: 1,
        },
    ],
    [
        FlexOptionProperty.FLEX_BASIS,
        {
            title: 'flex-basis',
            setting: getFlexOptionDefaultSetting(FlexOptionProperty.FLEX_BASIS),
            order: 2,
        },
    ],
    [
        FlexOptionProperty.ALIGN_SELF,
        {
            title: 'align-self',
            setting: getFlexOptionDefaultSetting(FlexOptionProperty.ALIGN_SELF),
            order: 4,
        },
    ],
]);

// Retourne la propriété "type" associée à une option
export const getOptionType = (
    flexOptionProperty: FlexOptionProperty
): FlexOptionType => {
    return getFlexOptionDefaultSetting(flexOptionProperty).type;
};

// Retourne la famille d'une option en fonction de son type
export const getOptionTypeFamily = (flexOptionProperty: FlexOptionProperty) => {
    const optionType = getOptionType(flexOptionProperty);

    switch (optionType) {
        case FlexOptionType.FLEX_CONTAINER_PROP:
        case FlexOptionType.FLEX_CONTAINER_VALUE:
            return FlexOptionTypeFamily.CONTAINER;

        case FlexOptionType.FLEX_ITEM_PROP:
        case FlexOptionType.FLEX_ITEM_VALUE:
        case FlexOptionType.FLEX_ITEM_PROP_AND_VALUE:
            return FlexOptionTypeFamily.ITEM;

        case FlexOptionType.FLEX_ITEM_SELECTOR:
            return FlexOptionTypeFamily.SELECTOR;
    }
};

// Formatage des valeurs pour la sélection d'items
export const itemSelectorTextFormat = (value: number) => {
    if (0 === value) return 'ALL';

    return String(value).padStart(2, '0');
};

export const getStyleUnit = (
    value?: string | number,
    unit?: FlexOptionPropertyValueUnit
) => {
    return Number.isNaN(Number(value)) ? '' : unit ?? '';
};

// Retourne un object contenant les styles "inline" à appliquer sur l'élement "container" de la preview
export const getContainerStyle = (containerSettings: SettingsDefaultMap) => {
    const styles = Array.from(containerSettings).reduce(
        (style: InlineStyle, [__, setting]) => {
            const unit = getStyleUnit(
                setting.setting.value,
                setting.setting.unit
            );

            style[_.camelCase(setting.setting.property)] =
                setting.setting.value + unit;

            return style;
        },
        {} as InlineStyle
    );

    return styles;
};

// Retourne un object contenant les styles "inline" à appliquer sur les élements "item" de la preview
export const getItemStyle = (itemSetting: SettingsDefaultMap) => {
    const style = Array.from(itemSetting).reduce(
        (style: InlineStyle, [__, setting]) => {
            const unit = getStyleUnit(
                setting.setting.value,
                setting.setting.unit
            );

            style[_.camelCase(setting.setting.property)] =
                setting.setting.value + unit;

            return style;
        },
        {} as InlineStyle
    );

    return style;
};

// Retourne une Map avec l'ensemble des styles applicables pour la génération du code CSS ( display | text )
export const getCssMap = (
    containerSettings: SettingsDefaultMap,
    itemsSettings: SettingsMap
) => {
    const cssMap = new Map() as CssMap;

    // Container
    const containerCss = _.sortBy(
        Array.from(containerSettings).map(([__, setting]) => setting),
        ['order']
    ).reduce((cssProperties: any, setting) => {
        const unit = getStyleUnit(setting.setting.value, setting.setting.unit);

        return [
            ...cssProperties,
            [setting.setting.property, setting.setting.value + unit],
        ];
    }, [] as TupleStringString[]);

    cssMap.set('container', containerCss);

    // Items
    itemsSettings.forEach((itemSetting, key) => {
        const itemCss = _.sortBy(
            Array.from(itemSetting).map(([__, setting]) => setting),
            ['order']
        ).reduce((cssProperties: any, setting) => {
            const unit = getStyleUnit(
                setting.setting.value,
                setting.setting.unit
            );

            const allSettingValue = itemsSettings
                .get(0)
                ?.get(setting.setting.property)?.setting.value;
            if (
                0 !== key &&
                String(setting.setting.value) === String(allSettingValue)
            ) {
                return cssProperties;
            }

            return [
                ...cssProperties,
                [setting.setting.property, setting.setting.value + unit],
            ];
        }, [] as TupleStringString[]);

        const selector = 0 === key ? 'children' : `child-${key}`;
        if (0 === key || itemCss.length) {
            cssMap.set(selector, itemCss);
        }
    });

    return cssMap;
};
