import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react';

export interface SliderStatus {
    value: number;
    isChanging?: boolean;
}

interface SliderProps {
    className?: string;
    // Valeur init.
    initialValue?: number;
    // Pour mise à jour du state parent
    setExternalStatusState?: Dispatch<SetStateAction<SliderStatus>>;
    // Position du badge horizontale
    badgePositionX?: 'start' | 'center' | 'end' | 'none';
    // Position du badge verticale
    badgePositionY?: 'top' | 'bottom';
}

const Slider = ({
    initialValue = 50,
    badgePositionX = 'center',
    badgePositionY = 'top',
    className = undefined,
    setExternalStatusState = undefined,
}: SliderProps) => {
    const [currentValue, setCurrentValue] = useState(initialValue);

    const onChangeSliderHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.valueAsNumber;

        setCurrentValue(value);
        if (setExternalStatusState) {
            setExternalStatusState({
                value,
                isChanging: true,
            });
        }
    };

    const onMouseUpSliderHandler = () => {
        if (setExternalStatusState) {
            setExternalStatusState({
                value: currentValue,
                isChanging: false,
            });
        }
    };

    useEffect(() => {
        setCurrentValue(initialValue);
    }, [initialValue]);

    const hidden = 'none' === badgePositionX;
    const yPosCss = 'top' === badgePositionY ? '' : ' order-last';
    let badgeWrapperCss = `flex-wrap-row justify-${badgePositionX}${yPosCss}${
        hidden ? ` hidden` : ``
    }`;

    return (
        <div className={`slider-wrapper${className ? ` ${className}` : ``}`}>
            <div className={badgeWrapperCss}>
                <div className="slider-badge-value">{currentValue}</div>
            </div>
            <div className="slider-track">
                <input
                    className="slider-input"
                    type="range"
                    min="0"
                    max="100"
                    onChange={onChangeSliderHandler}
                    onMouseUp={onMouseUpSliderHandler}
                />
                <div
                    className="slider-fill"
                    style={{ width: `${currentValue}%` }}
                ></div>
            </div>
        </div>
    );
};

export default Slider;
