export const getPaginationTotalPages = (itemsCount: number, perPage: number) => {
    if (0 === perPage || 0 === itemsCount) return 0;

    return Math.ceil(itemsCount / perPage);
};

export const getPaginationPages = (itemsCount: number, perPage: number) => {
    const totalPages = getPaginationTotalPages(itemsCount, perPage);

    return [...Array(totalPages).keys()].map((i) => i + 1);
};

export const getPaginationIsLastPage = (
    itemsCount: number,
    perPage: number,
    currentPage: number
): boolean => {
    return currentPage + 1 >= getPaginationTotalPages(itemsCount, perPage);
};
