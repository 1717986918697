import { useState } from 'react';
import { STATUS, UiMessage } from '../../models/generic';

const checkLogMessage = ` (check console log for details)`;

// Hook pour la complétion d'un message UI ( complète la date, le status, affiche les logs si nécessaires... )
export const useUiMessage = () => {
    const [uiMessageData, setUiMessageData] = useState<UiMessage | undefined>(undefined);

    const setUiMessageSuccess = ({ message, log }: Omit<UiMessage, 'status' | 'date'>) => {
        let logMessage = '';

        if (log) {
            console.group(log.type);
            console.log(log.data);
            console.groupEnd();
            logMessage = checkLogMessage;
        }

        setUiMessageData({
            status: STATUS.SUCCESS,
            message: message + logMessage,
            date: new Date(),
        });
    };

    const setUiMessageFailure = ({ message, log }: Omit<UiMessage, 'status' | 'date'>) => {
        let logMessage = '';

        if (log) {
            console.group(log.type);
            console.error(log.data);
            console.groupEnd();
            logMessage = checkLogMessage;
        }

        setUiMessageData({
            status: STATUS.FAILURE,
            message: message + logMessage,
            date: new Date(),
        });
    };

    const resetUiMessage = () => {
        setUiMessageData(undefined);
    };

    return {
        uiMessageData,
        setUiMessageSuccess,
        setUiMessageFailure,
        resetUiMessage,
    };
};
