import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
    selectAllBlogCategory,
    selectBlogCategoryCount,
    selectBlogCategoryStatus,
} from '../../features/blog/blogCategorySelector';
import { setBlogCategories } from '../../features/blog/blogCategorySlice';
import { getFirebaseBlogCategories } from '../../features/firebase/firebaseBlogCategory';
import { FETCH_STATUS } from '../../models/generic';
import { useAppDispatch, useAppSelector } from '../hooks';

// Fetch des categories blog & dispatch dans le store
// N.b: Fetch uniquement si "fetchTrigger" est renseigné
export const useFetchAndDispatchBlogCategories = () => {
    const dispatch = useAppDispatch();

    const blogCategories = useAppSelector(selectAllBlogCategory);
    const blogCategoriesCount = useAppSelector(selectBlogCategoryCount);

    const blogCategoriesIsLoading =
        useAppSelector(selectBlogCategoryStatus) === FETCH_STATUS.LOADING;
    const blogCategoriesIsSuccess =
        useAppSelector(selectBlogCategoryStatus) === FETCH_STATUS.SUCCEEDED;
    const blogCategoriesIsFailed =
        useAppSelector(selectBlogCategoryStatus) === FETCH_STATUS.FAILED;

    const [triggerFetch, setTriggerFetch] = useState<Date | undefined>(
        undefined
    );

    // Permet de (re-)lancer le fetch des data
    const blogCategoriesFetchTrigger = () => {
        setTriggerFetch(new Date());
    };

    useEffect(() => {
        if (!triggerFetch) return;

        const fetchData = async () => {
            dispatch(
                setBlogCategories({
                    categories: [],
                    status: FETCH_STATUS.LOADING,
                })
            );

            try {
                // Blog categories
                const { blogCategoryList } = await getFirebaseBlogCategories();

                dispatch(
                    setBlogCategories({
                        categories: _.sortBy(blogCategoryList, ['label']),
                        status: FETCH_STATUS.SUCCEEDED,
                    })
                );
            } catch (error) {
                dispatch(
                    setBlogCategories({
                        categories: [],
                        status: FETCH_STATUS.FAILED,
                    })
                );
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerFetch]);

    return {
        // Statuts
        blogCategoriesIsLoading,
        blogCategoriesIsFailed,
        blogCategoriesIsSuccess,
        // Data dans le store
        blogCategories,
        // Nbre de catégories
        blogCategoriesCount,
        // Trigger (re-)fetch
        blogCategoriesFetchTrigger,
    };
};
