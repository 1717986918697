import { BlogArticleTypesKeys } from '../models/backShop/blogArticle';

// Types des articles du blog
export const BLOG_ARTICLE_TYPES = [
    { key: BlogArticleTypesKeys.CODE, value: 'Code' },
    { key: BlogArticleTypesKeys.MISC, value: 'Misc' },
];

// Nombre d'items max
export const FLEX_ITEMS_MAX_QUANTITY = 10;

// @ de contact
export const CONTACT_EMAIL = 'hello@31st.fr';

// Compte Twitter ( X )
export const TWITTER_ACCOUNT = 'greg31st';
