import { Timestamp } from '@firebase/firestore';
import { Dispatch, SetStateAction } from 'react';
import { PaginationData } from '../generic';

export enum BlogArticleTypesKeys {
    CODE = 'CODE',
    MISC = 'MISC',
}

export enum BlogArticleFetchTriggerMode {
    ARTICLES = 'articles',
    ARTICLES_AND_COUNT = 'articles+count',
}

export interface BlogArticle {
    categories: string[];
    content: string;
    createdAt: Timestamp;
    id: string;
    seoDescription: string;
    title: string;
    type: BlogArticleTypesKeys;
    updatedAt: Timestamp;
}

export interface BlogArticleData {
    // Liste des articles retournées par le fetch
    data: BlogArticle[];
    // Liste des articles pour affichage ( éventuellement filtrés )
    articles: BlogArticle[];
    articlesCount: number;
    loading: boolean;
    triggerFetchDate?: Date;
}

export interface UseBlogArticleReturn extends BlogArticleData {
    pagination: PaginationData;
    setCurrentPagination: (currentPagination: Partial<PaginationData>) => void;
    filterCategory: Set<string>;
    setFilterCategory: Dispatch<SetStateAction<Set<string>>>;
    filterCategoryChange: number;
    triggerArticlesFetch: () => void;
}
