// Import the functions you need from the SDKs you need

// import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// @TODO: Déplacer dans .env

export const firebaseConfig = {
    apiKey: 'AIzaSyDagPgD5GDLYg4PzrnhmHAGr6zsbWKGWzs',

    authDomain: 'st-13ef1.firebaseapp.com',

    projectId: 'st-13ef1',

    storageBucket: 'st-13ef1.appspot.com',

    messagingSenderId: '232378750808',

    appId: '1:232378750808:web:ce14bb6834553669c4f72d',

    measurementId: 'G-TQ5W4GN2QW',
};

// Initialize Firebase

// const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
