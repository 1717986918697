import { Typography } from '@material-tailwind/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getDateDataFromTimestamp } from '../../../lib/functionsDate';
import { NewsFirebase } from '../../../models/backShop/news';
import ButtonIcon from '../../Common/Button/ButtonIcon';
import DialogCustom from '../../Common/Dialog/DialogCustom';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

interface BackShopWhatsUpNewsProps {
    className?: string;
    news: NewsFirebase;
    onClickNewsEditionHandler: (news: NewsFirebase) => void;
    onClickNewsDeleteHandler: (news: NewsFirebase) => void;
}

export const BackShopWhatsUpNews = ({
    news,
    onClickNewsEditionHandler,
    onClickNewsDeleteHandler,
    className = undefined,
}: BackShopWhatsUpNewsProps) => {
    const dateData = getDateDataFromTimestamp(news.createdAt, news.updatedAt);

    const [dialogOpen, setDialogOpen] = useState(false);

    const onClickDeleteNewsConfirmHandler = async () => {
        onClickNewsDeleteHandler(news);
    };

    return (
        <div className={`backshop-news py-4${className ? ` ${className}` : ``}`}>
            <DialogCustom<string>
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                callbackHandlerParam={news.id}
                callbackHandler={onClickDeleteNewsConfirmHandler}
                dialogMessage={'Confirm news deletion ?'}
            />
            <Typography variant="h6" className="p-0 font-normal text-purple-500">
                <div className="flex-wrap-row-between">
                    <div className="h-64">
                        {news.imageUrl && (
                            <img
                                className="h-64 w-96 rounded-l-md"
                                src={news.imageUrl}
                                alt={news.title}
                            />
                        )}
                    </div>

                    <div className="flex-wrap-col-center flex-1 gap-4 self-stretch rounded-r-md border border-l-0 border-dashed border-purple-300 bg-gradient-to-tl from-purple-100 to-pink-100">
                        <div className="flex-wrap-row-between gap-1">
                            <ButtonIcon
                                iconClass={'fa-pen'}
                                onClickHandler={() => onClickNewsEditionHandler(news)}
                            />
                            <ButtonIcon
                                iconClass={'fa-trash'}
                                type="danger"
                                onClickHandler={() => setDialogOpen(true)}
                            />
                        </div>
                        <div className="pb-3 text-right text-xs text-gray-400">
                            <span className="text-gray-500">{dateData.label}:</span>{' '}
                            {dateData.date.format('llll')}
                        </div>
                    </div>
                </div>
                <div className="flex-wrap-row-between h-16">{news.title}</div>
            </Typography>
            <Typography className="pb-3 text-sm whitespace-pre-wrap">{news.content}</Typography>
        </div>
    );
};
