import { createElement, FunctionComponent, ReactNode } from 'react';

interface HtmlTagProps {
    // LowerCase !
    tagname: string;
    children: ReactNode;
    className?: string;
}

// Permet de créer un élément HTML dynamiquement
const HtmlTag: FunctionComponent<HtmlTagProps> = (props) => {
    const { tagname, children } = props;

    return createElement(tagname, props, children);
};

export default HtmlTag;
